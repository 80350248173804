export const deviceTaskConstants = {
    GET_DEVICE_TASK_REQUEST: 'GET_DEVICE_TASK_REQUEST',
    GET_DEVICE_TASK_SUCCESS: 'GET_DEVICE_TASK_SUCCESS',
    GET_DEVICE_TASK_FAILURE: 'GET_DEVICE_TASK_FAILURE',

    OPEN_DOOR_REQUEST: 'OPEN_DOOR_REQUEST',
    OPEN_DOOR_SUCCESS: 'OPEN_DOOR_SUCCESS',
    OPEN_DOOR_FAILURE: 'OPEN_DOOR_FAILURE',

    GET_USERS_IN_CLOCK_REQUEST: 'GET_USERS_IN_CLOCK_REQUEST',
    GET_USERS_IN_CLOCK_SUCCESS: 'GET_USERS_IN_CLOCK_SUCCESS',
    GET_USERS_IN_CLOCK_FAILURE: 'GET_USERS_IN_CLOCK_FAILURE',

    GET_ATTENLOG_CLOCK_REQUEST: 'GET_ATTENLOG_CLOCK_REQUEST',
    GET_ATTENLOG_CLOCK_SUCCESS: 'GET_ATTENLOG_CLOCK_SUCCESS',
    GET_ATTENLOG_CLOCK_FAILURE: 'GET_ATTENLOG_CLOCK_FAILURE',

    // OPEN_MODAL_RESTART: 'OPEN_MODAL_RESTART',
};