import { appUserConstants } from "../constants/app-user.constants";
import { AppUser } from "../models/app-user";
import { AppUserState } from "../models/app-user.state";

const initialState: AppUserState = { 
    loading: true, 
    error: "",
    appUsers:  Array<AppUser>(), 
    openAppUser: {
        open: false,
        select: new AppUser(),
        type: 0,
        loading: false,
    },
    openAppRestart: {
        open: false,
        select: new AppUser(),
        type:0,
        loading: false
    }
} ;

export function appUsers(state:AppUserState = initialState, action:any) {
    switch (action.type) {

        case appUserConstants.OPEN_MODAL_APP_USER:
            return {
                ...state,
                openAppUser: action.openAppUser
            }
        case appUserConstants.OPEN_MODAL_APP_RESTART:
            return {
                ...state,
                openAppRestart: action.openAppRestart
            }
        case appUserConstants.GET_APP_USERS_REQUEST:
            return {
                ...state,
                loading: true,
                appUsers:[]
            };
        case appUserConstants.GET_APP_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                appUsers: action.appUsers
            };
        case appUserConstants.GET_APP_USERS_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case appUserConstants.ADD_APP_USER_REQUEST:
            return {
                ...state,
                 openAppUser: {
                    open: state.openAppUser.open,
                    select: action.user,
                    type: state.openAppUser.type,
                    loading: true,
                }
            };
        case appUserConstants.ADD_APP_USER_SUCCESS:
            return {
                ...state,
                appUsers: action.appUsers,
                openAppUser: {
                    open: false,
                    select: state.openAppUser.select,
                    type: state.openAppUser.type,
                    loading: false,
                }
            };
        case appUserConstants.ADD_APP_USER_FAILURE:
            return {
                ...state,
                openAppUser: {
                    open: true,
                    select: action.user,
                    type: state.openAppUser.type,
                    loading: false,
                }
            };

        case appUserConstants.EDIT_APP_USER_REQUEST:
            return {
                ...state,
                openAppUser: {
                    open: state.openAppUser.open,
                    select: action.user,
                    type: state.openAppUser.type,
                    loading: true,
                }
            };
        case appUserConstants.EDIT_APP_USER_SUCCESS:
            return {
                ...state,
                appUsers: action.appUsers,
                openAppUser: {
                    open: false,
                    select: state.openAppUser.select,
                    type: state.openAppUser.type,
                    loading: false,
                }
            };
        case appUserConstants.EDIT_APP_USER_FAILURE:
            return {
                ...state,
                openAppUser: {
                    open: true,
                    select: action.user,
                    type: state.openAppUser.type,
                    loading: false,
                }
            };
  
        case appUserConstants.SEND_APP_LINK_EMAIL_REQUEST:
            return {
                ...state,
                 openAppRestart: {
                    open: true,
                    select: state.openAppRestart.select,
                    type: state.openAppRestart.type,
                    loading: true,
                }
            };
        case appUserConstants.SEND_APP_LINK_EMAIL_SUCCESS:
            return {
                ...state,
                openAppRestart: {
                    open: false,
                    select: state.openAppRestart.select,
                    type: state.openAppRestart.type,
                    loading: false,
                }
            };
        case appUserConstants.SEND_APP_LINK_EMAIL_FAILURE:
            return {
                ...state,
                openAppRestart: {
                    open: true,
                    select: state.openAppRestart.select,
                    type: state.openAppRestart.type,
                    loading: false,
                }
            };
    

        default:
            return state
    }
}