export const batchSuscriptionConstants = {
    SEARCH_BATCH_ITEM_SELECT_REQUEST: 'SEARCH_BATCH_ITEM_SELECT_REQUEST',
    SEARCH_BATCH_ITEM_SELECT_SUCCESS: 'SEARCH_BATCH_ITEM_SELECT_SUCCESS',
    SEARCH_BATCH_ITEM_SELECT_FAILURE: 'SEARCH_BATCH_ITEM_SELECT_FAILURE',

    SAVE_BATCH_REQUEST: 'SAVE_BATCH_REQUEST',
    SAVE_BATCH_SUCCESS: 'SAVE_BATCH_SUCCESS',
    SAVE_BATCH_FAILURE: 'SAVE_BATCH_FAILURE',

    ADD_BATCH_LINE_REQUEST: 'ADD_BATCH_LINE_REQUEST',
    ADD_BATCH_LINE_SUCCESS: 'ADD_BATCH_LINE_SUCCESS',
    ADD_BATCH_LINE_FAILURE: 'ADD_BATCH_LINE_FAILURE',

    EDIT_BATCH_LINE_QUANTITY_REQUEST: 'EDIT_BATCH_LINE_QUANTITY_REQUEST',
    EDIT_BATCH_LINE_QUANTITY_SUCCESS: 'EDIT_BATCH_LINE_QUANTITY_SUCCESS',
    EDIT_BATCH_LINE_QUANTITY_FAILURE: 'EDIT_BATCH_LINE_QUANTITY_FAILURE',
    
    REMOVE_BATCH_LINE_REQUEST: 'REMOVE_BATCH_LINE_REQUEST',
    REMOVE_BATCH_LINE_SUCCESS: 'REMOVE_BATCH_LINE_SUCCESS',
    REMOVE_BATCH_LINE_FAILURE: 'REMOVE_BATCH_LINE_FAILURE',


    NEXT_BATCH_REQUEST: 'NEXT_BATCH_REQUEST',
    NEXT_BATCH_SUCCESS: 'NEXT_BATCH_SUCCESS',
    NEXT_BATCH_FAILURE: 'NEXT_BATCH_FAILURE',

    PREVIEW_BATCH_REQUEST: 'PREVIEW_BATCH_REQUEST',
    PREVIEW_BATCH_SUCCESS: 'PREVIEW_BATCH_SUCCESS',
    PREVIEW_BATCH_FAILURE: 'PREVIEW_BATCH_FAILURE',


    SELECT_CUSTOMER_TEAM_REQUEST: 'SELECT_CUSTOMER_TEAM_REQUEST',
    SELECT_CUSTOMER_TEAM_SUCCESS: 'SELECT_CUSTOMER_TEAM_SUCCESS',
    SELECT_CUSTOMER_TEAM_FAILURE: 'SELECT_CUSTOMER_TEAM_FAILURE',


    PRINT_COMPROBANT_BATCH_REQUEST: 'PRINT_COMPROBANT_BATCH_REQUEST',
    PRINT_COMPROBANT_BATCH_SUCCESS: 'PRINT_COMPROBANT_BATCH_SUCCESS',
    PRINT_COMPROBANT_BATCH_FAILURE: 'PRINT_COMPROBANT_BATCH_FAILURE',

    CLEAN_BATCH_SUSCRIPTION: 'CLEAN_BATCH_SUSCRIPTION',
   
    OPEN_BATCH_MODAL_SEARCH_ITEM: 'OPEN_BATCH_MODAL_SEARCH_ITEM',
    OPEN_MODAL_PAYMENT_METHOD_BATCH: 'OPEN_MODAL_PAYMENT_METHOD_BATCH',

};