export const appUserConstants = {
    GET_APP_USERS_REQUEST: 'GET_APP_USERS_REQUEST',
    GET_APP_USERS_SUCCESS: 'GET_APP_USERS_SUCCESS',
    GET_APP_USERS_FAILURE: 'GET_APP_USERS_FAILURE',

    ADD_APP_USER_REQUEST: 'ADD_APP_USER_REQUEST',
    ADD_APP_USER_SUCCESS: 'ADD_APP_USER_SUCCESS',
    ADD_APP_USER_FAILURE: 'ADD_APP_USER_FAILURE',

    EDIT_APP_USER_REQUEST: 'EDIT_APP_USER_REQUEST',
    EDIT_APP_USER_SUCCESS: 'EDIT_APP_USER_SUCCESS',
    EDIT_APP_USER_FAILURE: 'EDIT_APP_USER_FAILURE',

    SEND_APP_LINK_EMAIL_REQUEST: 'SEND_APP_LINK_EMAIL_REQUEST',
    SEND_APP_LINK_EMAIL_SUCCESS: 'SEND_APP_LINK_EMAIL_SUCCESS',
    SEND_APP_LINK_EMAIL_FAILURE: 'SEND_APP_LINK_EMAIL_FAILURE',

    OPEN_MODAL_APP_USER: 'OPEN_MODAL_APP_USER',
    OPEN_MODAL_APP_RESTART: 'OPEN_MODAL_APP_RESTART',
};