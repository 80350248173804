import { Dispatch } from "@reduxjs/toolkit";
import { alertActions } from "../../../auth/actions/alert.actions";
import { teamsConstants } from "../constanst/teams.constants";
import { Team } from "../models/team";
import { teamService } from "../team.service";

export const teamsActions = {
    save,
    edit,
    clearTeam,
    getTeams,
    getById,
    preview,
    next,
};

function clearTeam() {
    return {type: teamsConstants.CLEAN_TEAM};
}


function save(team:Team) {
    return (dispatch: Dispatch) => {
        dispatch(request(team));
        teamService.save(team)
            .then((result)=> { 
                    dispatch(success(result.data));
                    dispatch(alertActions.success(`Equipo agregado exitosamente ${result.data.teamId}`));
                    // dispatch( printComprobant(result.data.suscriptionId!)  as any);
                },
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(team,message));
                dispatch(alertActions.error(message));
              
            });
    };
    function request(team:Team) { return { type: teamsConstants.SAVE_REQUEST_TEAM, team } }
    function success(team:Team) { return { type: teamsConstants.SAVE_SUCCESS_TEAM, team } }
    function failure(team:Team, error:any) { return { type: teamsConstants.SAVE_FAILURE_TEAM, team, error } }
}
function edit(team:Team) {
    return (dispatch: Dispatch) => {
        dispatch(request(team));
        teamService.edit(team)
            .then((result)=> { 
                    dispatch(success(result.data));
                    dispatch(alertActions.success(`Equipo editado exitosamente ${result.data.teamId}`));
                },
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(team,message));
                dispatch(alertActions.error(message));
              
            });
    };
    function request(team:Team) { return { type: teamsConstants.SAVE_REQUEST_TEAM, team } }
    function success(team:Team) { return { type: teamsConstants.SAVE_SUCCESS_TEAM, team } }
    function failure(team:Team, error:any) { return { type: teamsConstants.SAVE_FAILURE_TEAM, team, error } }
}

function getTeams() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        teamService.getTeams()
            .then((result)=> { 
                    dispatch(success(result.data));
                },
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
              
            });
    };
    function request() { return { type: teamsConstants.GET_TEAMS_REQUEST } }
    function success(teams:Team[]) { return { type: teamsConstants.GET_TEAMS_SUCCESS, teams } }
    function failure(error:any) { return { type: teamsConstants.GET_TEAMS_FAILURE, error } }
}

function getById(teamId:string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        teamService.getById(teamId)
            .then((result)=> { 
                    dispatch(success(result.data));
                    console.log(result.data)
                },
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
              
            });
    };
    function request() { return { type: teamsConstants.GET_TEAM_BY_ID_REQUEST } }
    function success(team:Team) { return { type: teamsConstants.GET_TEAM_BY_ID_SUCCESS, team } }
    function failure(error:any) { return { type: teamsConstants.GET_TEAM_BY_ID_FAILURE, error } }
}


function next(teamId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        teamService.next(teamId)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: teamsConstants.NEXT_REQUEST_TEAM } }
    function success(team:Team) { return { type: teamsConstants.NEXT_SUCCESS_TEAM, team } }
    function failure(error:any) { return { type: teamsConstants.NEXT_FAILURE_TEAM,  error } }
}

function preview(teamId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        teamService.preview(teamId)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: teamsConstants.PREVIEW_REQUEST_TEAM } }
    function success(team:Team) { return { type: teamsConstants.PREVIEW_SUCCESS_TEAM, team } }
    function failure( error:any) { return { type: teamsConstants.PREVIEW_FAILURE_TEAM, error } }
}
