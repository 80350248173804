import { TypeDialog } from "../../../helpers/type_dialog";
import { suscriptionConstants } from "../constants/suscription.constants";
import { Suscription } from "../models/suscription";
import { SuscriptionHistorical } from "../models/suscription-historical";
import { SuscriptionState } from "../models/suscription.state";

const initialState: SuscriptionState = { 
    loading: false, 
    error: "",
    suscription: new Suscription(),
    suscriptions: [],
    activeSuscriptions: [],
    customerPayLate: [],
    openSelectSuscription: {
        open: false,
        select: new Suscription(),
        type: 0,
        loading: false,
    },
    openHistorical: {
        open: false,
        select: new SuscriptionHistorical(),
        type: 0,
        loading: false,
    },
    openPaymentMethod: {
        open: false,
        select: 0,
        type: 0,
        loading: false,
    },
    openDiaryClose: {
        open: false,
        select: "",
        type: 0,
        loading: false,
    }
};

export function suscription(state:SuscriptionState = initialState, action:any) {
    switch (action.type) {

        case suscriptionConstants.OPEN_MODAL_SEARCH_ITEM:
            return {
                ...state,
                openSelectSuscription: action.openSelectSuscription
            };
        case suscriptionConstants.OPEN_MODAL_UPDATE_HISTORICAL:
            return {
                ...state,
                openHistorical: action.openHistorical
            };
        case suscriptionConstants.OPEN_MODAL_PAYMENT_METHOD:
            return {
                ...state,
                openPaymentMethod: action.openPaymentMethod
            };
        case suscriptionConstants.OPEN_MODAL_DIARY_CLOSE:
            return {
                ...state,
                openDiaryClose: action.openDiaryClose
            };
        case suscriptionConstants.CLEAN_SUSCRIPTION:
            return {
                ...state,
                suscription: new Suscription(),
            };

        case suscriptionConstants.SAVE_REQUEST:
            return {
                ...state,
                 loading: true,
                suscription: action.suscription,
            };
         case suscriptionConstants.SAVE_SUCCESS:
            return {
                ...state,
                loading: false,
                suscription: new Suscription(),
                openPaymentMethod:{
                    open : false,
                    select :0,
                    type : TypeDialog.add,
                    loading: false
                  }
            };
        case suscriptionConstants.SAVE_FAILURE:
            return {
                ...state,
                loading: false,
                suscription: action.suscription
            };

          case suscriptionConstants.NEXT_REQUEST:
            return {
                ...state,
                 loading: true,
            };
         case suscriptionConstants.NEXT_SUCCESS:
            return {
                ...state,
                loading: false,
                suscription: action.suscription
            };
        case suscriptionConstants.NEXT_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case suscriptionConstants.PREVIEW_REQUEST:
            return {
                ...state,
                 loading: true,
            };
         case suscriptionConstants.PREVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                suscription: action.suscription
            };
        case suscriptionConstants.PREVIEW_FAILURE:
            return {
                ...state,
                loading: false,
            };

        
        case suscriptionConstants.REMOVE_LINE_REQUEST:
            return {
                ...state,
                 loading: true,
                suscription: action.suscription,
            };
         case suscriptionConstants.REMOVE_LINE_SUCCESS:
            return {
                ...state,
                loading: false,
                suscription: action.suscription
            };
        case suscriptionConstants.REMOVE_LINE_FAILURE:
            return {
                ...state,
                loading: false,
                suscription: action.suscription
            };
        case suscriptionConstants.ADD_LINE_REQUEST:
            return {
                ...state,
                loading: true,
                suscription: action.suscription
            };
        case suscriptionConstants.ADD_LINE_SUCCESS:
            return {
                ...state,
                loading: false,
                suscription: action.suscription
            };
        case suscriptionConstants.ADD_LINE_FAILURE:
            return {
                ...state,
                loading: false,
                suscription: action.suscription
            };

         case suscriptionConstants.EDIT_LINE_QUANTITY_REQUEST:
            return {
                ...state,
                loading: true,
                suscription: action.suscription
            };
        case suscriptionConstants.EDIT_LINE_QUANTITY_SUCCESS:
            return {
                ...state,
                loading: false,
                suscription: action.suscription
            };
        case suscriptionConstants.EDIT_LINE_QUANTITY_FAILURE:
            return {
                ...state,
                loading: false,
                suscription: action.suscription
            };
         case suscriptionConstants.GET_ACTIVE_REQUEST:
            return {
                ...state,
                loading: true,
                activeSuscriptions: []
            };
        case suscriptionConstants.GET_ACTIVE_SUCCESS:
            return {
                ...state,
                loading: false,
                activeSuscriptions: action.activeSuscriptions
            };
        case suscriptionConstants.GET_ACTIVE_FAILURE:
            return {
                ...state,
                loading: false,
                activeSuscriptions: []
            };




            case suscriptionConstants.GET_CUSTOMER_PAY_LATE_REQUEST:
            return {
                ...state,
                loading: true,
                customerPayLate: []
            };
        case suscriptionConstants.GET_CUSTOMER_PAY_LATE_SUCCESS:
            return {
                ...state,
                loading: false,
                customerPayLate: action.customerPayLate
            };
        case suscriptionConstants.GET_CUSTOMER_PAY_LATE_FAILURE:
            return {
                ...state,
                loading: false,
                customerPayLate: []
            };






         case suscriptionConstants.GET_SEARCH_ACTIVE_REQUEST:
            return {
                ...state,
                loading: true,
                activeSuscriptions: []
            };
        case suscriptionConstants.GET_SEARCH_ACTIVE_SUCCESS:
            return {
                ...state,
                loading: false,
                activeSuscriptions: action.activeSuscriptions
            };
        case suscriptionConstants.GET_SEARCH_ACTIVE_FAILURE:
            return {
                ...state,
                loading: false,
                activeSuscriptions: []
            };
         case suscriptionConstants.UPDATE_HISTORICAL_REQUEST:
            return {
                ...state,
                openHistorical: {
                    open: state.openHistorical.open,
                    select: action.historical,
                    type: state.openHistorical.type,
                    loading: true
                },

            };
        case suscriptionConstants.UPDATE_HISTORICAL_SUCCESS:
            return {
                ...state,
                openHistorical: {
                    open: false,
                    select: new SuscriptionHistorical(),
                    type: state.openHistorical.type,
                    loading: false
                },
                activeSuscriptions: action.activeSuscriptions
            };
        case suscriptionConstants.UPDATE_HISTORICAL_FAILURE:
            return {
                ...state,
                openHistorical: {
                    open: state.openHistorical.open,
                    select: action.historical,
                    type: state.openHistorical.type,
                    loading: false
                },
            };
         case suscriptionConstants.SELECT_CUSTOMER_REQUEST:
            return {
                ...state,
                loading: true,
                suscription: new Suscription()
            };
        case suscriptionConstants.SELECT_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,
                suscription: action.suscription
            };
        case suscriptionConstants.SELECT_CUSTOMER_FAILURE:
            return {
                ...state,
                loading: false,
            };




         case suscriptionConstants.PRINT_COMPROBANT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case suscriptionConstants.PRINT_COMPROBANT_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case suscriptionConstants.PRINT_COMPROBANT_FAILURE:
            return {
                ...state,
                loading: false,
            };

         case suscriptionConstants.PRINT_DIARY_CLOSE_REQUEST:
            return {
                ...state,
                openDiaryClose: {...state.openDiaryClose, loading: true},
            };
        case suscriptionConstants.PRINT_DIARY_CLOSE_SUCCESS:
            return {
                ...state,
                openDiaryClose: {
                    open : false,
                    select :"",
                    type : TypeDialog.add,
                    loading: false
                },
            };
        case suscriptionConstants.PRINT_DIARY_CLOSE_FAILURE:
            return {
                ...state,
                openDiaryClose: {...state.openDiaryClose, loading: false},
            };

         case suscriptionConstants.PRINT_INVOICE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case suscriptionConstants.PRINT_INVOICE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case suscriptionConstants.PRINT_INVOICE_FAILURE:
            return {
                ...state,
                loading: false,
            };


        default:
            return state
    }
}