import { reportConstants } from "../constants/report.constants";
import { ReportState } from "../models/report.state";

const initialState: ReportState = { 
    loading: false, 
    error: "",
    diarySales: [],
    monthlySales: [],
    salesByItems: [],
    customersLoss: [],
};

export function reports(state:ReportState = initialState, action:any) {
    switch (action.type) {

        case reportConstants.CLEAN_REPORTS:
            return {
                ...state,
                loading: false,
                diarySales: [],
                salesByItems: [],
                monthlySales: [],
                customersLoss: [],

            };
        case reportConstants.DIARY_SALES_REQUEST:
            return {
                ...state,
                loading: true,
                diarySales: []
            };
         case reportConstants.DIARY_SALES_SUCCESS:
            return {
                ...state,
                loading: false,
                diarySales: action.diarySales
            };
        case reportConstants.DIARY_SALES_FAILURE:
            return {
                ...state,
                loading: false,
            };


        case reportConstants.DIARY_SALES_BY_SERVICE_REQUEST:
            return {
                ...state,
                loading: true,
            };
         case reportConstants.DIARY_SALES_BY_SERVICE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case reportConstants.DIARY_SALES_BY_SERVICE_FAILURE:
            return {
                ...state,
                loading: false,
            };
            
        case reportConstants.BILLS_RESUMEN_REQUEST:
            return {
                ...state,
                loading: true,
            };
         case reportConstants.BILLS_RESUMEN_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case reportConstants.BILLS_RESUMEN_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case reportConstants.MONTHLY_SALES_REQUEST:
            return {
                ...state,
                loading: true,
                monthlySales: []
            };
         case reportConstants.MONTHLY_SALES_SUCCESS:
            return {
                ...state,
                loading: false,
                monthlySales: action.monthlySales
            };
        case reportConstants.MONTHLY_SALES_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case reportConstants.SALES_BY_ITEM_REQUEST:
            return {
                ...state,
                loading: true,
                salesByItems: []
            };
         case reportConstants.SALES_BY_ITEM_SUCCESS:
            return {
                ...state,
                loading: false,
                salesByItems: action.salesByItems
            };
        case reportConstants.SALES_BY_ITEM_FAILURE:
            return {
                ...state,
                loading: false,
            };

         case reportConstants.CUSTOMERS_LOSS_REQUEST:
            return {
                ...state,
                loading: true,
                customersLoss: []
            };
         case reportConstants.CUSTOMERS_LOSS_SUCCESS:
            return {
                ...state,
                loading: false,
                customersLoss: action.customersLoss
            };
        case reportConstants.CUSTOMERS_LOSS_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case reportConstants.PRINT_MONTH_CLOSE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case reportConstants.PRINT_MONTH_CLOSE_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case reportConstants.PRINT_MONTH_CLOSE_FAILURE:
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}