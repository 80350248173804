import axios from 'axios';
import environment from '../../environment';
import { Bill } from './models/bill';
import { BillCategory } from './models/bill-category';

export const billService = {
  save,
  getBills,
  getCategories,
  getById,
  preview,
  next,
  cancelBill,
  addCategory,
  editCategory
};
 function save(bill:Bill) {
    return axios.post<Bill>(`${environment.apiUri}/bill`, bill)
  };
  function getBills(fromDate: string, toDate:string) :Promise<any> {
    return axios.get<Bill[]>(`${environment.apiUri}/bill?fromDate=${fromDate}&toDate=${toDate}`)
  };
  
  function getById(billId: string) :Promise<any> {
   return axios.get<Bill>(`${environment.apiUri}/bill/GetById?billId=${billId}`)
  };
  function getCategories(){
    return axios.get<BillCategory[]>(`${environment.apiUri}/bill/categories`)
  };
  function preview(billId:string){
    return axios.get<Bill>(`${environment.apiUri}/bill/preview?billId=${billId}`)
  };
  function next(billId:string){
    return axios.get<Bill>(`${environment.apiUri}/bill/next?billId=${billId}`)
  };
 function cancelBill(billId:string){
   return axios.get<Bill>(`${environment.apiUri}/bill/cancelBill?billId=${billId}`)
  };
  function addCategory(billCategory:BillCategory) {
     return axios.post<BillCategory[]>(`${environment.apiUri}/bill/addCategory`, billCategory)
   };
  function editCategory(billCategory:BillCategory) {
     return axios.put<BillCategory[]>(`${environment.apiUri}/bill/editCategory`, billCategory)
   };