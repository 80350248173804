import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useEffect } from 'react';
import { Button } from 'primereact/button';
import { SkeletonComponent } from '../../components/SkeletonComponet';
import { TemplatePaginator } from '../../components/TemplatePaginator';
import { billsActions } from './actions/bills.actions';
import { BillCategory } from './models/bill-category';
import { User } from '../settings/users/models/user';
import DialogBillCategories from './components/dialog.bill-category';
import { Badge } from 'primereact/badge';
import { WithTranslation, withTranslation } from 'react-i18next';

function ListBillCategoriesPage({t}: WithTranslation) {
    const categories: BillCategory[]= useSelector((state:any) => state.bills?.categories??[]);
    const loading: boolean= useSelector((state:any) => state.bills.loading);
    const user  = useSelector((state: any) => state.authentication.user as User);
    const dispatch = useDispatch();

    useEffect(() => { 
        dispatch(billsActions.getCategories());
    },[]);

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                <h5 className="m-0">{t("categoriesList")}</h5>
                <span className="p-input-icon-left">
                    <Button label={t("addNew")} onClick={handAdd} icon="pi pi-pencil" iconPos="left"  />
                </span>
            </div>
        )
    }
    const  handAdd = () =>{
         dispatch(billsActions.openModalBillCategory({
                open : true,
                select : new BillCategory(),
                type : 0,
                loading: false
            }));
    }
    const header = renderHeader();

    const actionBodyTemplate = (rowData:BillCategory) => {
        const showDialog =()=>{
            dispatch(billsActions.openModalBillCategory({
                open : true,
                select : rowData,
                type : 1,
                loading: false
            }));
        }
       
        return  <span className="p-buttonset">
                    <Button label={t("edit")} icon="pi pi-pencil" iconPos="left" onClick={showDialog} />
                    {/* <Button label="Restablecer" className="p-button-success" icon="pi pi-info-circle" iconPos="left" onClick={showDialogRestart} /> */}
                </span>
    }

    const activeBodyTemplate = (rowData:BillCategory) => {      
        return <Badge value={rowData.active? t("active") : t("inactive")} severity={rowData.active ? undefined: "danger"} ></Badge>
    }
    return ( 
        <div className="grid mt-1">
            <div className="col-12 card">
                {loading ? <SkeletonComponent/> :
                <DataTable  emptyMessage={t("noResultFound")} value={categories} paginatorTemplate={TemplatePaginator as any} 
                        responsiveLayout="scroll"  rows={10}  size="small" stripedRows   header={header} >
                        <Column field="id" header={t("id")}></Column>
                        <Column field="name" header={t("name")}></Column>
                        <Column field="active" header={t("status")} body={activeBodyTemplate}></Column>
                        <Column header={t("action")} body={actionBodyTemplate} />
                    </DataTable>
                }
                <DialogBillCategories></DialogBillCategories>
            </div>
        </div>
    );
}


export default withTranslation()(ListBillCategoriesPage);