import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { BillCategory } from '../models/bill-category';
import { DataDialogProps } from '../../../models/data.dialog.props';
import { User } from '../../settings/users/models/user';
import { SkeletonComponent } from '../../../components/SkeletonComponet';
import { billsActions } from '../actions/bills.actions';
import { withTranslation, WithTranslation } from 'react-i18next';

  function DialogBillCategories({t}: WithTranslation) {
    const dispatch = useDispatch();    
    const openBillCategory  = useSelector((state: any) => state.bills.openBillCategory as DataDialogProps<BillCategory>);
    const user  = useSelector((state: any) => state.authentication.user as User);
    const [inputs, setInputs] = React.useState<BillCategory>(openBillCategory?.select);
    const [submitted, setSubmitted] = React.useState(false);

    useEffect(() => {
        setInputs(openBillCategory?.select)
        setSubmitted(false)
    }, [openBillCategory]);

    const handleClose = () => {
      setInputs(new User())
      dispatch(billsActions.openModalBillCategory({
        open : false,
        select : new BillCategory(),
        type : 0,
        loading: false
      }));
      setSubmitted(false)
    };


    function handleSubmit(e:any) {
      e.preventDefault();
      setSubmitted(true);
      if (inputs.name ) {
          if(openBillCategory?.type ===0){
            dispatch(billsActions.addCategory(inputs));
          }
          if(openBillCategory?.type ===1){
            dispatch(billsActions.editCategory(inputs));
          }
      }
    }

    function handleChange(e:any) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }
    function handleChangeCheck(e:any) {
        const { name, checked } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: checked }));
    }
    const footer = (
        <div>
            <Button onClick={handleSubmit} label={openBillCategory?.type ===0 ? t("add"): t("edit")} icon="pi pi-check"/>
        </div>
    );

   
    return (
        <Dialog header={openBillCategory?.type ===0 ? t("add"): t("edit")} position="top" footer={footer} visible={openBillCategory?.open??false} onHide={handleClose} breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '45vw'}}>
            <div>
                {openBillCategory.loading ? <SkeletonComponent/> :
                <div className="p-fluid grid mt-3">

                    <div className="field col-12 md:col-12">
                        <span className="p-float-label p-input-icon-left">
                                <i className="pi pi-user" />
                            <InputText id="name" name="name" value={inputs.name} onChange={handleChange}  className={(!inputs?.name) ? "block p-invalid":"block"}  />
                            <label htmlFor="name">{t("name")}</label>
                        </span>
                        {(!inputs?.name) ?<small id="name-help" className="block p-error">{t("nameRequired")}</small>: <></> }
                    </div>
                
                    {
                        openBillCategory.type == 1?
                        <div className="field col-12 md:col-12">
                            <span className="p-float-label p-input-icon-left">
                                <Checkbox inputId="cbActive" name="active" value="Activo" onChange={handleChangeCheck} checked={inputs.active??false}></Checkbox>
                                <label htmlFor="cbActive" className="p-checkbox-label">{t("active")}</label>
                            </span>
                        </div>
                        : <div></div>
                    }
              


                </div>
                }
            </div>
        </Dialog>
    );
  }
  export default withTranslation()(DialogBillCategories);