
export class TeamDetail { 
    lineId?: number;
    customerId?: string;
    customer?: string;
    createdDate ?: Date;
    photoUri?: string;
     
    constructor(datos?: TeamDetail) {
        if (datos != null) {
        this.lineId = datos.lineId;
        this.customerId = datos.customerId;
        this.customer = datos.customer;
        this.createdDate = datos.createdDate;
        this.photoUri = datos.photoUri;
        return;
        }
    }
}