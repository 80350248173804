import { deviceTaskConstants } from "../constants/device-task.constants";
import { DeviceTaskState } from "../models/device-task.state";

const initialState: DeviceTaskState = { 
    loading: true, 
    error: "",
    tasks:[]
} ;

export function devicesTasks(state:DeviceTaskState = initialState, action:any) {
    switch (action.type) {


        case deviceTaskConstants.GET_DEVICE_TASK_REQUEST:
            return {
                ...state,
                loading: true,
                tasks:[]
            };
        case deviceTaskConstants.GET_DEVICE_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                tasks: action.tasks
            };
        case deviceTaskConstants.GET_DEVICE_TASK_FAILURE:
            return {
                ...state,
                loading: false,
            };

        
        case deviceTaskConstants.OPEN_DOOR_REQUEST:
            return {
                ...state,
                loading: true,

            };
        case deviceTaskConstants.OPEN_DOOR_SUCCESS:
            return {
                ...state,
                loading: false,
                tasks: action.tasks
            };
        case deviceTaskConstants.OPEN_DOOR_FAILURE:
            return {
                ...state,
                loading: false,
            };


        case deviceTaskConstants.GET_USERS_IN_CLOCK_REQUEST:
            return {
                ...state,
                loading: true,

            };
        case deviceTaskConstants.GET_USERS_IN_CLOCK_SUCCESS:
            return {
                ...state,
                loading: false,
                tasks: action.tasks
            };
        case deviceTaskConstants.GET_USERS_IN_CLOCK_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case deviceTaskConstants.GET_ATTENLOG_CLOCK_REQUEST:
            return {
                ...state,
                loading: true,

            };
        case deviceTaskConstants.GET_ATTENLOG_CLOCK_SUCCESS:
            return {
                ...state,
                loading: false,
                tasks: action.tasks
            };
        case deviceTaskConstants.GET_ATTENLOG_CLOCK_FAILURE:
            return {
                ...state,
                loading: false,
            };


        default:
            return state
    }
}