import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { DataDialogProps } from '../../../models/data.dialog.props';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DialogProps } from '../../../models/dialog.props';
import { InputText } from 'primereact/inputtext';
import {DebounceInput} from 'react-debounce-input';
import { TemplatePaginator } from '../../../components/TemplatePaginator';
import { ImageSrc } from '../../../components/image-src';
import Logo from'../../../assets/images/logo.png';
import { withTranslation } from 'react-i18next';
import { Customer } from '../../customer/models/customer';
import { customerActions } from '../../customer/actions/customer.actions';

  function DialogSelectCustomerTeam(props: DialogProps<Customer>) {
    const { onSelect, t} = props;
    const dispatch = useDispatch();    
    const openSelectCustomerTeam  = useSelector((state: any) => state.customer.openSelectCustomerTeam as DataDialogProps<Customer[]>);

    const handleClose = () => {
      dispatch(customerActions.openModalSearchCustomerTeam({
        open : false,
        select :[],
        type : 0,
        loading: false
      }));
    };

     const renderHeader = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                    <span className="p-input-icon-left" style={{width:"100%"}}>
                    { openSelectCustomerTeam.loading  ? <i className="pi pi-spin pi-spinner" />: <i className="pi pi-search" /> }
                     <DebounceInput
                        placeholder={t("search")}
                        minLength={1}
                        style={{width:"100%"}}
                        onChange={event => {
                            if((event.target?.value??"").length > 1){
                                dispatch(customerActions.searchByDescriptionDialogBatch(event.target.value));
                            }
                        }} 
                        value=""
                        debounceTimeout={400}
                        element={InputText as any} />
                </span>
                {/* <h5 className="m-0">{ "Seleccione un tipo de item"}</h5>
                    <Button style={{width: "170px"}} label={"Agregar " + typeItem} icon="pi pi-plus" /> */}
            </div>
        )
    }

    const handOnSelectCustomer = (customer: Customer)=>{
        onSelect!(customer);
        handleClose();
    }

    const header = renderHeader();
 
    const photoBodyTemplate = (customer: Customer)=>{
        const imagess = (customer.photoUri??"").split(",");
        return <>
            {imagess.map(c=>  (c) ?
                                <ImageSrc height="25px" width="25px" src={c} />
                
                                :<img height="25px" width="25px"src={ Logo || ""} alt="" />)}
        </>
    }
    return (
        <Dialog header={t("customersList")} position="top" visible={openSelectCustomerTeam?.open??false} onHide={handleClose} breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '45vw'}}>
            <div>  
                <DataTable  emptyMessage={t("noResultFound")} loading={openSelectCustomerTeam.loading }  rows={10} 
                    paginatorTemplate={TemplatePaginator as any} disabled={openSelectCustomerTeam.loading } selectionMode="single"
                    value={openSelectCustomerTeam.select} responsiveLayout="scroll" 
                    onSelectionChange={e => handOnSelectCustomer(e.value)} size="small" stripedRows header={header} >
                    <Column field="photoUri" header={t("photo")} body={photoBodyTemplate}></Column>
                    <Column field="customerId" header={t("customerId")}></Column>
                    <Column field="name" header={t("name")}></Column>
                    <Column field="dni" header={t("dni")}></Column>
                </DataTable>
            </div>
        </Dialog>
    );
  }
    

  export default withTranslation()(DialogSelectCustomerTeam);
