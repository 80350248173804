export const suscriptionConstants = {
    SEARCH_ITEM_SELECT_REQUEST: 'SEARCH_ITEM_SELECT_REQUEST',
    SEARCH_ITEM_SELECT_SUCCESS: 'SEARCH_ITEM_SELECT_SUCCESS',
    SEARCH_ITEM_SELECT_FAILURE: 'SEARCH_ITEM_SELECT_FAILURE',

    SAVE_REQUEST: 'SAVE_REQUEST',
    SAVE_SUCCESS: 'SAVE_SUCCESS',
    SAVE_FAILURE: 'SAVE_FAILURE',

    ADD_LINE_REQUEST: 'ADD_LINE_REQUEST',
    ADD_LINE_SUCCESS: 'ADD_LINE_SUCCESS',
    ADD_LINE_FAILURE: 'ADD_LINE_FAILURE',

    EDIT_LINE_QUANTITY_REQUEST: 'EDIT_LINE_QUANTITY_REQUEST',
    EDIT_LINE_QUANTITY_SUCCESS: 'EDIT_LINE_QUANTITY_SUCCESS',
    EDIT_LINE_QUANTITY_FAILURE: 'EDIT_LINE_QUANTITY_FAILURE',
    
    REMOVE_LINE_REQUEST: 'REMOVE_LINE_REQUEST',
    REMOVE_LINE_SUCCESS: 'REMOVE_LINE_SUCCESS',
    REMOVE_LINE_FAILURE: 'REMOVE_LINE_FAILURE',


    NEXT_REQUEST: 'NEXT_REQUEST',
    NEXT_SUCCESS: 'NEXT_SUCCESS',
    NEXT_FAILURE: 'NEXT_FAILURE',

    PREVIEW_REQUEST: 'PREVIEW_REQUEST',
    PREVIEW_SUCCESS: 'PREVIEW_SUCCESS',
    PREVIEW_FAILURE: 'PREVIEW_FAILURE',

    GET_ACTIVE_REQUEST: 'GET_ACTIVE_REQUEST',
    GET_ACTIVE_SUCCESS: 'GET_ACTIVE_SUCCESS',
    GET_ACTIVE_FAILURE: 'GET_ACTIVE_FAILURE',

    GET_CUSTOMER_PAY_LATE_REQUEST: 'GET_CUSTOMER_PAY_LATE_REQUEST',
    GET_CUSTOMER_PAY_LATE_SUCCESS: 'GET_CUSTOMER_PAY_LATE_SUCCESS',
    GET_CUSTOMER_PAY_LATE_FAILURE: 'GET_CUSTOMER_PAY_LATE_FAILURE',

    GET_SEARCH_ACTIVE_REQUEST: 'GET_SEARCH_ACTIVE_REQUEST',
    GET_SEARCH_ACTIVE_SUCCESS: 'GET_SEARCH_ACTIVE_SUCCESS',
    GET_SEARCH_ACTIVE_FAILURE: 'GET_SEARCH_ACTIVE_FAILURE',

    UPDATE_HISTORICAL_REQUEST: 'UPDATE_HISTORICAL_REQUEST',
    UPDATE_HISTORICAL_SUCCESS: 'UPDATE_HISTORICAL_SUCCESS',
    UPDATE_HISTORICAL_FAILURE: 'UPDATE_HISTORICAL_FAILURE',

    SELECT_CUSTOMER_REQUEST: 'SELECT_CUSTOMER_REQUEST',
    SELECT_CUSTOMER_SUCCESS: 'SELECT_CUSTOMER_SUCCESS',
    SELECT_CUSTOMER_FAILURE: 'SELECT_CUSTOMER_FAILURE',


    PRINT_COMPROBANT_REQUEST: 'PRINT_COMPROBANT_REQUEST',
    PRINT_COMPROBANT_SUCCESS: 'PRINT_COMPROBANT_SUCCESS',
    PRINT_COMPROBANT_FAILURE: 'PRINT_COMPROBANT_FAILURE',


    PRINT_DIARY_CLOSE_REQUEST: 'PRINT_DIARY_CLOSE_REQUEST',
    PRINT_DIARY_CLOSE_SUCCESS: 'PRINT_DIARY_CLOSE_SUCCESS',
    PRINT_DIARY_CLOSE_FAILURE: 'PRINT_DIARY_CLOSE_FAILURE',

    PRINT_INVOICE_REQUEST: 'PRINT_INVOICE_REQUEST',
    PRINT_INVOICE_SUCCESS: 'PRINT_INVOICE_SUCCESS',
    PRINT_INVOICE_FAILURE: 'PRINT_INVOICE_FAILURE',



    CLEAN_SUSCRIPTION: 'CLEAN_SUSCRIPTION',

    OPEN_MODAL_SEARCH_ITEM: 'OPEN_MODAL_SEARCH_ITEM',
    OPEN_MODAL_UPDATE_HISTORICAL: 'OPEN_MODAL_UPDATE_HISTORICAL',
    OPEN_MODAL_PAYMENT_METHOD: 'OPEN_MODAL_PAYMENT_METHOD',
    OPEN_MODAL_DIARY_CLOSE: 'OPEN_MODAL_DIARY_CLOSE',
};