import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { DataDialogProps } from '../../../models/data.dialog.props';
import { DialogProps } from '../../../models/dialog.props';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { format } from '../../../helpers/format';
import { TypeDialog } from '../../../helpers/type_dialog';
import { SuscriptionHistorical } from '../models/suscription-historical';
import { suscriptionActions } from '../actions/suscription.actions';
import { InputMask } from "primereact/inputmask";
import { User } from '../../settings/users/models/user';
import { Suscription } from '../models/suscription';
import { Fieldset } from 'primereact/fieldset';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import FilePickerInHouse from '../../file_picker_in_house/FilePickerInHouse';
import { FileUpload } from '../../file_picker_in_house/models/file_upload';
import { withTranslation } from 'react-i18next';
import { InputTextarea } from 'primereact/inputtextarea';


  function PaymentMethod(props: DialogProps<SuscriptionHistorical>) {
    const { onClose, t} = props;
    const dispatch = useDispatch();    
    const openPaymentMethod  = useSelector((state: any) => state.suscription.openPaymentMethod as DataDialogProps<number>);
    const loading: boolean= useSelector((state:any) => state.suscription.loading);
    const suscription: Suscription= useSelector((state:any) => state.suscription.suscription);
    const [inputs, setInputs] = useState<Suscription>(suscription);
 
    const handleClose = () => {
      dispatch(suscriptionActions.openModalPaymentMethod({
        open : false,
        select :0,
        type : TypeDialog.add,
        loading: false
      }));
    };
 

    useEffect(() => {
        setInputs(suscription)
   }, [openPaymentMethod]);



    const banks = [
        {
            bankId: "bac",
            name: "Bac Credomatic",
            icon: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS737KnUHGQdmafj1EY7dxeo1i4-UJvIX31jBtUrHsNPQ&s"
        },
        {
            bankId: "ficohsa",
            name: "Ficohsa",
            icon: "https://play-lh.googleusercontent.com/E9APS5C-nmNiB6sjPHA3c96mrEFCwfxEEte6S-Q2fKldll9F_A4bOqp_KFqfv0k-agk"
        },

        {
            bankId: "occidente",
            name: "Banco de Occidente",
            icon: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyCnLvdYkI7iVeVzKb2ET_avz8pT66pMsz6_xFCnWjDQ&s"
        },
        {
            bankId: "banpais",
            name: "Banpais",
            icon: "https://play-lh.googleusercontent.com/v4YuZGyuFb9dE0N6q3VVSTFFqqmt7n_DY_57-axH8QCtED-FXdCHZ0xpHCgty7m1evYm"
        },
        {
            bankId: "atlantida",
            name: "Banco Altántida",
            icon: "https://d3jky06km58rdx.cloudfront.net/assets/images/store/tegucigalpa/MyJIRMe1WLUZluLGGZRvnRQKzmfnUDjyRVwXHlPJ.png"
        },
        {
            bankId: "banrural",
            name: "Banrural",
            icon: "https://d9hhnadinot6y.cloudfront.net/imag/2021/09/banrural-logo-este-si.jpg"
        },
        {
            bankId: "promerica",
            name: "Banco Promerica",
            icon: "https://play-lh.googleusercontent.com/XN1DevVYDHl1jdi3kn6nfz9u-eJXzN0j3ZCJmgEjSryuNxBn_4KpKE7EpVnwauEX6Vk"
        },

        
    ]
    
    function handleSubmit(e:any) {
        e.preventDefault();
        dispatch(suscriptionActions.save(inputs));
    }

    
    function handleChange(e:any) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }
    function handleChangeBank(e:any) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value, amountCredit: undefined, referenceCreditCard: undefined, creditCard: undefined }));
    }

    function handleChangeBankTransference(e:any) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value, referenceTransference: undefined, amountTransference: undefined, transferenceComprobant: undefined }));
    }

 
    const getChange =()=>{
        const pay = parseFloat(inputs.amountCash?.toString() ?? "0") + parseFloat(inputs.amountCredit?.toString() ?? "0")  + parseFloat(inputs.amountTransference?.toString() ?? "0"); 
        const total= pay - (suscription?.total??0) ;
        return total;
    }

    const disabledSend = ()=>{
        const disabled= getChange() !=0 
        || (inputs.bankId && (inputs.amountCredit??0) <=0) 
        || (inputs.bankId && (inputs.creditCard??0).toString().replace("_", "").length <4) 
        || (inputs.bankId && !inputs.referenceCreditCard)
        || (inputs.bankTransferenceId && !inputs.referenceTransference)
        || (inputs.bankTransferenceId && (inputs.amountTransference??0) <=0) 
        || (inputs.bankTransferenceId && !inputs.transferenceComprobant)
        
        
        ;
        return disabled as boolean;
    }

    const footer = (
        <div>
           <Button loading={openPaymentMethod.loading || loading} disabled={openPaymentMethod.loading ||loading  || disabledSend()} label="Pagar y Guardar  " onClick={handleSubmit} icon="pi pi-check"/>
        </div>
    );

   
    const selectedCountryTemplate = (option: any, props:any) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <img alt={option.name} src={option.icon}  style={{ width: '18px' }} />
                    <div>{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const countryOptionTemplate = (option:any) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.name} src={option.icon} style={{ width: '18px' }} />
                <div>{option.name}</div>
            </div>
        );
    };

    const onImagePicker =  async (result: FileUpload)=>{
        setInputs(inputs => ({ ...inputs, ["transferenceComprobant"]: result.path }));
        // setInputs(inputs => ({ ...inputs, ["fileId"]: result.fileId }));
    }    
  
    return (
        <Dialog  header={t("paymentMethod")}  footer={footer} visible={openPaymentMethod?.open??false} onHide={handleClose} breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '55vw'}}>
            <div className='p-fluid grid mt-2'>   

                <div className="col-12">
                    <div className="grid">
                        <div className="col-6">
                            <div className="grid">                                    
                                <div className="col-12">
                                    <Fieldset legend={t("cash")}>
                                       <div className="p-inputgroup flex-1">
                                            <span className="p-inputgroup-addon">L</span>
                                            <InputNumber placeholder={t("cashAmount")} 
                                            disabled={loading}
                                             name="amountCash"
                                             onChange={(e)=>{
                                                handleChange({target: {
                                                    name: "amountCash",
                                                    value: e.value
                                                }})
                                             }}
                                            locale="en-US" minFractionDigits={2}  />
                                        </div>
                                    </Fieldset>
                                </div>
                                <div className="col-12">
                                    <Fieldset legend={t("creditCard")}>
                               
                                    <div className="p-inputgroup flex-1 mt-2">
                                        <Dropdown inputId="dropdownStore" name="bankId" value={inputs.bankId || undefined } 
                                        disabled={loading}
                                        options={banks} 
                                        scrollHeight='400px'
                                        itemTemplate={countryOptionTemplate} 
                                        valueTemplate={selectedCountryTemplate}
                                        placeholder={t("selectABank")}
                                        showClear
                                        onChange={handleChangeBank} optionLabel="name" optionValue="bankId" />
                                    </div>
                                    
                                    {(inputs.bankId) ? 
                                    <>
                                    <div className="p-inputgroup flex-1 mt-2">
                                        <span className="p-inputgroup-addon">L</span>
                                        <InputNumber name="amoutCredit" value={inputs?.amountCredit??undefined} 
                                        disabled={loading}
                                        onChange={(e)=>{
                                            handleChange({target: {
                                                name: "amountCredit",
                                                value: e.value
                                            }})
                                        }} 
                                        locale="en-US" minFractionDigits={2}
                                        placeholder={t("amountCreditCard")}  className={ ((inputs?.bankId && (inputs.amountCredit??0)<=0) || (inputs?.bankId && !inputs.amountCredit))? "p-invalid":""}  />
                                        { ((inputs?.bankId && (inputs.amountCredit??0)<=0) || (inputs?.bankId && !inputs.amountCredit)) ? <Button tooltip={t("amountCreditCardRequired")} icon="pi pi-info" className="p-button-danger" />:<></>}
                                    </div>

                                    
                                    <div className="p-inputgroup flex-1 mt-2">
                                        <span className="p-inputgroup-addon"><i className="pi pi-credit-card"></i></span>
                                        <InputMask placeholder={t("lastForDigit")} 
                                        disabled={loading}
                                        name="creditCard"
                                        onChange={handleChange}
                                        value={inputs?.creditCard?.toString() || ""}
                                        mask="9999" className={(inputs?.bankId && (inputs.creditCard??0)<=0)? "p-invalid":""}  />
                                        { ( inputs.bankId && (inputs.creditCard??"0").toString().replace("_", "").length <4 )? <Button tooltip={t("lastForDigitRequired")} icon="pi pi-info" className="p-button-danger" />:<></>}
                                    </div>

                                  
                                    <div className="p-inputgroup flex-1 mt-2">
                                        <span className="p-inputgroup-addon"><i className="pi pi-verified"></i></span>
                                        <InputText placeholder={t("reference")}
                                        disabled={loading}
                                        name="referenceCreditCard"
                                        onChange={handleChange}
                                        value={inputs?.referenceCreditCard}
                                        className={(inputs?.bankId && !inputs.referenceCreditCard)? "p-invalid":""}
                                        />
                                        { (inputs?.bankId && !inputs.referenceCreditCard)? <Button tooltip={t("referenceRequired")} icon="pi pi-info" className="p-button-danger" />:<></>}
                                    </div>

                                    </>
                                    : <></>}


                                    </Fieldset>
                                </div>


                                <div className="col-12">
                                    <Fieldset legend={t("transference")}>
                               
                                    <div className="p-inputgroup flex-1 mt-2">
                                        <Dropdown inputId="dropdownStoreTransference" name="bankTransferenceId" value={inputs.bankTransferenceId || undefined } 
                                        options={banks} 
                                        disabled={loading}
                                        scrollHeight='400px'
                                        itemTemplate={countryOptionTemplate} 
                                        valueTemplate={selectedCountryTemplate}
                                        placeholder={t("selectABank")}
                                        showClear
                                        onChange={handleChangeBankTransference} optionLabel="name" optionValue="bankId" />
                                    </div>
                                    
                                    {(inputs.bankTransferenceId) ? 
                                    <>
                                    <div className="p-inputgroup flex-1 mt-2">
                                        <span className="p-inputgroup-addon">L</span>
                                        <InputNumber name="amoutCredit" value={inputs?.amountTransference??undefined} 
                                        disabled={loading}
                                        onChange={(e)=>{
                                            handleChange({target: {
                                                name: "amountTransference",
                                                value: e.value
                                            }})
                                        }} 
                                        locale="en-US" minFractionDigits={2}
                                        placeholder={t("amountTransference")}  className={ ((inputs?.bankTransferenceId && (inputs.amountTransference??0)<=0) || (inputs?.bankTransferenceId && !inputs.amountTransference))? "p-invalid":""}  />
                                        { ((inputs?.bankTransferenceId && (inputs.amountTransference??0)<=0) || (inputs?.bankTransferenceId && !inputs.amountTransference)) ? <Button tooltip={t("amountTransferenceRequired")} icon="pi pi-info" className="p-button-danger" />:<></>}
                                    </div>


                                  
                                    <div className="p-inputgroup flex-1 mt-2">
                                        <span className="p-inputgroup-addon"><i className="pi pi-verified"></i></span>
                                        <InputText placeholder={t("reference")}
                                        disabled={loading}
                                        name="referenceTransference"
                                        onChange={handleChange}
                                        value={inputs?.referenceTransference}
                                        className={(inputs?.bankTransferenceId && !inputs.referenceTransference)? "p-invalid":""}
                                        />
                                        { (inputs?.bankTransferenceId && !inputs.referenceTransference)? <Button tooltip={t("referenceRequired")} icon="pi pi-info" className="p-button-danger" />:<></>}
                                    </div>

                                    <div className="col-4 md:col-3">
                                        <FilePickerInHouse onClose={onImagePicker} photoUri={inputs.transferenceComprobant??""}></FilePickerInHouse>   
                                    </div>

                                    </>
                                    : <></>}


                                    </Fieldset>
                                </div>
         
                            </div>
                        </div>
                        <div className="col-6">
                        <div className="grid">
                            <div className="field col-12 p-fluid">
                                            <label htmlFor="comment" className="block">{t("comment")}</label>
                                            <InputTextarea  id="comment" name="comment" placeholder={t("commentOptional")} value={inputs?.comment??""}
                                            disabled={loading}
                                            onChange={handleChange} 
                                            className={"w-full block"} 
                                            />
                                        </div>
              
                            </div>  
                                        
                            <div className="grid mt-2">
                                <div className="s-total-body">
                                    <p className="s-title">{t("total")}</p>
                                    <p className="s-total">L {format(suscription?.total??0,2)}</p>
                                     {(getChange() <=0)? <p className="s-title mt-5 mb-3" ><strong>{t("outstandingBalance")}</strong> L{format(getChange(),2)}</p> 
                                    :  <p className="s-title mt-5 mb-3" style={{color:"#ff0064"}} ><strong>{t("excess")}</strong> L{format(getChange(),2)}</p>
                                    }
                                    {/*  */}
                                </div>                     
                            </div>       
                        </div>
                    </div>
                </div>      
            </div>
        </Dialog>
    );
  }

export default withTranslation()(PaymentMethod);
