
export class BillDetail { 
    lineId?: number;
    description?: string;
    categoryId?: number;
    total?: number;
    reference?: string;
    createdDate ?: Date;
     
    constructor(datos?: BillDetail) {
        if (datos != null) {
        this.lineId = datos.lineId;
        this.description = datos.description;
        this.categoryId = datos.categoryId;
        this.total = datos.total;
        this.reference = datos.reference;
        this.createdDate = datos.createdDate;
        return;
        }
    }
}