import { BillDetail } from "./bill-detail";

export class Bill { 
    billId?: string;
    description?: string;
    createdDate?: string;
    createdBy?:number;
    createdByName?:string;
    statusId?: number;
    total?: number;
    detail?: BillDetail[];

    constructor(datos?: Bill) {
        if (datos != null) {
        this.billId = datos.billId;
        this.description = datos.description;
        this.createdDate = datos.createdDate;
        this.createdBy = datos.createdBy;
        this.createdByName = datos.createdByName;
        this.statusId = datos.statusId;
        this.detail = datos.detail;
        this.total = datos.total;
        return;
        }
        this.detail = [];

    }
}