import { itemConstants } from "../constants/item.constants";
import { Item } from "../models/item";
import { ItemState } from "../models/item.state";

const initialState: ItemState = { 
    loading: true, 
    error: "",
    itemsSearch:  Array<Item>(), 
    item: new Item(),
    items: Array<Item>(),
    openItem: {
        open: false,
        select: new Item(),
        type: 0,
        loading: false,
    },
    openSelectItem: {
        open: false,
        select: [],
        type: 0,
        loading: false,
    },
    openSelectItemBatch: {
        open: false,
        select: [],
        type: 0,
        loading: false,
    }
};

export function items(state:ItemState = initialState, action:any) {
    switch (action.type) {


        case itemConstants.OPEN_MODAL_SEARCH_ITEM_REQUEST:
            return {
                ...state,
                openSelectItem: {
                    open: action.openSelectItem.open,
                    select: action.openSelectItem.select,
                    type: action.openSelectItem.type,
                    loading: true
                }
            };
        case itemConstants.OPEN_MODAL_SEARCH_ITEM_SUCCESS:
            return {
                ...state,
                openSelectItem: {
                    open: action.openSelectItem.open,
                    select: action.items,
                    type: action.openSelectItem.type,
                    loading: false
                }
            };
        case itemConstants.OPEN_MODAL_SEARCH_ITEM_FAILURE:
            return {
                ...state,
                openSelectItem: {
                    open: action.openSelectItem.open,
                    select: action.openSelectItem.select,
                    type: action.openSelectItem.type,
                    loading: false
                }
            };


        case itemConstants.OPEN_MODAL_SEARCH_ITEM_BATCH_REQUEST:
            return {
                ...state,
                openSelectItemBatch: {
                    open: action.openSelectItemBatch.open,
                    select: action.openSelectItemBatch.select,
                    type: action.openSelectItemBatch.type,
                    loading: true
                }
            };
        case itemConstants.OPEN_MODAL_SEARCH_ITEM_BATCH_SUCCESS:
            return {
                ...state,
                openSelectItemBatch: {
                    open: action.openSelectItemBatch.open,
                    select: action.items,
                    type: action.openSelectItemBatch.type,
                    loading: false
                }
            };
        case itemConstants.OPEN_MODAL_SEARCH_ITEM_BATCH_FAILURE:
            return {
                ...state,
                openSelectItemBatch: {
                    open: action.openSelectItemBatch.open,
                    select: action.openSelectItemBatch.select,
                    type: action.openSelectItemBatch.type,
                    loading: false
                }
            };




        case itemConstants.OPEN_MODAL_ITEM:
            return {
                ...state,
                openItem: action.openItem
            };

        case itemConstants.GET_ITEM_REQUEST:
            return {
                ...state,
                items: [],
                loading: true
            };
        case itemConstants.GET_ITEM_SUCCESS:
            return {
                ...state,
                items: action.items,    
                loading: false
            };
        case itemConstants.GET_ITEM_FAILURE:
            return {
                ...state,
                items: state.items,    
                loading: false
            };
        case itemConstants.SEARCH_ITEM_SELECT_BY_DESCRIPTION_LIST_REQUEST:
            return {
                ...state,
                
                loading: true
            };
        case itemConstants.SEARCH_ITEM_SELECT_BY_DESCRIPTION_LIST_SUCCESS:
            return {
                ...state,
                items: action.items,    
                loading: false
            };
        case itemConstants.SEARCH_ITEM_SELECT_BY_DESCRIPTION_LIST_FAILURE:
            return {
                ...state,
                items: state.items,    
                loading: false
            };
        case itemConstants.EDIT_ITEM_REQUEST:
            return {
                ...state,
                openItem: {
                    open:true,
                    loading: true,
                    select: action.item,
                    type: state.openItem.type
                }
            };
        case itemConstants.EDIT_ITEM_SUCCESS:
            return {
                ...state,
                items: action.items,    
                openItem: {
                    open:false,
                    loading: false,
                    select: action.item,
                    type: state.openItem.type
                }
            };
        case itemConstants.EDIT_ITEM_FAILURE:
            return {
                ...state,
                openItem: {
                    open:true,
                    loading: false,
                    select: action.item,
                    type: state.openItem.type
                }
            };
        case itemConstants.ADD_ITEM_REQUEST:
            return {
                ...state,
                openItem: {
                    open:true,
                    loading: true,
                    select: action.item,
                    type: state.openItem.type
                }
            };
        case itemConstants.ADD_ITEM_SUCCESS:
            return {
                ...state,
                items: action.items,    
                openItem: {
                    open:false,
                    loading: false,
                    select: action.item,
                    type: state.openItem.type
                }
            };
        case itemConstants.ADD_ITEM_FAILURE:
            return {
                ...state,
                openItem: {
                    open:true,
                    loading: false,
                    select: action.item,
                    type: state.openItem.type
                }
            };
        case itemConstants.SEARCH_ITEM_SELECT_BY_DESCRIPTION_REQUEST:
            return {
                ...state,
                openSelectItem: {
                    open: state.openSelectItem.open,
                    select: state.openSelectItem.select,
                    type: state.openSelectItem.type,
                    loading: true
                }
            };
        case itemConstants.SEARCH_ITEM_SELECT_BY_DESCRIPTION_SUCCESS:
            return {
                ...state,
                openSelectItem: {
                    open: state.openSelectItem.open,
                    select: action.items,
                    type: state.openSelectItem.type,
                    loading: false
                }
            };
        case itemConstants.SEARCH_ITEM_SELECT_BY_DESCRIPTION_FAILURE:
            return {
                ...state,
                openSelectItem: {
                    open: state.openSelectItem.open,
                    select: state.openSelectItem.select,
                    type: state.openSelectItem.type,
                    loading: false
                }
            };



        case itemConstants.SEARCH_ITEM_SELECT_BY_DESCRIPTION_BATCH_REQUEST:
            return {
                ...state,
                openSelectItemBatch: {
                    open: state.openSelectItemBatch.open,
                    select: state.openSelectItemBatch.select,
                    type: state.openSelectItemBatch.type,
                    loading: true
                }
            };
        case itemConstants.SEARCH_ITEM_SELECT_BY_DESCRIPTION_BATCH_SUCCESS:
            return {
                ...state,
                openSelectItemBatch: {
                    open: state.openSelectItemBatch.open,
                    select: action.items,
                    type: state.openSelectItemBatch.type,
                    loading: false
                }
            };
        case itemConstants.SEARCH_ITEM_SELECT_BY_DESCRIPTION_BATCH_FAILURE:
            return {
                ...state,
                openSelectItemBatch: {
                    open: state.openSelectItemBatch.open,
                    select: state.openSelectItemBatch.select,
                    type: state.openSelectItemBatch.type,
                    loading: false
                }
            };



        case itemConstants.SEARCH_ITEM_BY_ID_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case itemConstants.SEARCH_ITEM_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                item: action.item
            };
        case itemConstants.SEARCH_ITEM_BY_ID_FAILURE:
            return {
                ...state,
                loading: false
            };
        default:
            return state
    }
}