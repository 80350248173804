import { TypeDialog } from "../../../helpers/type_dialog";
import { batchSuscriptionConstants } from "../constants/batch-suscription.constants";
import { BatchSuscription } from "../models/batch-suscription";
import { BatchSuscriptionState } from "../models/batch-suscription.state";

const initialState: BatchSuscriptionState = { 
    loading: false, 
    error: "",
    batchSuscription: new BatchSuscription(),
    openPaymentMethodBatch: {
        open: false,
        select: 0,
        type: 0,
        loading: false,
    },
};

export function batchSuscription(state:BatchSuscriptionState = initialState, action:any) {
    switch (action.type) {

        //TODO  case batchSuscriptionConstants.OPEN_BATCH_MODAL_SEARCH_ITEM:
        //     return {
        //         ...state,
        //         openSelectSuscription: action.openSelectSuscription
        //     };

        case batchSuscriptionConstants.OPEN_MODAL_PAYMENT_METHOD_BATCH:
            return {
                ...state,
                openPaymentMethodBatch: action.openPaymentMethodBatch
            };
  

        case batchSuscriptionConstants.CLEAN_BATCH_SUSCRIPTION:
            return {
                ...state,
                batchSuscription: new BatchSuscription(),
            };
        case batchSuscriptionConstants.SAVE_BATCH_REQUEST:
            return {
                ...state,
                 loading: true,
                 batchSuscription: action.batchSuscription,
            };
         case batchSuscriptionConstants.SAVE_BATCH_SUCCESS:
            return {
                ...state,
                loading: false,
                batchSuscription: new BatchSuscription(),
                openPaymentMethodBatch:{
                    open : false,
                    select :0,
                    type : TypeDialog.add,
                    loading: false
                  }
            };
        case batchSuscriptionConstants.SAVE_BATCH_FAILURE:
            return {
                ...state,
                loading: false,
                batchSuscription: action.batchSuscription
            };

          case batchSuscriptionConstants.NEXT_BATCH_REQUEST:
            return {
                ...state,
                 loading: true,
            };
         case batchSuscriptionConstants.NEXT_BATCH_SUCCESS:
            return {
                ...state,
                loading: false,
                batchSuscription: action.batchSuscription
            };
        case batchSuscriptionConstants.NEXT_BATCH_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case batchSuscriptionConstants.PREVIEW_BATCH_REQUEST:
            return {
                ...state,
                 loading: true,
            };
         case batchSuscriptionConstants.PREVIEW_BATCH_SUCCESS:
            return {
                ...state,
                loading: false,
                batchSuscription: action.batchSuscription
            };
        case batchSuscriptionConstants.PREVIEW_BATCH_FAILURE:
            return {
                ...state,
                loading: false,
            };

        
        case batchSuscriptionConstants.REMOVE_BATCH_LINE_REQUEST:
            return {
                ...state,
                 loading: true,
                 batchSuscription: action.batchSuscription,
            };
         case batchSuscriptionConstants.REMOVE_BATCH_LINE_SUCCESS:
            return {
                ...state,
                loading: false,
                batchSuscription: action.batchSuscription
            };
        case batchSuscriptionConstants.REMOVE_BATCH_LINE_FAILURE:
            return {
                ...state,
                loading: false,
                batchSuscription: action.batchSuscription
            };
        case batchSuscriptionConstants.ADD_BATCH_LINE_REQUEST:
            return {
                ...state,
                loading: true,
                batchSuscription: action.batchSuscription
            };
        case batchSuscriptionConstants.ADD_BATCH_LINE_SUCCESS:
            return {
                ...state,
                loading: false,
                batchSuscription: action.batchSuscription
            };
        case batchSuscriptionConstants.ADD_BATCH_LINE_FAILURE:
            return {
                ...state,
                loading: false,
                batchSuscription: action.batchSuscription
            };

         case batchSuscriptionConstants.EDIT_BATCH_LINE_QUANTITY_REQUEST:
            return {
                ...state,
                loading: true,
                batchSuscription: action.batchSuscription
            };
        case batchSuscriptionConstants.EDIT_BATCH_LINE_QUANTITY_SUCCESS:
            return {
                ...state,
                loading: false,
                batchSuscription: action.batchSuscription
            };
        case batchSuscriptionConstants.EDIT_BATCH_LINE_QUANTITY_FAILURE:
            return {
                ...state,
                loading: false,
                batchSuscription: new BatchSuscription(action.batchSuscription)
            };
        
        

         case batchSuscriptionConstants.SELECT_CUSTOMER_TEAM_REQUEST:
            return {
                ...state,
                loading: true,
                batchSuscription: new BatchSuscription()
            };
        case batchSuscriptionConstants.SELECT_CUSTOMER_TEAM_SUCCESS:
            return {
                ...state,
                loading: false,
                batchSuscription: action.batchSuscription
            };
        case batchSuscriptionConstants.SELECT_CUSTOMER_TEAM_FAILURE:
            return {
                ...state,
                loading: false,
            };

        

         case batchSuscriptionConstants.PRINT_COMPROBANT_BATCH_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case batchSuscriptionConstants.PRINT_COMPROBANT_BATCH_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case batchSuscriptionConstants.PRINT_COMPROBANT_BATCH_FAILURE:
            return {
                ...state,
                loading: false,
            };



        default:
            return state
    }
}