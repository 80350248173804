import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import Webcam from "react-webcam";
import React, { useEffect } from 'react';
import { DialogProps } from '../../../models/dialog.props';
import { DataDialogProps } from '../../../models/data.dialog.props';
import { filepickerActions } from '../filepicker.action';
import { withTranslation } from 'react-i18next';

  function DialogCamera(props: DialogProps<string>) {
    const { onClose,t } = props;
    const dispatch = useDispatch();    
    const openCamera  = useSelector((state: any) => state.filePicker.openCamera as DataDialogProps<string>);
    const webcamRef = React.useRef<any>(null);   
  
     const capture = React.useCallback(
    () => {
      const imageSrc = webcamRef?.current?.getScreenshot();
        if(imageSrc) {
          onClose!(imageSrc)
          handleClose();
      }
    },
    [webcamRef]
  );
    const handleClose = () => {
     dispatch(filepickerActions.openModalCamera({
                open : false,
                select :openCamera?.select,
                type : 0,
                loading: false
            }));
    };
    

  

    const footer = (
        <div>
            <Button disabled={openCamera?.loading} loading={openCamera.loading} onClick={capture} label={t("takePhoto")} icon="pi pi-save"/>
        </div>
    );

   const videoConstraints = {
        facingMode: "user",
        width: 1280,
        height: 720,
    };
    return (
        <Dialog header={t("camera")} position="top" visible={openCamera?.open??false} footer={footer} 
            onHide={handleClose} breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '45vw'}}>
            <div className="p-fluid grid mt-5">  
                <Webcam style={{"width":"100%"}}ref={webcamRef}  screenshotQuality={1} screenshotFormat="image/jpeg" videoConstraints={videoConstraints}/>
            </div>
        </Dialog>
    );
  }
 

  export default withTranslation()(DialogCamera);
