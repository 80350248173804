import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {  useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TemplatePaginator } from '../../components/TemplatePaginator';
import { Button } from 'primereact/button';
import { User } from '../settings/users/models/user';
import { Badge } from 'primereact/badge';
import { Calendar } from 'primereact/calendar';
import { format, getDefaultDate } from '../../helpers/format';
import Logo from'../../assets/images/logo.png';
import { Suscription } from '../suscriptions/models/suscription';
import { ImageSrc } from '../../components/image-src';
import { AttenLog } from './models/atten-log';
import { customerActions } from './actions/customer.actions';
import { withTranslation, WithTranslation } from 'react-i18next';


function ListAttenLogPage({t}:  WithTranslation ) {
    const attenLogs: AttenLog[]= useSelector((state:any) => state.customer.attenLogs);
    const loading: boolean= useSelector((state:any) => state.customer.loading);
    const dispatch = useDispatch();    
    const navigate = useNavigate();
    const user:User  = useSelector((state: any) => state.authentication.user as User);
    const [fromDate, setFromDate] = useState<Date>(getDefaultDate());
    const [toDate, setToDate] = useState<Date>(getDefaultDate());
 

    useEffect(()=>{
        handSearch()
    },[])
    const handSearch = ()=>{
        dispatch(customerActions.getAttenLoggByDate(fromDate?.toISOString() ?? "", toDate?.toISOString()?? "")); 
    }


    function handleFromDate(e:any) {
        const { value } = e.target;
        setFromDate(value);
   }

   function handleToDate(e:any) {
        const {value } = e.target;
        setToDate(value);
   }
   

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                <div>
                    <h5 className="m-0">{t("attenLog")}</h5>  
                    <div>
                    <div className="p-fluid grid">
                        
                            <div className="field col-4">  
                                <label htmlFor="fromDate" className="block">{t("fromDate")}</label>
                                <Calendar id="fromDate" value={fromDate!= undefined ?  new Date(fromDate??"") :undefined}  onChange={handleFromDate}></Calendar>
                                {/* {(submit&& !fromDate) ?<small id="name-help" className="block p-error">Seleccione la fecha Inicial</small>: <></> }            */}
                            </div>
                            <div className="field col-4">
                                <label htmlFor="toDate" className="block">{t("toDate")}</label>
                                <Calendar id="toDate" value={toDate!= undefined ?  new Date(toDate??"") :undefined} onChange={handleToDate}></Calendar>
                                {/* {(submit&& !toDate) ?<small id="name-help" className="block p-error">Seleccione la fecha final</small>: <></> } */}
                            </div>
                            <div className="field col-4 mt-5">
                                  <Button label={t("search")} onClick={handSearch} icon="pi pi-search" iconPos="left"  />
        
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
    const header = renderHeader();

    const logDateBodyTemplate = (row:Suscription) => {
        if(row.createdDate == null) return <span>{t("notApplicable")}</span>
        let date = new Date(row.createdDate!);
        return <span>{date?.toLocaleDateString("es-US")}</span>
    }
    const photoBodyTemplate = (customer: AttenLog)=>{
        return  (customer.photoUri) ?
            <ImageSrc height="40px" width="60px" src={customer.photoUri} />
            :<img height="40px" width="40px"src={ Logo || ""} alt="" />
        }
     
    //   const statusBodyTemplate = (suscription:Suscription) => {
    //     return <Badge value={bill.statusId == 1? "Activo" : "Anulado"} severity={bill.statusId == 1? undefined: "danger"} ></Badge>
    // }

    return (

            <div className="grid  mt-1">
                    <div className=" col-12 card">
                    {header}
                    <DataTable  emptyMessage={t("noResultFound")} paginatorTemplate={TemplatePaginator as any} 
                        loading={loading} paginator rows={10}  disabled={loading}
                        value={attenLogs??[]} responsiveLayout="scroll"
                        size="small" stripedRows  >
                        <Column filter filterType="contains" field="photoId" header={t("photo")} body={photoBodyTemplate}></Column>
                        {/* <Column filter filterType="contains" field="description" header="Descriptión"></Column> */}
                        <Column  field="customer" header={t("customer")}></Column>
                        {/* <Column  field="pin" header="Pin"></Column> */}
                        <Column sortable  field="hour" header={t("hour")} ></Column>
                        <Column sortable  field="date" header={t("date")} ></Column>
                    </DataTable>
                </div>

            </div>
   
       
    );
}


export default withTranslation()(ListAttenLogPage);