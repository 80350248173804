import axios from 'axios';
import environment from '../../../environment';
import { AppUser } from './models/app-user';

export const appUserService = {
    getUsers,
    sendLinkEmail,
    add,
    edit
};

 function getUsers() {
    return axios.get(`${environment.apiUri}/appUser`)
  };
  
 function add(user: AppUser) {
    return axios.post(`${environment.apiUri}/appUser`, user)
  };
  
 function edit(user: AppUser) {
    return axios.put(`${environment.apiUri}/appUser`, user)
  };
  
 function sendLinkEmail(userId: number) {
    return axios.get(`${environment.apiUri}/appUser/SendEmailWithLink/${userId}`)
  };
