import { combineReducers } from "redux";
import { alert } from "../auth/reducers/alert.reducer";
import { authentication } from "../auth/reducers/authentication.reducer";
import { searchBar } from "../components/search_bar/reducers/search_bar.reducer";
import { categories } from "../pages/category/reducers/item.reducer";
import { customer } from "../pages/customer/reducers/customer.reducer";
import { durations } from "../pages/durations/duration.reducer";
import { filePicker } from "../pages/file_picker_in_house/filepicker.reducer";
import { home } from "../pages/home/reducers/home.reducer";
import { items } from "../pages/items/reducers/item.reducer";
import { offers } from "../pages/offers/reducers/offer.reducer";
import { printPdf } from "../pages/print-pdf/reducers/print-pdf.reducer";
import { reports } from "../pages/reports/reducers/report.reducer";
import { devicesTasks } from "../pages/settings/device-task/reducers/device-task.reducer";
import { permissions } from "../pages/settings/permissions/reducers/permission.reducer";
import { roles } from "../pages/settings/roles/reducers/roles.reducer";
import { users } from "../pages/settings/users/reducers/users.reducer";
import { suscription } from "../pages/suscriptions/reducers/suscription.reducer";
import { vatItems } from "../pages/vat-item/vat-item.reducer";
import { bills } from "../pages/bills/reducers/bills.reducer";
import { listSuscription } from "../pages/list-suscriptions/reducers/list-suscription.reducer";
import { teams } from "../pages/teams/reducers/teams.reducer";
import { batchSuscription } from "../pages/batch-suscriptions/reducers/batch-suscription.reducer";
import { appUsers } from "../pages/the-app/users/reducers/app-users.reducer";
import { pendientPayBanner } from "../components/pendient-pay-banner/pendient-pay-banner.reducer";

export default combineReducers({
    alert,
    authentication,
    users,
    roles,
    permissions,
    offers,
    items,
    categories,
    home,
    searchBar,
    customer,
    suscription,
    durations,
    vatItems,
    devicesTasks,
    printPdf,
    reports,
    filePicker,
    bills,
    listSuscription,
    teams,
    batchSuscription,
    appUsers,
    pendientPayBanner
});
