import axios from 'axios';
import environment from '../../environment';
import { BatchSuscription } from './models/batch-suscription';

export const batchSuscriptionService = {
    save,
    addLine,
    removeLine,
    editLinePrice,
    next,
    preview,
    selectCustomer,
    printComprobant,
};


 function save(batchSuscription:BatchSuscription) {
    return axios.post<BatchSuscription>(`${environment.apiUri}/batchSuscription`, batchSuscription)
  };

 function addLine(batchSuscription:BatchSuscription ,itemId: string) {
    return axios.post(`${environment.apiUri}/batchSuscription/addLine/${itemId}`, batchSuscription)
  };
 function removeLine(batchSuscription:BatchSuscription ,customerId: string) {
    return axios.post(`${environment.apiUri}/batchSuscription/removeLine/${customerId}`, batchSuscription)
  };
 function editLinePrice(batchSuscription:BatchSuscription, customerId :string, itemId: string, price:number) {
    return axios.post(`${environment.apiUri}/batchSuscription/EditLinePrice?customerId=${customerId}&&itemId=${itemId}&&price=${price}`, batchSuscription)
  };

function next(batchSuscriptionId:string) {
    return axios.get(`${environment.apiUri}/batchSuscription/next?BatchSuscriptionId=${batchSuscriptionId}`)
  };

function preview(batchSuscriptionId:string) {
    return axios.get(`${environment.apiUri}/batchSuscription/preview?BatchSuscriptionId=${batchSuscriptionId}`)
  };
function printComprobant(batchSuscriptionId:string) {
    return axios.get(`${environment.apiUri}/batchSuscription/PrintComprobant?BatchSuscriptionId=${batchSuscriptionId}`)
  };

function selectCustomer(customers:string, batchSuscription: BatchSuscription) {
    return axios.post(`${environment.apiUri}/batchSuscription/SelectCustomer?customers=${customers}`, batchSuscription)
  };
