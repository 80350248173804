import { Dispatch } from "@reduxjs/toolkit";
import { alertActions } from "../../../auth/actions/alert.actions";
import { homeConstants } from "../constants/home.constants";
import { homeService } from "../services/home.service";
import { WeekData } from "../models/week-data";


export const homeActions = {
    getHomeData,
    cleanData
};



function getHomeData() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        homeService.getHomeData()
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: homeConstants.GET_HOME_DATA_REQUEST } }
    function success(data:WeekData) { return { type: homeConstants.GET_HOME_DATA_SUCCESS, data } }
    function failure(error:any) { return { type: homeConstants.GET_HOME_DATA_FAILURE, error } }
}
function cleanData() {
    return { type: homeConstants.CLEAN_HOME_DATA};
}
