export const billsConstants = {
    
    GET_BILLS_REQUEST: 'GET_BILLS_REQUEST',
    GET_BILLS_SUCCESS: 'GET_BILLS_SUCCESS',
    GET_BILLS_FAILURE: 'GET_BILLS_FAILURE',


    GET_BILL_BY_ID_REQUEST: 'GET_BILL_BY_ID_REQUEST',
    GET_BILL_BY_ID_SUCCESS: 'GET_BILL_BY_ID_SUCCESS',
    GET_BILL_BY_ID_FAILURE: 'GET_BILL_BY_ID_FAILURE',


    GET_BILL_CATEGORIES_REQUEST: 'GET_BILL_CATEGORIES_REQUEST',
    GET_BILL_CATEGORIES_SUCCESS: 'GET_BILL_CATEGORIES_SUCCESS',
    GET_BILL_CATEGORIES_FAILURE: 'GET_BILL_CATEGORIES_FAILURE',

    SAVE_REQUEST_BILL: 'SAVE_REQUEST_BILL',
    SAVE_SUCCESS_BILL: 'SAVE_SUCCESS_BILL',
    SAVE_FAILURE_BILL: 'SAVE_FAILURE_BILL',

    SAVE_REQUEST_BILL_CATEGORY: 'SAVE_REQUEST_BILL_CATEGORY',
    SAVE_SUCCESS_BILL_CATEGORY: 'SAVE_SUCCESS_BILL_CATEGORY',
    SAVE_FAILURE_BILL_CATEGORY: 'SAVE_FAILURE_BILL_CATEGORY',

    EDIT_REQUEST_BILL_CATEGORY: 'EDIT_REQUEST_BILL_CATEGORY',
    EDIT_SUCCESS_BILL_CATEGORY: 'EDIT_SUCCESS_BILL_CATEGORY',
    EDIT_FAILURE_BILL_CATEGORY: 'EDIT_FAILURE_BILL_CATEGORY',

    NEXT_REQUEST_BILL: 'NEXT_REQUEST_BILL',
    NEXT_SUCCESS_BILL: 'NEXT_SUCCESS_BILL',
    NEXT_FAILURE_BILL: 'NEXT_FAILURE_BILL',

    PREVIEW_REQUEST_BILL: 'PREVIEW_REQUEST_BILL',
    PREVIEW_SUCCESS_BILL: 'PREVIEW_SUCCESS_BILL',
    PREVIEW_FAILURE_BILL: 'PREVIEW_FAILURE_BILL',

    CANCEL_BILL_REQUEST: 'CANCEL_BILL_REQUEST',
    CANCEL_BILL_SUCCESS: 'CANCEL_BILL_SUCCESS',
    CANCEL_BILL_FAILURE: 'CANCEL_BILL_FAILURE',

    CLEAN_BILL: 'CLEAN_BILL',
    OPEN_MODAL_BILL_CATEGORY: 'OPEN_MODAL_BILL_CATEGORY',
};