import { Suscription } from "../../suscriptions/models/suscription";

export class BatchSuscription{
    batchSuscriptionId?:string;
    createdDate?: Date;
    companyId?: string;
    total?: number;
    subTotal?: number;
    vat?: number;
    storeId?: number;
    bankId?: string;
    amountCredit?: number;
    amountCash?: number;
    creditCard?:number;
    referenceCreditCard?:string;
    
    bankTransferenceId?: string;
    referenceTransference?:string;
    amountTransference?: number;
    transferenceComprobant?:string;

    comment?: string;


    suscriptions?: Suscription[];
    
    constructor(datos?: BatchSuscription) {
        if (datos != null) {
        this.batchSuscriptionId = datos.batchSuscriptionId;
        this.total = datos.total;
        this.subTotal = datos.subTotal;
        this.vat = datos.vat;
        this.storeId = datos.storeId;
        this.companyId = datos.companyId;
        this.createdDate = datos.createdDate;
        this.bankId = datos.bankId;
        this.amountCredit = datos.amountCredit;
        this.amountCash = datos.amountCash;
        this.creditCard = datos.creditCard;
        this.referenceCreditCard = datos.referenceCreditCard;
        this.bankTransferenceId = datos.bankTransferenceId;
        this.referenceTransference = datos.referenceTransference;
        this.amountTransference = datos.amountTransference;
        this.transferenceComprobant = datos.transferenceComprobant;
        this.comment = datos.comment;
        this.suscriptions = datos.suscriptions;
        return;
        }

        this.suscriptions = [];
    }
}