import { useDispatch, useSelector } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { useEffect } from 'react';
import React from 'react';
import './BatchSuscription.css';
import { Button } from 'primereact/button';
import { DataTable, DataTableExpandedRows, DataTableValueArray } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Item } from '../items/models/item';
import { customerActions } from '../customer/actions/customer.actions';
import DialogSelectCustomer from '../customer/components/dialog.select-customer';
import { Customer } from '../customer/models/customer';
import DialogSelectItem from '../items/components/dialog.select-item';
import { itemActions } from '../items/actions/item.actions';
import { TypeDialog } from '../../helpers/type_dialog';
import { format, getDateFormat } from '../../helpers/format';
import { DebounceInput } from 'react-debounce-input';
import { ImageSrc } from '../../components/image-src';
import Swal from 'sweetalert2';
import DialogPrintPdf from '../print-pdf/components/dialog.print-pdf';
import { User } from '../settings/users/models/user';
import { useNavigate } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { BatchSuscription } from './models/batch-suscription';
import { batchSuscriptionActions } from './actions/batch-suscription.actions';
import { SuscriptionDetail } from '../suscriptions/models/suscription-detail';
import { Suscription } from '../suscriptions/models/suscription';
import DialogSelectCustomerTeam from './components/dialog.select-customer-team';
import BatchPaymentMethod from './components/batch-payment-method';
import DiaryClose from '../suscriptions/components/diary-close';
import DialogSelectItemBatch from '../items/components/dialog.select-item-batch';
import Logo from'../../assets/images/logo.png';
import { suscriptionActions } from '../suscriptions/actions/suscription.actions';


function BatchSuscriptionPage({t}: WithTranslation) {
    const batchSuscription: BatchSuscription = useSelector((state:any) => state.batchSuscription.batchSuscription);
    const loading: boolean= useSelector((state:any) => state.batchSuscription.loading);
    const [inputs, setInputs] = React.useState<BatchSuscription>(batchSuscription);
    const user:User  = useSelector((state: any) => state.authentication.user as User);
    const [expandedRows, setExpandedRows] = React.useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        setInputs(batchSuscription)
        expandAll();
    }, [batchSuscription]);

    const expandAll = () => {
        let _expandedRows: DataTableExpandedRows = {};
        (batchSuscription.suscriptions??[]).forEach((p) => (_expandedRows[`${p.customerId}`] = true));
        setExpandedRows(_expandedRows);
    };

    const handAdd = () => {
        dispatch(itemActions.openModalSearchItemBatch({
            open : true,
            select : [],
            type : TypeDialog.add,
            loading: false
        }));
    }

    const onSave = () => {
        showPaymentMethod();

        // if((inputs?.detail??[]).length ===0) return;
        
    }

    const onPrintComprobant = () => {
        dispatch(batchSuscriptionActions.printComprobant(inputs.batchSuscriptionId!));
    }

    const onPost = () => {
    //     if((inputs?.detail??[]).length ===0) return;
    //    //TODO  dispatch(batchSuscriptionActions.save(inputs));
    //     Swal.fire({
    //         title: '¿Deseá facturar?',
    //         text: 'Se le solicitara eliga su punto de facturación',
    //         footer: '',
    //         icon: 'question',
    //         showCancelButton: true,
    //         cancelButtonText: 'No',
    //         confirmButtonText: 'Si',
    //         allowOutsideClick: false,
    //         allowEscapeKey: false,
    //         allowEnterKey: false,
    //     }).then(async (result2) => {
    //         if (result2.isConfirmed) {
                
    //         }
    //     });
    }
 
    const onClean = () => {
        dispatch(batchSuscriptionActions.cleanBatchSuscription());
    }
 
    const onPreview = () => {
        dispatch(batchSuscriptionActions.preview(inputs.batchSuscriptionId??""));
    }

    const onPrintDiaryClose= () => {
        dispatch(suscriptionActions.openModalDiaryClose({
            open : true,
            select :"",
            type : TypeDialog.add,
            loading: false
        }));
    }

    const onNext = () => {
        dispatch(batchSuscriptionActions.next(inputs.batchSuscriptionId??""));
    }
    const goToBills = () => {
        navigate(`/bill`)
    }

 

    const searchCustomer = () =>{
        dispatch(customerActions.openModalSearchCustomerTeam({
            open : true,
            select :[],
            type : TypeDialog.add,
            loading: true
        }));
    }
  
    const showPaymentMethod = () =>{
        dispatch(batchSuscriptionActions.openModalPaymentMethodBatch({
            open : true,
            select :inputs.total??0,
            type : TypeDialog.add,
            loading: false
        }));
    }


    const onSelectCustomer = (customer: Customer)=>{    
        dispatch(batchSuscriptionActions.selectCustomer(customer.customers?.map(c=> c.customerId).join(",")??"", inputs));
    }

    const onPaymentMethod = (customer: Customer)=>{
        dispatch(batchSuscriptionActions.selectCustomer(customer.customerId??"", inputs));//TODO 
    }


    const onSelectClosePrint = ()=>{
        
    }


    const onSelectItem = (item: Item)=>{
        dispatch(batchSuscriptionActions.addLine(inputs, item.itemId!));
    }
    const onRemove = (suscription: Suscription)=>{
        dispatch(batchSuscriptionActions.removeLine(inputs, suscription.customerId!));
    }

        const renderHeader = () => {
                return (
                    <div className="flex justify-content-between align-items-center">
                        {/* <h5>{t("itemsAndSuscriptions")}</h5> */}
                        <p></p>
                        <span className="p-input-icon-left mb-2">
                            <Button  style={{width:"200px"}} className='mr-5' disabled={loading || cannotEdit()} label={t("selectACustomer")} onClick={searchCustomer} icon="pi pi-search-plus" iconPos="left"  />
                            <Button  style={{width:"200px"}} disabled={(inputs?.suscriptions?.length == 0 )|| loading || cannotEdit()} label={t("addItem")} onClick={handAdd} icon="pi pi-plus" iconPos="left"  />
                        </span>
                    </div>
                )
            }
    const cannotEdit = () :boolean =>{

        var is = (inputs.batchSuscriptionId??"").toString().length > 1;
        return is;
    }
    const cannotPost = () :boolean =>{

        var is = (inputs.batchSuscriptionId??"").toString().length > 1 // TODO and DOC NUM;
        return is;
    }
    const header = renderHeader();
 
    const actionBodyTemplate = (item:SuscriptionDetail) => {
         return <span className="p-buttonset">
            <Button className="p-button-danger" icon="pi pi-trash" disabled={cannotEdit()} iconPos="left" onClick={(event:any)=>{onRemove(item) }} />
        </span>

    }


    const notHavePermissionToEditPrice = ()=>{

        var permission = user.permissions.find(x=> x.path == "btn_update_price_suscription");//
        return !permission;
        
    }


    const priceBodyTemplate = (item:SuscriptionDetail, suscription: Suscription, options:any) => {    
        return <span className={options.className}>
                 
                 <DebounceInput
             
                        placeholder={t("priceVat")}
                        minLength={1}
                       
                        className='price-item-editable p-inputtext-sm'
                        style={{width:"90px"}}
                        disabled={loading || cannotEdit() || notHavePermissionToEditPrice()}
                        mode="decimal" locale="en-US" minFractionDigits={2} maxFractionDigits={2}
                        onChange={(event:any) => {
        
                            if((event.target.value??"").length > 0){
                                dispatch(batchSuscriptionActions.editLinePrice(inputs, suscription.customerId!, item.itemId!, parseFloat(event.target.value)));
                            }
                            else{
                                event.target.value = item.priceVat;
                            }
                                }} 

                        value={item.priceVat}
                    
                          keyfilter="money"
                        debounceTimeout={400}
                        element={InputText as any} />
        </span>
    }
    const vatBodyTemplate = (row:SuscriptionDetail, options:any) => {
        return (
            <span className={options.className}>
                <span>L {format((row.vat??0),2)}</span>
            </span>
        )
    }
    const totalBodyTemplate = (row:SuscriptionDetail, options:any) => {
        return (
            <span className={options.className}>
                <span>L {format((row.total??0),2)}</span>
            </span>
        )
    }
    
    const hasPermissionToDiaryClose = ()=>{
        var permission = user.permissions.find(x=> x.path == "btn_diary_close");
        return permission;
    }

    const canAddBill = ()=>{
        var permission = user.permissions.find(x=> x.path == "btn_add_bill");
        return permission;
    }
  

    // const quantityBodyTemplate = (row:SuscriptionDetail, options:any) => {
    //     return (
    //         <span className={options.className}>

               
    //              <DebounceInput
    //                     placeholder={t("quantity")}
    //                     minLength={1}
    //                     style={{width:"100%", maxWidth:"80px"}}
    //                     disabled={cannotEdit()}
    //                     onChange={(event:any) => {
    //                         let pattern = /[^1-9]/g;
    //                         let text= event.target?.value??"";
    //                         if(!text.match(pattern)){
    //                             if((event.target?.value??"").length > 0){
    //                             dispatch(batchSuscriptionActions.editLinePrice(inputs, row.itemId!, parseFloat(event.target.value)));
    //                             }
    //                             else{
    //                                event.target.value = row.quantity;
    //                             }
    //                         }
    //                         else{
    //                             event.target.value = row.quantity;
    //                         }
    //                     }} 
    //                     //onClick={(event:any)=>{event.target?.selectAll(); }}
    //                     value={row.quantity}
    //                     keyfilter="int"
    //                     debounceTimeout={400}
    //                     element={InputText as any} />
    //         </span>
    //     )
    // }

    const startingDateBodyTemplate = (row:Suscription, options:any) => {
        if(row.startingDate == null) return <span>{t("notApplicable")}</span>
        let date = new Date(row.startingDate!);
        return <span>{date?.toLocaleDateString("es-US")}</span>
    }
    const endingDateBodyTemplate = (row:Suscription, options:any) => {
        if(row.endingDate == null) return <span>{t("notApplicable")}</span>
        let date = new Date(row.endingDate!);
        return <span>{date?.toLocaleDateString("es-US")}</span>
    }


    // const headerTemplate = (data: Suscription) => {
    //     return (
    //         <React.Fragment>
    //            <ImageSrc  height='45px' src={data.photoUri} />
    //             {/* <img alt={data.customer} src={`https://primefaces.org/cdn/primereact/images/avatar/${data.representative.image}`} width="32" style={{ verticalAlign: 'middle' }} className="ml-2" /> */}
    //             <span className="vertical-align-middle ml-2 font-bold line-height-3">{data.customer}</span>
    //         </React.Fragment>
    //     );
    // };
    

    // const footerTemplate = (data: Suscription) => {
    //     return (
    //         <React.Fragment>
    //             <td colSpan={5}>
    //                 <div className="flex justify-content-end font-bold w-full">{t("Total")}: L {format(inputs?.total??0,2)}</div>
    //             </td>
    //         </React.Fragment>
    //     );
    // };


    const rowExpansionTemplate = (data: Suscription) => {
        return (
         <div className='pl-6'>
        
                <DataTable value={(data.detail??[])} size="small" >
                    {/* <Column field="itemId" header={t("id")}></Column>  */}
                    <Column field="description" header={t("description")} style={{width:"30%"}}></Column>
                    <Column field="quantity"  header={t("quantity")}></Column>
                    {/* <Column field="days" header={t("days")}></Column> */}
                    <Column field="priceVat" header={t("priceVat")} body={(detail, option)=>{ return priceBodyTemplate(detail, data, option)}} style={{width:"110px"}}></Column>
                    <Column field="vat" header={t("vat")} body={vatBodyTemplate}></Column>
                    <Column field="total" header={t("total")} body={totalBodyTemplate}></Column>
                    {/* <Column header={t("action")} body={actionBodyTemplate} />  */}
                </DataTable>
        </div>
      
        );
    };

    const photoBodyTemplate = (suscription: Suscription)=>{

        return  (suscription.photoUri??"") ?
            <ImageSrc height="25px" width="25px" src={suscription.photoUri} />

            :<img height="25px" width="25px"src={ Logo || ""} alt="" />

    }

    return (
        <div className="card">
          
                <div className="grid">
                        <div className="col-12">
                            <div className="flex justify-content-start">
                                <Button icon="pi pi-arrow-left" loading={loading} disabled={loading} onClick={onPreview} className="p-button-rounded p-button" aria-label={t("previous")} />
                                <Button icon="pi pi-arrow-right" loading={loading} disabled={loading} onClick={onNext} className="p-button-rounded p-button" aria-label={t("next")} />
                                <Button icon="pi pi-print" disabled={!cannotEdit()} loading={loading} onClick={onPrintComprobant} className="p-button-rounded p-button-danger" />
                                <Button icon="pi pi-file" loading={loading} label={t("new")} onClick={onClean} className="p-button-rounded " />
                                <Button icon="pi pi-save" loading={loading} disabled={cannotEdit() || (inputs?.suscriptions??[]).length ===0 }  label={t("save")} onClick={onSave} className="p-button-rounded p-button-danger" />
                                {
                                    cannotPost()? <Button icon="pi pi-save" loading={loading} disabled={cannotEdit()}  label={t("bill")} onClick={onPost} className="p-button-rounded p-button-help" />
                                    :<div/>
                                }
                                {/* <Button icon="pi pi-print" loading={loading} label="Imprimir Factura" onClick={onPrintInvoice} className="p-button-rounded p-button-danger" /> */}

                            </div>
                                <div className="flex justify-content-end">
                                    <Button icon="pi pi-print" loading={loading} disabled={loading || !hasPermissionToDiaryClose()} onClick={onPrintDiaryClose} className="p-button-rounded p-button"  label={t("diaryClose")}  aria-label="Anterior" />
                                    <Button icon="pi pi-cart-plus" disabled={loading ||  !canAddBill()} loading={loading }  onClick={goToBills} className="p-button-rounded  p-button-danger" label={t("bills")}  aria-label="Anterior" /> 
                                </div>
                            </div>
                        <div className="col-12 mt-2">
                            <h4>{t("teamSuscriptions")} {inputs.batchSuscriptionId}</h4>
                            <div className="grid">
                                {/* <div className="col-9">
                                    <div className=" grid">
                                        <div className="field col-12 p-inputgroup">
                                            <InputText disabled={true} id="customer" className="block" value={inputs.customer || ""} readOnly={true}   placeholder={t("selectACustomer")}/>
                                            <Button disabled={cannotEdit()} icon="pi pi-search-plus" onClick={searchCustomer} label={t("selectACustomer")}/>
                                        </div>
                                        <div className="field col-4 p-fluid">
                                            <label htmlFor="startingDate" className="block">{t("startingDate")}</label>
                                            <InputText id="startingDate"disabled={true} value={getDateFormat(inputs.startingDate, user.lang)} readOnly={true} className="block"/>
                                        </div>
                                        <div className="field col-4 p-fluid">
                                            <label htmlFor="endingDate" className="block">{t("endingDate")}</label>
                                            <InputText id="endingDate" disabled={true} value={getDateFormat(inputs.endingDate, user.lang)} readOnly={true} className="block"/>
                                        </div>
                                        <div className="field col-4 p-fluid">
                                            <label htmlFor="daysLeft" className="block">{t("leftDays")}</label>
                                            <InputText id="daysLeft" disabled={true} value={(inputs?.leftDays??0).toString()} readOnly={true} className="block"/>
                                        </div>
                                      
                                    </div>

                                   
                                </div> */}



                                 <div className="col-12">
                                            {header}
                                            <DataTable  emptyMessage={t("noResultFound")}
                                                    loading={loading} scrollable scrollHeight='500px' showGridlines  height={200}  disabled={loading}
                                                    value={inputs.suscriptions??[]} responsiveLayout="scroll"  
                                                //    rowGroupHeaderTemplate={headerTemplate} 
                                                   expandedRows={expandedRows}
                                                   dataKey="customerId"
                                                    // rowGroupFooterTemplate={footerTemplate} 
                                                    expandableRowGroups 
                                                    // onRowExpand={onRowExpand}
                                                    rowExpansionTemplate={rowExpansionTemplate}
                                                    size="small" stripedRows  >
                                                    {/* <Column expander={true} style={{ width: '5rem' }} /> */}

                                                    <Column  field="photoUri" header={t("photo")} body={photoBodyTemplate}></Column>
                                                    {/* <Column  field="customerId" header={t("id")}></Column> */}
                                                    <Column  field="customer" header={t("customer")} style={{width:"30%"}} ></Column>
                                                    <Column  field="startingDate" header={t("startingDate")} body={startingDateBodyTemplate} ></Column>
                                                    <Column  field="endingDate" header={t("endingDate")} body={endingDateBodyTemplate}></Column>
                                                    {/* <Column  field="leftDays" header={t("leftDays")} style={{width:"11%"}}></Column> */}
                                                    <Column header={t("action")} body={actionBodyTemplate} /> 
                                                </DataTable>
                                     
                                        </div>

                                    <div className="col-12">
                                        <div className="grid">
                                            <div className="col-9">
                                                <div className="s-total-body">
                                                    <p className="s-title">{t("total")}</p>
                                                    <p className="s-total">L {format(inputs?.total??0,2)}</p>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <p>{t("discount")}: L 0.00</p>
                                                <p>{t("vat")} L {format(inputs?.vat??0,2)}</p>
                                                <p>{t("subTotal")}: L {format(inputs?.subTotal??0,2)}</p>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                <DialogSelectCustomerTeam onSelect={onSelectCustomer}/>
                <DialogSelectItemBatch onSelect={onSelectItem}/>
                <DialogPrintPdf onClose={onSelectClosePrint}/>
                <BatchPaymentMethod onClose={onPaymentMethod}/>
                <DiaryClose onClose={()=>{/*NOTHING*/ }}/>
                </div>
        
        </div>
    );
}

export default withTranslation()(BatchSuscriptionPage);
