import { billsConstants } from "../constanst/bills.constants";
import { Bill } from "../models/bill";
import { BillCategory } from "../models/bill-category";
import { BillsState } from "../models/bills.state";

const initialState: BillsState = { 
    loading: false, 
    error: "",
    bill: new Bill(),
    bills: [],
    categories: [],
    openBillCategory: {
        open: false,
        select: new BillCategory(),
        type:0,
        loading: false
    }
};

export function bills(state:BillsState = initialState, action:any) {
    switch (action.type) {

        case billsConstants.OPEN_MODAL_BILL_CATEGORY:
            return {
                ...state,
                openBillCategory: action.openBillCategory
            }


        case billsConstants.CLEAN_BILL:
            return {
                ...state,
                bill: new Bill(),
                
            };
        case billsConstants.SAVE_REQUEST_BILL:
            return {
                ...state,
                 loading: true,
                 bill: action.bill,
            };
         case billsConstants.SAVE_SUCCESS_BILL:
            return {
                ...state,
                loading: false,
                bill: action.bill
            };
        case billsConstants.SAVE_FAILURE_BILL:
            return {
                ...state,
                loading: false,
                bill: action.bill
            };
        

        
        case billsConstants.SAVE_REQUEST_BILL_CATEGORY:
            return {
                ...state,
                    openBillCategory: {
                        open: state.openBillCategory.open,
                        select: action.billCategory,
                        type: state.openBillCategory.type,
                        loading: true,
                    }
            };
            case billsConstants.SAVE_SUCCESS_BILL_CATEGORY:
            return {
                ...state,
                categories: action.categories,
                openBillCategory: {
                    open: false,
                    select: state.openBillCategory.select,
                    type: state.openBillCategory.type,
                    loading: false,
                }
            };
        case billsConstants.SAVE_FAILURE_BILL_CATEGORY:
            return {
                ...state,
                openBillCategory: {
                    open: true,
                    select: action.billCategory,
                    type: state.openBillCategory.type,
                    loading: false,
                }
            };

        case billsConstants.EDIT_REQUEST_BILL_CATEGORY:
            return {
                ...state,
                openBillCategory: {
                    open: state.openBillCategory.open,
                    select: action.billCategory,
                    type: state.openBillCategory.type,
                    loading: true,
                }
            };
            case billsConstants.EDIT_SUCCESS_BILL_CATEGORY:
            return {
                ...state,
                categories: action.categories,
                openBillCategory: {
                    open: false,
                    select: state.openBillCategory.select,
                    type: state.openBillCategory.type,
                    loading: false,
                }
            };
        case billsConstants.EDIT_FAILURE_BILL_CATEGORY:
            return {
                ...state,
                openBillCategory: {
                    open: true,
                    select: action.billCategory,
                    type: state.openBillCategory.type,
                    loading: false,
                }
            };



        
        case billsConstants.GET_BILLS_REQUEST:
            return {
                ...state,
                loading: true,
                bills: []
            };
        case billsConstants.GET_BILLS_SUCCESS:
            return {
                ...state,
                loading: false,
                bills: action.bills
            };
        case billsConstants.GET_BILLS_FAILURE:
            return {
                ...state,
                loading: false,
                bills: []
            };



        case billsConstants.NEXT_REQUEST_BILL:
            return {
                ...state,
                loading: true,
            };
        case billsConstants.NEXT_SUCCESS_BILL:
            return {
                ...state,
                loading: false,
                bill: action.bill
            };
        case billsConstants.NEXT_FAILURE_BILL:
            return {
                ...state,
                loading: false,
            };


        case billsConstants.PREVIEW_REQUEST_BILL:
            return {
                ...state,
                loading: true,
            };
        case billsConstants.PREVIEW_SUCCESS_BILL:
            return {
                ...state,
                loading: false,
                bill: action.bill
            };
        case billsConstants.PREVIEW_FAILURE_BILL:
            return {
                ...state,
                loading: false,
            };




        case billsConstants.GET_BILL_BY_ID_REQUEST:
            return {
                ...state,
                loading: true,
                bill: new Bill()
            };
        case billsConstants.GET_BILL_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                bill: action.bill
            };
        case billsConstants.GET_BILL_BY_ID_FAILURE:
            return {
                ...state,
                loading: false,
            };


        case billsConstants.CANCEL_BILL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case billsConstants.CANCEL_BILL_SUCCESS:
            return {
                ...state,
                loading: false,
                bill: action.bill
            };
        case billsConstants.CANCEL_BILL_FAILURE:
            return {
                ...state,
                loading: false,
            };




         case billsConstants.GET_BILL_CATEGORIES_REQUEST:
            return {
                ...state,
                loading: true,
                categories: []
            };
        case billsConstants.GET_BILL_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                categories: action.categories
            };
        case billsConstants.GET_BILL_CATEGORIES_FAILURE:
            return {
                ...state,
                loading: false,
                categories: []
            };
        default:
            return state
    }
}