import i18n from "../i18n";

export const format = (num: number, decimals: number) =>
  num.toLocaleString('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

export const getDateFormat = (date?:Date, lang?:string)=>{
      if(date == null || !date ) return i18n.t("notApplicable")
      let dateFormat = new Date(date!);
      return dateFormat?.toLocaleDateString(lang == "es"? "es-HN": "en-US");
  }
export const getDateFormatNew = (date?:Date)=>{
      if(date == null || !date ) return new Date()
      let dateFormat = new Date(date!);
      return dateFormat;
  }


export const getDefaultDate=()=>{
    let today = new Date();
    today.setHours(0)
    today.setMinutes(0)
    today.setSeconds(0)

    return today;
}
export async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {

    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], fileName, { type: 'image/png' });
}
