import {Button} from 'primereact/button';
import {Message} from 'primereact/message';
import {useEffect, useState} from 'react';
import PaymentIcon from '../../assets/images/paymenticon.png';
import {useDispatch, useSelector} from 'react-redux';
import { pendientPayBannerActions } from './pendient-pay-banner.action';

function PendientPayBanner() {
    const user = useSelector((state : any) => state.authentication.user);
    const message = useSelector((state : any) => state.pendientPayBanner.message);
    const isOpen = useSelector((state : any) => state.pendientPayBanner.isOpen);
    
    const [open, setOpen] = useState<boolean>(false);

    const dispatch = useDispatch();
    useEffect(()=>{
        setOpen(isOpen)
    },[isOpen])
    
    useEffect(() => {
        dispatch(pendientPayBannerActions.searchSuscription());
    }, [])

    const clearMessages = () => {
        dispatch(pendientPayBannerActions.closeMessage());
    };


    const content = () => {
        return (
            <div className="flex align-items-center">
                <img alt="logo"
                    src={PaymentIcon}
                    width="32"/>
                <div className="ml-2">
                    <p>{message}</p>
                </div>

                {/* <Button className='ml-4' label="Últimos pagos" severity="danger" onClick={goToPay}></Button> */}
                <Button className='ml-4'icon="pi pi-times" severity="danger" onClick={clearMessages}></Button>
            </div>
        )
    }
    return ( <div style={{position: "absolute", width: "fit-content", left:0, right:0, top:"20px", marginInline: "auto"}}>
             {
            open ?
            <Message style={
                    {
                        border: 'solid #a27272',
                        borderWidth: '0 0 0 6px',
                        color: '#a27272'
                    }
                }
                className="w-full justify-content-start"
                severity="error"
                content={content}/>
                : <></>
                
            }
            </div>
    );
}


export {
    PendientPayBanner
}
