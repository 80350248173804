import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { DataDialogProps } from '../../../models/data.dialog.props';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Item } from '../models/item';
import { DialogProps } from '../../../models/dialog.props';
import { itemActions } from '../actions/item.actions';
import { InputText } from 'primereact/inputtext';
import {DebounceInput} from 'react-debounce-input';
import { TemplatePaginator } from '../../../components/TemplatePaginator';
import { format } from '../../../helpers/format';
import Logo from'../../../assets/images/logo.png';
import { ImageSrc } from '../../../components/image-src';
import { withTranslation } from 'react-i18next';


  function DialogSelectItemBatch(props: DialogProps<Item>) {
    const { onSelect, t} = props;
    const dispatch = useDispatch();    
    const openSelectItemBatch  = useSelector((state: any) => state.items.openSelectItemBatch as DataDialogProps<Item[]>);

    const handleClose = () => {
      dispatch(itemActions.openModalSearchItemBatch({
        open : false,
        select :[],
        type : 0,
        loading: false
      }));
    };

     const renderHeader = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                    <span className="p-input-icon-left" style={{width:"100%"}}>
                    { openSelectItemBatch.loading ? <i className="pi pi-spin pi-spinner" />: <i className="pi pi-search" /> }
                     <DebounceInput
                        placeholder={t("search")}
                        minLength={1}
                        style={{width:"100%"}}
                        onChange={event => {
                            if((event.target?.value??"").length > 1){
                                dispatch(itemActions.searchByDescriptionBatch(event.target.value));
                            }
                        }} 
                        value=""
                        debounceTimeout={400}
                        element={InputText as any} />
                </span>
                {/* <h5 className="m-0">{ "Seleccione un tipo de item"}</h5>
                    <Button style={{width: "170px"}} label={"Agregar " + typeItem} icon="pi pi-plus" /> */}
            </div>
        )
    }

    const handOnSelectItem = (item: Item)=>{
        onSelect!(item);
        handleClose();
    }

    const header = renderHeader();
    const dateBodyTemplate = (rowData:Item) => {
        // let date = new Date(rowData.createdDate!);
        // return <span>{date?.toLocaleDateString(user.lang == "es"? "es-HN": "en-US")}</span>
        // return <img width={55} src={rowData.thumbnail} alt="" />;
    }

    const priceBodyTemplate = (row:Item, options:any) => {
        return (
            <span className={options.className}>
                <span>L {format((row.priceVat??0),2)}</span>
            </span>
        )
    }
    const photoBodyTemplate = (item: Item)=>{
        return  (item.fileId != 0) ?
                                <ImageSrc height="25px" width="25px" src={item.photoUri} />
                
                                :<img height="25px" width="25px"src={ Logo || ""} alt="" />
        }
    return (
        <Dialog header={t("teamItemsList")} position="top" visible={openSelectItemBatch?.open??false} onHide={handleClose} breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '45vw'}}>
            <div>  
                <DataTable  emptyMessage={t("noResultFound")} loading={openSelectItemBatch.loading} paginatorTemplate={TemplatePaginator as any} 
                    disabled={openSelectItemBatch.loading}  rows={10}  selectionMode="single"
                    value={openSelectItemBatch.select} responsiveLayout="scroll" 
                    onSelectionChange={e => handOnSelectItem(e.value)} stripedRows  size="small" header={header} >
                    <Column field="photoUri" header="Imagen" body={photoBodyTemplate}></Column>
                    <Column field="itemId" header="No"></Column>
                    <Column field="name" header="Nombre"></Column>
                    <Column field="priceVat" header="Precio" body={priceBodyTemplate}></Column>
                    {/* <Column field="active" body={statusBodyTemplate} header="Estado"></Column>
                    <Column header="Acción" body={actionBodyTemplate} /> */}
                </DataTable>
            </div>
        </Dialog>
    );
  }
    

  
  export default withTranslation()(DialogSelectItemBatch);
