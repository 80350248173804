import { Dispatch } from "@reduxjs/toolkit";
import { alertActions } from "../../auth/actions/alert.actions";
import { pendientPayBannerService } from "./pendient-pay-banner.service";
import { PendientPayBannerModel } from "./models/pendient-pay-banner.model";
import { pendientPayBannerConstants } from "./pendient-pay-banner.constants";

export const pendientPayBannerActions = {
    searchSuscription,
    closeMessage,
};

function searchSuscription() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        pendientPayBannerService.getPendientPayBanner()
            .then((result)=> { 
                    dispatch(success(result.data));
                },
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
              
            });
    };
    function request() { return { type: pendientPayBannerConstants.GET_PENDIENT_PAY_BANNER_REQUEST, } }
    function success(pendientPayBanner:PendientPayBannerModel) { return { type: pendientPayBannerConstants.GET_PENDIENT_PAY_BANNER_SUCCESS, pendientPayBanner } }
    function failure(error:any) { return { type: pendientPayBannerConstants.GET_PENDIENT_PAY_BANNER_FAILURE, error } }
}
function closeMessage() {
    return (dispatch: Dispatch) => {
        dispatch(  { type: pendientPayBannerConstants.CLOSE_PENDIENT_PAY_BANNER });
    };
}