import i18n from "../i18n";
import { Langs } from "../pages/settings/users/models/langs";

export const TypeItems = [{type: 0, name:"Suscripción"}, {type: 1, name:"Producto"}, {type: 2, name:"Servicio"}]


//TODO 
export const  months = [{month: 1, name: 'Enero'}, {month: 2, name: 'Febrero'}, {month: 3, name: 'Marzo'}, {month: 4, name: 'Abríl'}, {month: 5, name: 'Mayo'}
                        , {month: 6, name: 'Junio'}, , {month: 6, name: 'Julio'}, {month: 8, name: 'Agosto'}, {month: 9, name: 'Septiembre'}
                        , {month: 10, name: 'Octubre'}, , {month: 11, name: 'Noviembre'}, {month: 12, name: 'Diciembre'}];

export const years = [{name: 2018},{name: 2019}, {name: 2020}, {name: 2021},  {name: 2022}, {name: 2023}, {name: 2024}, {name: 2025}, {name: 2026},{name: 2027}]


export const languages = [ new Langs({ lang:"en", name:i18n.t("english") }), new Langs({ lang:"es", name:i18n.t("spanish") })]
