import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { DataDialogProps } from '../../../models/data.dialog.props';
import { DialogProps } from '../../../models/dialog.props';
import { customerActions } from '../actions/customer.actions';
import React, { useEffect } from 'react';
import { TypeDialog } from '../../../helpers/type_dialog';
import EnrolFinger1Logo from'../../../assets/images/enrolFinger1.png';
import EnrolFinger2Logo from'../../../assets/images/enrolFinger2.png';
import FingerPrintSuccessLogo from'../../../assets/images/success-fingerprint.jpg';
import LoadingLogo from'../../../assets/images/loading.webp';
import ConectingLogo from'../../../assets/images/loading.gif';
import FingerPrintErrorLogo from'../../../assets/images/fingerPrintError.webp';
import { withTranslation } from 'react-i18next';


const statuses = {
    loading:  1,
    firstFinger:2,
    secondFinger:3,
    thirdFinger:4,
    success:5,
    saving: 6,
    error: 7
}
  function DialogNewEnrolFinger(props: DialogProps<string>) {
    const { onClose, t} = props;
    const dispatch = useDispatch();    
    const openNewEnrolFinger  = useSelector((state: any) => state.customer.openNewEnrolFinger as DataDialogProps<string>);
    const [status, setStatus] = React.useState<number>(statuses.loading);
    const [isEnrolling, setIsEnrolling] = React.useState<boolean>(false);

    useEffect(() => {
         if(openNewEnrolFinger.select && !isEnrolling)
        {
            setIsEnrolling(true);
            (window as any).electron.enroll(onFirstFinger, onSecondFinger, onThirdFinger,onFingerPrintDone, onFail);
           
        }
        else{
            setIsEnrolling(false);
            setStatus(statuses.loading)
        }
        
        if(openNewEnrolFinger.loading) setStatus(statuses.saving)
    }, [openNewEnrolFinger]);

    const handleClose = () => {
        (window as any).electron.close();
    onClose!("OK");
      dispatch(customerActions.closeNewEnrolFinger({
            open : false,
            select :"",
            type : TypeDialog.add,
            loading: false
        }));
    };
   
    const onFirstFinger = ()=>{
        setStatus(statuses.firstFinger)
    }
    const onSecondFinger = ()=>{
        setStatus(statuses.secondFinger)
    }
    const onThirdFinger = ()=>{
        setStatus(statuses.thirdFinger)
    }
 
    const onFingerPrintDone = (fingerPrint:string)=>{
        if( !openNewEnrolFinger.select) return;
        dispatch(customerActions.saveFingePrint(
            openNewEnrolFinger.select,
            fingerPrint
        ));

    }
    const onFail = (ex:string)=>{
        setStatus(statuses.error)
        console.error("FAIL", ex)
    }

    const footer = (
        <div>
           {/* <Button loading={openNewEnrolFinger.loading} disabled={openNewEnrolFinger.loading} label="Confirmar" onClick={handleClose} icon="pi pi-arrow-right"/> */}
        </div>
    );

    const loadingStatus = ()=>{
        return <>
            <div style={{textAlign:"center"}}>
                <img height="250px" width={"290px"} src={ ConectingLogo || ""} alt="" />
                <h4>{t("conectingToTheFingerReader")}</h4>
            </div>
        </>
    }

    const firstFingerStatus = ()=>{
        return <>
            <div style={{textAlign:"center"}}>
                <img height="250px" width={"250px"}src={ EnrolFinger1Logo || ""} alt="" />
                <h4>{t("pleasePutTheCustomerFinger")}</h4>
            </div>
        </>
    }

    const secondFingerStatus = ()=>{
        return <>
            <div style={{textAlign:"center"}}>
                <img height="250px" width={"250px"} src={ EnrolFinger2Logo || ""} alt="" />
                <h4>{t("pleasePutTheCustomerFingerAgain")}</h4>
            </div>
        </>
    }
    const thirdFingerStatus = ()=>{
        return <>
            <div style={{textAlign:"center"}}>
                <img height="250px" width={"250px"} src={ EnrolFinger2Logo || ""} alt="" />
                <h4>{t("pleasePutTheCustomerFingerLastTime")}</h4>
            </div>
        </>
    }

    const fingerPrintSuccessStatus = ()=>{
        return <>
            <div style={{textAlign:"center"}}>
                <img height="250px" width={"290px"} src={ FingerPrintSuccessLogo || ""} alt="" />
                <h4>{t("fingerPritTakedSuccess")}</h4>
            </div>
        </>
    }
    const savingStatus = ()=>{
        return <>
            <div style={{textAlign:"center"}}>
                <img height="250px" width={"290px"} src={ LoadingLogo || ""} alt="" />
                <h4>{t("saving")}...</h4>
            </div>
        </>
    }
    const errorStatus = ()=>{
        return <>
            <h4>Error</h4>
            <img height="250px" width={"290px"} src={ FingerPrintErrorLogo || ""} alt="" />
        </>
    }

    const getStatus = (status: number)=>{
        switch(status){
            case statuses.loading:
                return loadingStatus()
            case statuses.firstFinger:
                return firstFingerStatus()
            case statuses.secondFinger:
                return secondFingerStatus()
            case statuses.thirdFinger:
                return thirdFingerStatus()
            case statuses.success:
                return fingerPrintSuccessStatus()
            case statuses.saving:
                return savingStatus()
            case statuses.error:
                return errorStatus()
        }
    }
  
    return (
        <Dialog  header={"Asignar Huella"} position='top'  footer={footer} visible={openNewEnrolFinger?.open??false} onHide={handleClose} closeOnEscape={false} breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '65vw'}}>
            <div className='p-fluid grid'>   
                <div className="col-12 mt-5">
                    {/* <p>Hemos enviado la configuración al lector de huellas, por favor que el cliente coloque su huella sobre el lector <strong>3</strong> veces </p> */}
                    <div className="grid">
                        <div className="col-12">
                            {
                               getStatus(status)                                        
                            }
                            
                        </div>
                    </div>
                </div>
  
            </div>
        </Dialog>
    );
  }
    
  export default withTranslation()(DialogNewEnrolFinger);
