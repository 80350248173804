import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "hello": "Hello",
      "diarySales": "Daily Sale",
      "usersList": "Users List",
      "appUsersList": "APP! Users List",
      "addNew": "Add one",
      "noAccessToActionRequest": "No access to the request action",
      "edit": "Edit",
      "add": "Add",
      "noResultFound": "No result found",
      "id":"Id",
      "name":"Name",
      "nameRequired":"Name is required",
      "userName": "User Name",
      "userNameRequired": "User Name is required",
      "password":"Password",
      "passwordRequired": "Password is required",
      "email": "Email",
      "emailRequired": "Email is required",
      "role": "Role",
      "roleRequired": "Role is required",
      "office": "Office",
      "officeRequired":"Office is required",
      "documentSize": "Document Size",
      "documentSizeRequired": "Document Size is required",
      "language": "Language",
      "languageRequired": "Language is required",
      "action": "Action",
      "pos": "POS",
      "letter": "Letter",
      "english":"English",
      "spanish":"Spanish",
      "active": "Active",
      "inactive": "Inactive",
      "rolesList": "Roles List",
      "status": "Status",
      "createdDate": "Created Date",
      "search": "Search",
      "employee": "Employee",
      "photo": "Photo",
      "dni": "Dni",
      "dniRequired": "Dni is required",
      "rtn": "Rtn",
      "phone": "Phone",
      "phoneRequired":"Phone is required",
      "actionNotAvailableOnTheWebPleaseInstallTheDesktopApp":"Action not available on the web, please install the desktop app",
      "doYouWantToSyncThisFingerPrintManually": "Do you want to sync this finger print manually?",
      "ifThisCustomerHaventPaidTheFingerWillRemoveAutomatically":"If this customer haven't paid the finger print will be remove automatically",
      "yes": "Yes",
      "no":"No",
      "doYouWantToRemoveThisFingerPrintManually":"Do you want to remove this FingerPrint manually?",
      "doYouWantToRemovePermanently": "Do you want to remove the finger print permanently this cannot be undo?",
      "willRemoveTheFingerPrintFromTheClockAndYouWillNeedToAddedAgain":"Will remove the FingerPrint from the clock and you will need to add it again",
      "doYouWantToMarkThisCustomerAsEmployee":" Do you want to mark this customer as employee?",
      "thisActionWillPreventTheSystemRemoveTheFingerPrintAutomatically": "This action will prevent to the system to remove the FingerPrint automatically",
      "thisActionWillConvertThisEmployeeToCustomer": "This action will convert this employee to customer",
      "doYouWantToRemoveThisEmployee":"Do you want to remove this employee?",
      "save": "Save",
      "customer": "Customer",
      "birthDay":"BirthDay",
      "assignedFingerPrint": "Assigned FingerPrint",
      "sync":"Sync",
      "remove": "Remove",
      "removeFingerPrint": "Remove FingerPrint",
      "enrollFingerPrint": "Enroll FingerPrint",
      "markAsEmployee": "Mark as employee",
      "convertToCustomer": "Convert to Customer",
      "conectingToTheFingerReader": "Conecting to the FingerReader...",
      "pleasePutTheCustomerFinger": "Please put the customer finger",
      "pleasePutTheCustomerFingerAgain": "Please put the customer finger again",
      "pleasePutTheCustomerFingerLastTime": "Please put the customer finger last time",
      "fingerPritTakedSuccess":"FingerPrit taked success",
      "saving": "Saving",
      "camera": "Camera",
      "takePhoto": "Take Photo",
      "fileSizeIsToBigMax5Mb":'File size is yo big the max size is 5Mb',
      "gallery": "Gallery",
      "done":"Done",
      "linesPerPage": "Lines per page",
      "notApplicable":"Not Applicable",
      "startingDate": "Starting Date",
      "endingDate":"Ending Date",
      "lastPay":"Last Pay",
      "shouldPayDate": "Should Pay Date",
      "lastSuscription":"Last Suscription",
      "leftDays":"Left Days",
      "customerId": "Customer Id",
      "categoriesList":"Categories List",
      "category":"Category" ,
      "categoryRequired":"Category required" ,
      "totalRequired":"Total required",
      "description":"Description",
      "descriptionRequired":"Description required",
      "validUntil":"Valid Until",
      "completed": "Completed",
      "addFingerPrints":"Add finger prints",
      "removeFingerPrints":"Remove finger prints",
      "openDoor": "Open Door",
      "lookForUsers": "Look for users",
      "lookRecords":"Look for records",
      "refresh": "Refresh",
      "tasksList": "Tasks List",
      "reschedule":"Reschedule",
      "itemsList": "Items List",
      "teamItemsList": "Team items list",
      "removed":"Removed",
      "billsList": "Bills List",
      "fromDate": "From Date",
      "toDate": "To Date",
      "date": "Date",
      "itemsAndSuscriptions": "Items/Suscriptions",
      "quantity": "Quantity",
      "previous":"Previous",
      "next": "Next",
      "new": "New",
      "bill":"Bill",
      "diaryClose": "Diary close",
      "bills":"Bills",
      "suscription": "Suscription",
      "selectACustomer": "Select a Customer",
      "pleaseSelectACustomer": "Please select a Customer",
      "days":"Days",
      "price":"Price",
      "priceVat": "Price with Vat",
      "vat": "Vat",
      "vatRequired": "Vat required",
      "total": "Total",
      "discount": "Discount",
      "subTotal": "Sub Total",
      "addItem": "Add Item",
      "update":"Update",
      "check": "Check",
      "canceled": "Canceled",
      "attenLog": "Atten Log",
      "hour": "Hour",
      "customersList": "Customers List",
      "print":"Print",
      "download":"Download",
      "addLine":"Add Line",
      "duration":"Duration",
      "durationRequired": "Duration is required",
      "minMembers":"Min Members",
      "minMembersRequired": "Min Members is required",
      "priceWithTaxes": "Price with taxes",
      "priceWithTaxesRequired": "Price with taxes is required",
      "barCode":"Barcode",
      "barCodeRequired":"Barcode is required",
      "durationType":"Duration Type",
      "durationTypeRequired":"Duration Type is required",
      "itemType":"Item Type",
      "itemTypeRequired":"Item Type is required",
      "item": "Item",
      "suscriptionsList":"Suscriptions List",
      "comprobant": "Comprobant",
      "cash": "Cash",
      "cashAmount":"Cash amount",
      "amountCreditCard":"CreditCard amount",
      "amountCreditCardRequired":"CreditCard amount is required",
      "amountTransference": "Amount transference",
      "amountTransferenceRequired": "Amount transference is required",
      "creditCard":"CreditCard",
      "lastCreditCardDigit": "Last four digits",
      "selectABank":"Select a Bank",
      "transference": "Transference",
      "bank": "Bank",
      "reference": "Reference",
      "referenceRequired": "Reference is required",
      "amount": "Amount",
      "editSuscription":"Edit Suscription",
      "lastForDigit": "Last 4 digits",
      "lastForDigitRequired": "Last 4 digits are required",
      "outstandingBalance": "Outstanding balance",
      "excess": "Excess",
      "teamList": "Team List",
      "team": "Team",
      "members": "Team Members",
      "addCustomer": "Add customer",
      "customerHasAlreadyBeenAdded":"Customer has already been added",
      "comment": "Comment",
      "commentOptional": "Any comment aditional?",
      "teamSuscriptions":"Team Suscriptions",
      "paymentMethod": "Payment Method",
      "createdByName": "Created by"
    }
  },
  es: {
    translation: {
      "hello": "Hola",
      "diarySales": "Venta Díaria",
      "usersList": "Listado de Usuarios",
      "appUsersList": "Listado de Usuarios La APP!",
      "addNew": "Agregar nuevo",
      "noAccessToActionRequest": "No tiene acceso a esta acción, por favor solicitar",
      "edit": "Editar",
      "add":"Agregar",
      "noResultFound": "No se encontraron resultados",
      "id": "No",
      "name": "Nombre",
      "nameRequired": "Ingrese el Nombre",
      "userName": "Usuario",
      "userNameRequired": "Ingrese un usuario",
      "password": "Contraseña",
      "passwordRequired": "Ingrese una contraseña",
      "email": "Correo",
      "emailRequired": "Ingres un correo",
      "role": "Rol",
      "roleRequired": "Seleccione un rol",
      "office":"Sucursal",
      "officeRequired":"Ingrese una sucursal",
      "documentSize": "Tamaño de Documentos",
      "documentSizeRequired": "Ingrese un Tamaño de Documentos",
      "language": "Idioma",
      "languageRequired": "El idioma es requerido",
      "action": "Acción",
      "pos": "Listín",
      "letter":"Carta",
      "english":"Ingles",
      "spanish":"Español",
      "active": "Activo",
      "inactive": "Inactivo",
      "rolesList": "Listado de Roles",
      "status": "Estado",
      "createdDate": "Fecha Creación",
      "search": "Buscar",
      "employee": "Empleado",
      "photo": "Foto",
      "dni": "Dni",
      "dniRequired": "Ingrese el Dni",
      "rtn": "Rtn",
      "phone":"Teléfono",
      "phoneRequired":"Teléfono es requerido",
      "actionNotAvailableOnTheWebPleaseInstallTheDesktopApp":"Acción no disponible mediante la web, por favor instale el programa de escritorio",
      "doYouWantToSyncThisFingerPrintManually": "¿Desea sincronizar esta huella manualmente?",
      "ifThisCustomerHaventPaidTheFingerWillRemoveAutomatically":"Si el cliente no ha pagado la huella sera borrada automaticamente",
      "yes": "Sí",
      "no":"No",
      "doYouWantToRemoveThisFingerPrintManually":"¿Desea eliminar esta huella manualmente?",
      "doYouWantToRemovePermanently": "¿Desea eliminar esta huella de forma permanente? esto no se puede deshacer",
      "willRemoveTheFingerPrintFromTheClockAndYouWillNeedToAddedAgain":"Se borrara la huella del reloj y tendra que agregar la huella nuevamente",
      "doYouWantToMarkThisCustomerAsEmployee":"¿Desea asignar a este cliente como empleado?",
      "thisActionWillPreventTheSystemRemoveTheFingerPrintAutomatically": "Esto hará que la huella no se elimine automáticamente",
      "thisActionWillConvertThisEmployeeToCustomer": "Esto lo asignara como un cliente",
      "doYouWantToRemoveThisEmployee":"¿Desea eliminar a este empleado?",
      "save": "Guardar",
      "customer": "Cliente",
      "birthDay":"Fecha nacimiento",
      "assignedFingerPrint": "Huella asignada",
      "sync":"Sincronizar",
      "remove": "Quitar",
      "removeFingerPrint": "Eliminar Huella",
      "enrollFingerPrint": "Tomar Huella",
      "markAsEmployee": "Marcar como Empleado",
      "convertToCustomer": "Convertir a cliente",
      "conectingToTheFingerReader": "Conectando al lector...",
      "pleasePutTheCustomerFinger": "Coloque el dedo del cliente",
      "pleasePutTheCustomerFingerAgain": "Coloque el dedo nuevamente",
      "pleasePutTheCustomerFingerLastTime": "Coloque el dedo una última vez",
      "fingerPritTakedSuccess":"Huella registrada correctamente",
      "saving": "Guardando",
      "camera": "Camara",
      "takePhoto": "Tomar Foto",
      "fileSizeIsToBigMax5Mb":'El archivo es demasiado grande maxímo 5mb',
      "gallery": "Galería",
      "done":"Confirmar",
      "linesPerPage": "Líneas por Página",
      "notApplicable":"No Aplica",
      "startingDate":"Fecha Inicio",
      "endingDate":"Fecha Finaliza",
      "lastPay":"Fecha Pago",
      "shouldPayDate":"Fecha debía pagar",
      "lastSuscription":"Ult. Suscripción",
      "leftDays":"Días restantes",
      "customerId": "No Cliente",
      "categoriesList":"Lista de Categorías",
      "category":"Categoría" ,
      "categoryRequired":"Ingrese una categoría" ,
      "totalRequired":"Ingrese el total",
      "description":"Descripción",
      "descriptionRequired":"Descripción requerida",
      "validUntil":"Valida Hasta",
      "completed": "Completado",
      "addFingerPrints":"Agregar huellas",
      "removeFingerPrints":"Remover huellas",
      "openDoor": "Abrir puerta",
      "lookForUsers": "Buscar Usuarios",
      "lookRecords":"Buscar Registros",
      "refresh": "Actualizar",
      "tasksList": "Listado de tareas",
      "reschedule":"Reprogramar",
      "itemsList": "Listado de Productos",
      "teamItemsList": "Listado de Productos para equipos",
      "removed":"Removido",
      "billsList": "Listado de Gastos",
      "fromDate": "Desde",
      "toDate": "Hasta",
      "date":"Fecha",
      "itemsAndSuscriptions":"Artículos/Suscripciones",
      "quantity": "Cantidad",
      "previous": "Anterior",
      "next": "Siguiente",
      "new":"Nuevo",
      "bill":"Gastos",
      "diaryClose": "Cierre Díario",
      "bills": "Gastos",
      "suscription": "Suscripción",
      "pleaseSelectACustomer": "Por favor seleccione un cliente",
      "selectACustomer": "Buscar Cliente",
      "days": "Días",
      "price":"Precio",
      "priceVat": "Precio con ISV",
      "vat": "Impuesto",
      "vatRequired":"Ingrese un impuesto",
      "total": "Total",
      "discount": "Descuento",
      "subTotal": "Sub Total",
      "addItem": "Agregar artículo",
      "update":"Modificar",
      "check": "Revisar",
      "canceled":"Anulado",
      "attenLog": "Listado de Asistencias",
      "hour": "Hora",
      "customersList": "Listado de clientes",
      "print":"Imprimir",
      "download":"Descargar",
      "addLine":"Agregar Línea",
      "duration":"Duración",
      "durationRequired":"Ingrese la duración",
      "minMembers":"Mínimo para equipo",
      "minMembersRequired": "Mínimo para equipo es requerido",
      "priceWithTaxes":"Precio Final",
      "priceWithTaxesRequired":"Precio Final es requerido",
      "barCode":"Código de Barra",
      "barCodeRequired":"Ingrese un Código de Barra",
      "durationType":"Tipo de Duración",
      "durationTypeRequired":"Ingrese el tipo de duración",
      "itemType":"Tipo de Producto",
      "itemTypeRequired":"Ingrese el tipo de producto",
      "item":"Producto",
      "suscriptionsList":"Listado de Suscripciones",
      "comprobant": "Comprobante",
      "cash": "Efectivo",
      "cashAmount":"Monto en Efectivo",
      "amountCreditCard":"Monto en Tarjeta de Crédito",
      "amountCreditCardRequired":"Ingrese un Monto",
      "amountTransference":"Ingrese un Monto",
      "amountTransferenceRequired":"Ingrese un Monto",
      "creditCard":"Tarjeta de Crédito",
      "lastCreditCardDigit": "Últimos dígitos",
      "selectABank":"Seleccione un banco",
      "transference": "Transferencia",
      "bank": "Banco",
      "reference": "Referencia",
      "referenceRequired": "Ingrese la referencia",
      "amount": "Monto",
      "editSuscription":"Editar Suscripción",
      "lastForDigit": "Últimos 4 dígitos",
      "lastForDigitRequired": "Últimos 4 dígitos requeridos",
      "outstandingBalance": "Saldo Pendiente",
      "excess": "Sobrante",
      "teamList": "Listado de Equipos",
      "team": "Equipo",
      "members": "Miembros del equipo",
      "addCustomer": "Agregar cliente",
      "customerHasAlreadyBeenAdded":"Cliente ya ha sido agregado previamente",
      "comment": "Comentario",
      "commentOptional": "¿Algún comentario adicional?",
      "teamSuscriptions": "Suscripciones de equipo",
      "paymentMethod": "Método de Pago",
      "createdByName": "Creado por"
    }
  } 
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;