import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import 'react-pivottable/pivottable.css';
import DialogPrintPdf from '../print-pdf/components/dialog.print-pdf';
import { suscriptionActions } from '../suscriptions/actions/suscription.actions';
import { DataDialogProps } from '../../models/data.dialog.props';
import { getDefaultDate } from '../../helpers/format';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';
import { months, years } from '../../helpers/consts';
import { SkeletonComponent } from '../../components/SkeletonComponet';
import { reportActions } from './actions/report.actions';

function MonthClosePage({t}: WithTranslation) {
    const [submit, setSubmit] = React.useState<boolean>(false);   
    const loading: boolean= useSelector((state:any) => state.reports.loading);

    const [month, setMonth] = React.useState<number>(new Date().getMonth()+ 1);
    const [year, setYear] = React.useState<number>(new Date().getFullYear());

    const dispatch = useDispatch();

    const handSearch = () => {
        setSubmit(true)
        dispatch(reportActions.printMonthClose(month, year));
        // dispatch(reportActions.searchDiarySales(fromDate?.toISOString() ?? "", toDate?.toISOString()??""));
    }


    function handleMonth(e:any) {
        const { name, value } = e.target;
        setMonth(value);

   }
   function handleYear(e:any) {
        const { name, value } = e.target;
        setYear(value);
   }
 
    return (
        <div className="card">
            <div className="p-fluid grid">
            <div className="field col-2">  
                    <span className="p-float-label">
                        <Dropdown 
                            inputId="dropdownmonth" 
                            name="month" value={month} 
                            options={months} onChange={handleMonth} 
                            optionLabel="name" 
                            optionValue="month"
                            disabled={loading} />
                        <label htmlFor="dropdownmonth">Mes</label>
                        {(submit&& !month) ?<small id="month-help" className="block p-error">Seleccione el mes</small>: <></> }
                    </span>
                </div>
                <div className="field col-2">
                    <span className="p-float-label">
                        <Dropdown 
                        inputId="dropdownYear" 
                        name="year" value={year} 
                        options={years} 
                        onChange={handleYear} 
                        optionLabel="name" 
                        optionValue="name"
                        disabled={loading} />
                        
                        <label htmlFor="dropdownYear">Año</label>
                        {(submit&& !month) ?<small id="year-help" className="block p-error">Seleccione el año</small>: <></> }
                    </span>
                </div>
               
               
                <div className=" col-2 ">
                    <Button label={t("search")} onClick={handSearch} icon="pi pi-search" iconPos="left" loading={loading} />
                </div>
                 {loading ? <SkeletonComponent/> :

                        <></>
                }
                <DialogPrintPdf expand onClose={()=>{}}/>
            </div>
        </div>
    );
}

    
export default withTranslation()(MonthClosePage);

