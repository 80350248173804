import { listSuscriptionConstants } from "../constants/list-suscription.constants";
import { ListSuscriptionState } from "../models/list-suscription.state";

const initialState: ListSuscriptionState = { 
    loading: false, 
    error: "",
    suscriptions: [],
};

export function listSuscription(state:ListSuscriptionState = initialState, action:any) {
    switch (action.type) {

        
        case listSuscriptionConstants.GET_SUSCRIPTION_BY_DATE_REQUEST:
            return {
                ...state,
                loading: true,
                suscriptions: []
            };
        case listSuscriptionConstants.GET_SUSCRIPTION_BY_DATE_SUCCESS:
            return {
                ...state,
                loading: false,
                suscriptions: action.suscriptions
            };
        case listSuscriptionConstants.GET_SUSCRIPTION_BY_DATE_FAILURE:
            return {
                ...state,
                loading: false,
            };


        default:
            return state
    }
}