import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useEffect } from 'react';
import { Button } from 'primereact/button';
import { Offer } from './models/offer';
import { offerActions } from './actions/offer.actions';
import { SkeletonComponent } from '../../components/SkeletonComponet';
import { Badge } from 'primereact/badge';
import DialogOffer from './components/dialog.offer';
import { TemplatePaginator } from '../../components/TemplatePaginator';
import { WithTranslation, withTranslation } from 'react-i18next';


function OfferListPage({t}: WithTranslation) {
   const offers: Offer[] = useSelector((state:any) => state.offers.offers as Offer[]);
   const loading: boolean= useSelector((state:any) => state.offers.loading);
   const user = useSelector((state: any) => state.authentication.user);
    const dispatch = useDispatch();


    useEffect(() => { 
       dispatch(offerActions.get()); 
    },[]);

    const onCloseOffer = () => { 
        dispatch(offerActions.get()); 
      };

    const addOffer = ()=>{    
        dispatch(offerActions.openModalOffer({
            open : true,
            select : new Offer(),
            type : 0,
            loading: false
        }));
    }

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                <h5 className="m-0">Listado de Ofertas</h5>
                <span className="p-input-icon-left">
                    <Button label={t("addNew")} onClick={addOffer} icon="pi pi-pencil" iconPos="left"  />
                    {
                        /* 
                            <i className="pi pi-search" />
                            <InputText placeholder="Buscador..." />
                         */
                    }
                </span>
            </div>
        )
    }
    const header = renderHeader();

    const actionBodyTemplate = (rowData:Offer) => {
            const showDialog =()=>{
                dispatch(offerActions.openModalOffer({
                    open : true,
                    select : rowData,
                    type : 1,
                    loading: false
            }));
        }
        return  <span className="p-buttonset">
                    <Button label={t("edit")} icon="pi pi-pencil" iconPos="left" onClick={showDialog} />
                </span>
    }

    const statusBodyTemplate = (rowData:Offer) => {
        return <Badge value={rowData.active? "Activo" : "Inactivo"} severity={rowData.active ? undefined: "danger"} ></Badge>
    }
    const dateBodyTemplate = (rowData:Offer) => {
        let date = new Date(rowData.createdDate!);
        return <span>{date?.toLocaleDateString(user.lang == "es"? "es-HN": "en-US")}</span>
    }
    return (
        <div className="card">
            {loading ? <SkeletonComponent/> :
              <DataTable  emptyMessage={t("noResultFound")} value={offers} paginatorTemplate={TemplatePaginator as any} 
                    responsiveLayout="scroll"  rows={10}  size="small" stripedRows header={header} >
                    <Column filter sortable field="offerId" header="No"></Column>
                    <Column filter sortable field="description" header="Descripción"></Column>
                    <Column filter sortable field="createdDate" header="Fecha Creación" body={dateBodyTemplate}></Column>
                    {/* <Column field="createdByName" header="Creado por" ></Column> */}
                    <Column filter sortable field="active" body={statusBodyTemplate} header="Estado"></Column>
                    <Column header="Acción" body={actionBodyTemplate} />
                </DataTable>
            }
            {/* <DialogOffer onClose={onCloseOffer} ></DialogOffer> */}
        </div>
     
    );
}
    
export default withTranslation()(OfferListPage);

