import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import App from './app/App';
import ScrollToTop from './components/ScrollToTop';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
// import 'webdatarocks/webdatarocks.css'
import './i18n';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
//   <React.StrictMode>
    <Provider store={store}>
        <HashRouter>
             <ScrollToTop>
                <App />
            </ScrollToTop>
        </HashRouter>
    </Provider>
//   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
