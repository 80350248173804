import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataDialogProps } from '../../../../models/data.dialog.props';
import { DialogProps } from '../../../../models/dialog.props';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { roleActions } from '../actions/role.actions';
import { Role } from '../models/role';
import { SkeletonComponent } from '../../../../components/SkeletonComponet';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Tree } from 'primereact/tree';
import { User } from '../../users/models/user';
import { withTranslation } from 'react-i18next';


  function DialogRole(props: DialogProps<Role>) {
    const { t } = props;
    const dispatch = useDispatch();    
    const openRole  = useSelector((state: any) => state.roles.openRole as DataDialogProps<Role>);
    const [inputs, setInputs] = React.useState<Role>(openRole?.select);
    const [submitted, setSubmitted] = React.useState(false);
    const  user  = useSelector((state: any) => state.authentication.user as User);

     useEffect(() => {
         setInputs(openRole?.select)
         setSubmitted(false)
    }, [openRole]);

    const handleClose = () => {
      setInputs(new Role())
      dispatch(roleActions.openModalRole({
        open : false,
        select : new Role(),
        type : 0,
        loading: false
      }));
      setSubmitted(false)
    };
  
    function handleChangeCheck(e:any)  {
      const { name, value } = e.target;
      setInputs(inputs => ({ ...inputs, [name]: e.target.checked  }));
    };
    
    function handleChangeCheckItem(node: any, e:any)  {
       node.active = e.checked;
        setInputs(inputs => ({ ...inputs}));
    };
    
    function handleSubmit(e:any) {
      e.preventDefault();
      setSubmitted(true);
      if (inputs.name) {
            inputs.createdBy = user.userId??0;
          if(openRole?.type ===0){
            dispatch(roleActions.add(inputs));
          }
          if(openRole?.type ===1){
            dispatch(roleActions.edit(inputs));
          }
      }
    }

  function handleChange(e:any) {
      const { name, value } = e.target;
      setInputs(inputs => ({ ...inputs, [name]: value }));
  }
   const footer = (
        <div>
            <Button disabled={openRole.loading} onClick={handleSubmit} label={openRole?.type ===0 ? t("add"): t("edit")} icon="pi pi-check"/>
        </div>
    );

    const nodeTemplate = (node:any, options:any) => {
        let label = <span>{node.label}</span>;
        return (
            <span className={options.className}>
                {label} <Checkbox onChange={e => handleChangeCheckItem(node, e)} checked={node.active}></Checkbox> 
            </span>
        )
    }

    return (
        <Dialog header={openRole?.type ===0 ?  t("add"): t("edit")} footer={footer} visible={openRole?.open??false} position="top" onHide={handleClose} breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '40vw'}}>
            <div>
                <div className="p-fluid grid mt-3"> 
                     {openRole.loading ? <SkeletonComponent/> : 
                        <React.Fragment>
                            <div className="field col-12 md:col-12">
                                <span className="p-float-label p-input-icon-left">
                                    <i className="pi pi-id-card" />
                                    <InputText id="name" name="name" value={inputs?.name || ""} onChange={handleChange} />
                                    <label htmlFor="name">{t("name")}</label>
                                </span>
                            </div>
                            <div className="col-12 md:col-12">  
                                <Checkbox inputId="cbActive" onChange={handleChangeCheck} name="active" checked={inputs?.active??false}></Checkbox>
                                <label htmlFor="cbActive" className="p-checkbox-label">{t("active")}</label>
                            </div>
                             <div className="col-12 md:col-12">  
                                <Tree  value={openRole.select?.detail??[]} nodeTemplate={nodeTemplate}  />
                            </div>
                        </React.Fragment>
                     }                   
                </div>
            </div>
        </Dialog>
    );
  }

  
export default withTranslation()(DialogRole);