import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {  useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TemplatePaginator } from '../../components/TemplatePaginator';
import { Button } from 'primereact/button';
import { User } from '../settings/users/models/user';
import { Calendar } from 'primereact/calendar';
import { format, getDefaultDate } from '../../helpers/format';
import { listSuscriptionActions } from './actions/list-suscription.actions';
import { Suscription } from '../suscriptions/models/suscription';
import { ImageSrc } from '../../components/image-src';
import { WithTranslation, withTranslation } from 'react-i18next';
import Logo from'../../assets/images/logo.png';

function ListSuscriptionsPage({t}:WithTranslation ) {
    const suscriptions: Suscription[]= useSelector((state:any) => state.listSuscription.suscriptions);
    const loading: boolean= useSelector((state:any) => state.listSuscription.loading);
    const dispatch = useDispatch();
    const [fromDate, setFromDate] = useState<Date>(getDefaultDate());
    const [toDate, setToDate] = useState<Date>(getDefaultDate());
 

    useEffect(()=>{
        handSearch()
    },[])
    const handSearch = ()=>{
        dispatch(listSuscriptionActions.getByDate(fromDate?.toISOString() ?? "", toDate?.toISOString()?? "")); 
    }
    function handleFromDate(e:any) {
        const { value } = e.target;
        setFromDate(value);
   }

   function handleToDate(e:any) {
        const {value } = e.target;
        setToDate(value);
   }
   

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                <div>
                    <h5 className="m-0">{t("suscriptionsList")}</h5>  
                    <div>
                    <div className="p-fluid grid">
                        
                            <div className="field col-4">  
                                <label htmlFor="fromDate" className="block">{t("fromDate")}</label>
                                <Calendar id="fromDate" value={fromDate!= undefined ?  new Date(fromDate??"") :undefined}  onChange={handleFromDate}></Calendar>
                                {/* {(submit&& !fromDate) ?<small id="name-help" className="block p-error">Seleccione la fecha Inicial</small>: <></> }            */}
                            </div>
                            <div className="field col-4">
                                <label htmlFor="toDate" className="block">{t("toDate")}</label>
                                <Calendar id="toDate" value={toDate!= undefined ?  new Date(toDate??"") :undefined} onChange={handleToDate}></Calendar>
                                {/* {(submit&& !toDate) ?<small id="name-help" className="block p-error">Seleccione la fecha final</small>: <></> } */}
                            </div>
                            <div className="field col-4 mt-5">
                                  <Button label={t("search")} onClick={handSearch} icon="pi pi-search" iconPos="left"  />
        
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
    const header = renderHeader();

    const createBodyTemplate = (row:Suscription) => {
        if(row.createdDate == null) return <span>{t("notApplicable")}</span>
        let date = new Date(row.createdDate!);
        return <span>{date?.toLocaleDateString("es-US")}</span>
    }
    const totalBodyTemplate = (row:Suscription) => {
        return <span>L. {format(row.total??0,2)}</span>
    }
    const amountCashBodyTemplate = (row:Suscription) => {
        return <span>L. {format(row.amountCash??0,2)}</span>
    }
    const amountCreditBodyTemplate = (row:Suscription) => {
        return row.bankId ? 
                <div>
                <span><strong>{t("amount")}</strong> L. {format(row.amountCredit??0,2)}</span><br/> 
                <span><strong>{t("bank")}</strong> {row.bankId} </span>
                <span><strong>{t("reference")}</strong>: {row.referenceCreditCard}</span><br/> 
                <span><strong>{t("lastCreditCardDigit")}</strong>: {row.creditCard}</span>
                </div>
            : <span>{t("notApplicable")}</span>
    }
 
    const amountTransferenceBodyTemplate = (row:Suscription) => {
        return row.bankTransferenceId ? 
                <div>
                <span><strong>{t("amount")}</strong> L. {format(row.amountTransference??0,2)}</span><br/> 
                <span><strong>{t("bank")}</strong> {row.bankTransferenceId} </span>
                <span><strong>{t("reference")}</strong>: {row.referenceTransference}</span>
                </div>
            : <span>{t("notApplicable")}</span>
    }

      const actionBodyTemplate = (suscription:Suscription) => {
  
        return <>{
            (suscription.transferenceComprobant) ?
               <ImageSrc  height='40px' src={suscription.transferenceComprobant} />
            : <div></div>
            }
            </>
    }

    const photoBodyTemplate = (suscription: Suscription)=>{
        return  (suscription.photoUri) ?
            <ImageSrc height="40px" width="45px" src={suscription.photoUri} />
            :<img height="40px" width="40px"src={ Logo || ""} alt="" />
        }

    return (

            <div className="grid  mt-1">
                    <div className=" col-12 card">
                    {header}
                    <DataTable  emptyMessage={t("noResultFound")} paginatorTemplate={TemplatePaginator as any} 
                        loading={loading} paginator rows={10}  disabled={loading}
                        value={suscriptions??[]} responsiveLayout="scroll"
                        size="small" stripedRows  >

                        <Column   field="photoId" header={t("photo")} body={photoBodyTemplate}></Column>
                        <Column filter filterType="contains" field="suscriptionId" header={t("id")}></Column>
                        {/* <Column filter filterType="contains" field="description" header="Descriptión"></Column> */}
                        <Column sortable  field="customer" header={t("customer")} ></Column>
                        <Column sortable  field="createDate" header={t("date")} body={createBodyTemplate}></Column>
                        <Column  field="amountCash" header={t("cash")} body={amountCashBodyTemplate}></Column>
                        <Column  sortable field="bankId" header={t("creditCard")} body={amountCreditBodyTemplate}></Column>
                        <Column  sortable field="bankTransferenceId" header={t("transference")} body={amountTransferenceBodyTemplate}></Column>
                        <Column  field="comment" header={t("comment")}></Column>
                        {/* <Column  field="bankTransferenceId" header={t("bank")} body={bankTransferenceIdBodyTemplate}></Column> */}
                        <Column filter filterType="contains" field="total" header={t("total")} body={totalBodyTemplate}></Column>
                        <Column header={t("comprobant")} body={actionBodyTemplate} /> 
                    </DataTable>
                </div>

            </div>
   
       
    );
}


export default withTranslation()(ListSuscriptionsPage);