import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { DiarySales } from './models/diary_sales';
import { Calendar } from 'primereact/calendar';
import { reportActions } from './actions/report.actions';
import TableRenderers from 'react-pivottable/TableRenderers';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import { SkeletonComponent } from '../../components/SkeletonComponet';
import 'react-pivottable/pivottable.css';
import { getDefaultDate } from '../../helpers/format';
import { withTranslation, WithTranslation } from 'react-i18next';
import DialogPrintPdf from '../print-pdf/components/dialog.print-pdf';

function BillsResumen({t}: WithTranslation) {
    const loading: boolean= useSelector((state:any) => state.reports.loading);
    const [fromDate, setFromDate] = useState<Date>(getDefaultDate());
    const [toDate, setToDate] = useState<Date>(getDefaultDate());
    const [submit, setSubmit] = useState<boolean>(false);
    const [pivotState, setPivotState] = useState();
    const dispatch = useDispatch();


    const handSearch = () => {
        setSubmit(true)
        dispatch(reportActions.searchBillsResumen(fromDate?.toISOString() ?? "", toDate?.toISOString()??""));
    }

   function handleFromDate(e:any) {
        const { value } = e.target;
        setFromDate(value);
   }

   function handleToDate(e:any) {
        const {value } = e.target;
        setToDate(value);
   }
  
    return (
        <div className="card">
            <div className="p-fluid grid">
                <div className="field col-2">  
                    <label htmlFor="fromDate" className="block">{t("fromDate")}</label>
                    <Calendar id="fromDate" value={fromDate!== undefined ?  new Date(fromDate??"") :undefined}  onChange={handleFromDate}></Calendar>
                    {(submit&& !fromDate) ?<small id="name-help" className="block p-error">Seleccione la fecha Inicial</small>: <></> }           
                </div>
                <div className="field col-2">
                    <label htmlFor="toDate" className="block">{t("toDate")}</label>
                    <Calendar id="toDate" value={toDate!== undefined ?  new Date(toDate??"") :undefined} onChange={handleToDate}></Calendar>
                    {(submit&& !toDate) ?<small id="name-help" className="block p-error">Seleccione la fecha final</small>: <></> }
                </div>

                <div className=" col-2 mt-5">
                    <Button label={t("search")} onClick={handSearch}  icon="pi pi-search" iconPos="left" data-testid="btn-search" />
                </div> 
                 {loading ? <SkeletonComponent/> :
                <div className="col-12">
                    {loading ? <SkeletonComponent/> :

                    <></>
                    }
                </div>
                }
                <DialogPrintPdf expand onClose={()=>{}}/>
            </div>
        </div>
    );
}

export default withTranslation()(BillsResumen);
