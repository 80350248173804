import axios from 'axios';
import environment from '../../environment';
import { Suscription } from './models/suscription';
import { SuscriptionHistorical } from './models/suscription-historical';
import { MarkCustomerLatePay } from './models/markCustomerLatePay';

export const suscriptionService = {
    save,
    addLine,
    removeLine,
    editLineQuantity,
    searchByDescription,
    next,
    preview,
    getActive,
    getCustomerLatePay,
    selectCustomer,
    printComprobant,
    printInvoice,
    updateHistorical,
    printDiaryClose,
    getByDate,
    markCustomerLatePay
};


 function save(suscription:Suscription) {
    return axios.post<Suscription>(`${environment.apiUri}/suscription`, suscription)
  };
 function updateHistorical(suscription:SuscriptionHistorical) {
    return axios.put<SuscriptionHistorical[]>(`${environment.apiUri}/suscription/UpdateHistorical`, suscription)
  };
 function addLine(suscription:Suscription ,itemId: string) {
    return axios.post(`${environment.apiUri}/suscription/addLine/${itemId}`, suscription)
  };
 function removeLine(suscription:Suscription ,itemId: string) {
    return axios.post(`${environment.apiUri}/suscription/removeLine/${itemId}`, suscription)
  };
 function editLineQuantity(suscription:Suscription ,itemId: string, quantity:number) {
    return axios.post(`${environment.apiUri}/suscription/EditLineQuantity?itemId=${itemId}&&quantity=${quantity}`, suscription)
  };

function next(suscriptionId:string) {
    return axios.get(`${environment.apiUri}/suscription/next?SuscriptionId=${suscriptionId}`)
  };

function preview(suscriptionId:string) {
    return axios.get(`${environment.apiUri}/suscription/preview?SuscriptionId=${suscriptionId}`)
  };
function printComprobant(suscriptionId:string) {
    return axios.get(`${environment.apiUri}/suscription/PrintComprobant?SuscriptionId=${suscriptionId}`)
  };

function printDiaryClose(date:string) {
    return axios.get(`${environment.apiUri}/report/CloseDiary?date=${date}`)
  };
function printInvoice(suscriptionId:string) {
    return axios.get(`${environment.apiUri}/suscription/PrintInvoice?SuscriptionId=${suscriptionId}`)
  };

function getActive() {
    return axios.get(`${environment.apiUri}/suscription/activeSuscriptions`)
  };
function getCustomerLatePay(fromDate:string, toDate:string) {
    return axios.get(`${environment.apiUri}/suscription/GetCustomerLatePay?fromDate=${fromDate}&toDate=${toDate}`)
  };
function markCustomerLatePay(params:MarkCustomerLatePay) {
    return axios.post(`${environment.apiUri}/suscription/markCustomerLatePay`,params)
  };
function getByDate(fromDate:string, toDate:string) {
    return axios.get(`${environment.apiUri}/suscription/getByDate?fromDate=${fromDate}&toDate=${toDate}`)
  };
function searchByDescription(description:string) {
    return axios.get(`${environment.apiUri}/suscription/SearchByDescription?Description=${description}`)
  };

function selectCustomer(customerId:string) {
    return axios.get(`${environment.apiUri}/suscription/SelectCustomer/${customerId}`)
  };
