import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { suscriptionActions } from './actions/suscription.actions';
import { DebounceInput } from 'react-debounce-input';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SuscriptionHistorical } from './models/suscription-historical';
import { ImageSrc } from '../../components/image-src';
import { TemplatePaginator } from '../../components/TemplatePaginator';
 import Logo from'../../assets/images/logo.png';
import { Button } from 'primereact/button';
import { TypeDialog } from '../../helpers/type_dialog';
import DialogUpdateHistorical from './components/dialog.update-historical';
import { User } from '../settings/users/models/user';
import { withTranslation, WithTranslation } from 'react-i18next';

function ActiveSuscriptionPage({t}: WithTranslation) {
    const activeSuscriptions: SuscriptionHistorical[]= useSelector((state:any) => state.suscription.activeSuscriptions);
    const loading: boolean= useSelector((state:any) => state.suscription.loading);
    const dispatch = useDispatch();
    const user:User  = useSelector((state: any) => state.authentication.user as User);

 
    useEffect(() => { 
       dispatch(suscriptionActions.getActive()); 
    },[]);

    const renderHeader = () => {
    return (
        <div className="flex justify-content-between align-items-center">
                <span className="p-input-icon-left" >
                { loading ? <i className="pi pi-spin pi-spinner" />: <i className="pi pi-search" /> }
                    <DebounceInput
                    placeholder={t("search")}
                    minLength={1}
                    style={{width:"100%"}}
                    onChange={event => {
                        if((event.target?.value??"").length > 1){
                            dispatch(suscriptionActions.searchByDescription(event.target.value));
                        }
                    }} 
                    value=""
                    debounceTimeout={400}
                    element={InputText as any} />
            </span>
              
        </div>
    )
}
    
    const header = renderHeader();

    const showUpdateBtn =  () => {
        
        var permission = user.permissions.find(x=> x.path == "btn_update_suscription");
        return permission;
    }
    const photoBodyTemplate = (suscription: SuscriptionHistorical)=>{
        return  (suscription.photoUri) ?
            <ImageSrc height="40px" width="45px" src={suscription.photoUri} />
            :<img height="40px" width="40px"src={ Logo || ""} alt="" />
        }

         
    const startingDateBodyTemplate = (row:SuscriptionHistorical) => {
        if(row.startingDate == null) return <span>{t("notApplicable")}</span>
        let date = new Date(row.startingDate!);
        return <span>{date?.toLocaleDateString("es-US")}</span>
    }
    const endingDateBodyTemplate = (row:SuscriptionHistorical) => {
        if(row.endingDate == null) return <span>{t("notApplicable")}</span>
        let date = new Date(row.endingDate!);
        return <span>{date?.toLocaleDateString("es-US")}</span>
    }
      const actionBodyTemplate = (historical:SuscriptionHistorical) => {
            const openInNewTab = () => {
                window.open(`https://wa.me/+504${historical.phone}`, '_blank', 'noreferrer');
              };
            const onUpdateSuscription =()=>{
                dispatch(suscriptionActions.openModalUpdateHistorical({
                    open : true,
                    select :historical,
                    type : TypeDialog.edit,
                    loading: false
                }));
            }
          return   <span className="p-buttonset">
                    { historical.phone? <Button  className="p-button-success p-button-outlined" icon="pi pi-whatsapp" iconPos="left" onClick={openInNewTab} /> : <></>}
                    {showUpdateBtn()? <Button className="p-button-danger p-button-outlined" icon="pi pi-pencil" iconPos="left" onClick={onUpdateSuscription} />
                    : <></>
                    }
                    
                    {/* <Button label="Ver Todas" icon="pi pi-arrow-right" className="p-button-outlined"  iconPos="right" onClick={showDialogCustomer} /> */}
                </span>
    }
     
      const statusBodyTemplate = (suscription:SuscriptionHistorical) => {
            if(suscription.daysLeft! <= 0){
                return <div className='suscription-circle red'></div>
            }
            if(suscription.daysLeft! <= 10){
                return <div className='suscription-circle yellow'></div>
            }
          return <div className='suscription-circle green'></div>
    }

    return (

            <div className="p-fluid grid  mt-1">
                    <div className=" col-12 card">
                    {header}
                    <DataTable  emptyMessage={t("noResultFound")} paginatorTemplate={TemplatePaginator as any} 
                        loading={loading} paginator rows={10}  disabled={loading}
                        value={activeSuscriptions??[]} responsiveLayout="scroll"
                        size="small" stripedRows  >
                        <Column filter  field="photoId" header={t("photo")} body={photoBodyTemplate}></Column>
                        <Column filter filterType="contains" field="customerId" header={t("customerId")}></Column>
                        <Column filter filterType="contains" field="customer" header={t("customer")}></Column>
                        <Column filter filterType="contains" field="phone" header={t("phone")}></Column>
                        <Column sortable  field="startingDate" header={t("startingDate")} body={startingDateBodyTemplate}></Column>
                        <Column sortable field="endingDate" header={t("endingDate")} body={endingDateBodyTemplate} ></Column>
                        <Column sortable  field="daysLeft" header={t("leftDays")}></Column>
                        <Column header={t("status")} body={statusBodyTemplate} />
                        <Column header={t("action")} body={actionBodyTemplate} />
                    </DataTable>
                </div>
                <DialogUpdateHistorical  ></DialogUpdateHistorical>
            </div>
 
       
    );
}


    
export default withTranslation()(ActiveSuscriptionPage);
