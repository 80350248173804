export const teamsConstants = {
    
    GET_TEAMS_REQUEST: 'GET_TEAMS_REQUEST',
    GET_TEAMS_SUCCESS: 'GET_TEAMS_SUCCESS',
    GET_TEAMS_FAILURE: 'GET_TEAMS_FAILURE',

    GET_TEAM_BY_ID_REQUEST: 'GET_TEAM_BY_ID_REQUEST',
    GET_TEAM_BY_ID_SUCCESS: 'GET_TEAM_BY_ID_SUCCESS',
    GET_TEAM_BY_ID_FAILURE: 'GET_TEAM_BY_ID_FAILURE',


    SAVE_REQUEST_TEAM: 'SAVE_REQUEST_TEAM',
    SAVE_SUCCESS_TEAM: 'SAVE_SUCCESS_TEAM',
    SAVE_FAILURE_TEAM: 'SAVE_FAILURE_TEAM',

    EDIT_TEAM_REQUEST: 'EDIT_TEAM_REQUEST',
    EDIT_TEAM_SUCCESS: 'EDIT_TEAM_SUCCESS',
    EDIT_TEAM_FAILURE: 'EDIT_TEAM_FAILURE',

    NEXT_REQUEST_TEAM: 'NEXT_REQUEST_TEAM',
    NEXT_SUCCESS_TEAM: 'NEXT_SUCCESS_TEAM',
    NEXT_FAILURE_TEAM: 'NEXT_FAILURE_TEAM',

    PREVIEW_REQUEST_TEAM: 'PREVIEW_REQUEST_TEAM',
    PREVIEW_SUCCESS_TEAM: 'PREVIEW_SUCCESS_TEAM',
    PREVIEW_FAILURE_TEAM: 'PREVIEW_FAILURE_TEAM',

    CLEAN_TEAM: 'CLEAN_TEAM',
};