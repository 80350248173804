import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { format, getDateFormat } from '../../helpers/format';
import { Bill } from './models/bill';
import { BillDetail } from './models/bill-detail';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { billsActions } from './actions/bills.actions';
import { Dropdown } from 'primereact/dropdown';
import { BillCategory } from './models/bill-category';
import { InputText } from 'primereact/inputtext';
import { User } from '../settings/users/models/user';
import { useNavigate, useParams } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';

function BillPage({t}: WithTranslation) {
    const categories: BillCategory[]= useSelector((state:any) => state.bills?.categories??[]);
    const loading: boolean= useSelector((state:any) => state.bills.loading);
    const bill: Bill= useSelector((state:any) => state.bills.bill);
    const [inputs, setInputs] = useState<Bill>(bill);
    const navigate = useNavigate();
    const { id } = useParams();
    const user  = useSelector((state: any) => state.authentication.user as User);


    const dispatch = useDispatch();


    useEffect(()=>{
        dispatch(billsActions.getCategories())
    },[])

    useEffect(() => {
        setInputs(bill)
    }, [bill]);

    useEffect(()=>{

        if(!canAddBill()) {
            navigate("/")
            return;
        }
        if(id){
            dispatch(billsActions.getById(id));
        }
        else{
            dispatch(billsActions.clearBill());
        }
    },[])

    const canAddBill = ()=>{
        var permission = user.permissions.find(x=> x.path == "btn_add_bill");
        return permission;
    }

    const canCancelBill = ()=>{
        var permission = user.permissions.find(x=> x.path == "btn_cancel_bill");
        return permission;
    }
    const handAdd = () => {
        let newInputs = {...inputs}
        newInputs.detail = [new BillDetail(),...inputs.detail??[]]
        newInputs.detail.forEach(((x,i)=>  {x.lineId = i+1 }))
        setInputs(newInputs)
    }

    const onSave = () => {
        inputs.createdBy = user.userId;
        inputs.createdByName = user.name;
        inputs.createdDate = new Date().toISOString();
        inputs.statusId = 1;
        inputs.total = 0;
        if(!inputs.description){
            return;
        }
        if((inputs.detail??[]).length == 0){
            return;
        }
        dispatch(billsActions.save(inputs));
    }
    
    const onClean = () => {
        dispatch(billsActions.clearBill());
        navigate("/bill")
    }
 
    const onPreview = () => {
        dispatch(billsActions.preview(inputs.billId??""));
    }
    const onCancelBill = () => {
        dispatch(billsActions.cancelBill(inputs.billId??""));
    }

    const onNext = () => {
        dispatch(billsActions.next(inputs.billId??""));
    }

    const onRemove = (item: BillDetail)=>{
        let newInputs = {...inputs}
        let finIndex = newInputs.detail?.findIndex(c=> c.lineId == item.lineId)
        if(finIndex != -1){
            newInputs.detail?.splice(finIndex!,1)
            newInputs.detail!.forEach(((x,i)=> { x.lineId = i+1}))
            setInputs(newInputs);
        }
    }

        const renderHeader = () => {
                return (
                    <div className="flex justify-content-between align-items-center">
                        <h5>{t("bills")}</h5>
                        <span className="p-input-icon-left mb-2">
                            <Button  style={{width:"200px"}} disabled={loading || cannotEdit()} label={t("addLine")} onClick={handAdd} icon="pi pi-plus" iconPos="left"  />
                        </span>
                    </div>
                )
            }
    const cannotEdit = () :boolean =>{

        var is = (inputs.billId??"").toString().length > 1;
        return is;
    }

    const header = renderHeader();
 
    const actionBodyTemplate = (item:BillDetail, option:any) => {    
         return <span className="p-buttonset">
            <Button className="p-button-danger" icon="pi pi-trash" disabled={cannotEdit()} iconPos="left" onClick={(event:any)=>{onRemove(item) }} />
        </span>
    }
 
    function handleChange(e:any) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }
    
    const totalBodyTemplate = (item:BillDetail, options:any) => {    
        return <span className={options.className} style={{marginLeft:"2px"}}>
                <InputNumber placeholder={t("total")}
                
                 name='total' mode="decimal" locale="en-US" minFractionDigits={2} maxFractionDigits={2}
                    value={item.total || 0}   onValueChange={(e)=>{
                        let newInputs = {...inputs};
                        (newInputs.detail??[])[options.rowIndex].total = e.value??0;
                        setInputs(newInputs);
                    }} 
                    disabled={loading || cannotEdit()}
                    className={(!item?.total ) ? "p-invalid ":""}/>
                    {(!item?.total) ?<small id="phone-help" className="block p-error">{t("totalRequired")}</small>: <></> }
        </span>
    }
    const descriptionBodyTemplate = (item:BillDetail, options:any) => {    

        let description = {...item}.description;
        return <span className={options.className}>
      
            <InputText id="description" value={description??""} onChange={(e:any) => {
                 let newInputs = {...inputs};
                 (newInputs.detail??[])[options.rowIndex].description = e.target.value as any;
                 setInputs(newInputs);
            }}
            className={(!item?.description ) ? "w-full  p-invalid ":"w-full"} 
            
            disabled={loading || cannotEdit()}
            />
            {(!item?.description) ?<small id="phone-help" className="block p-error">{t("descriptionRequired")}</small>: <></> }
        </span>
    }

    // const search = (event:any) => {
    //     setItems([...["aa","bb"]].map(item => event.query + '-' + item));
    // }

    const typeBodyTemplate = (item:BillDetail, options:any) => {    
        var cateorySelected = categories?.find(c=> c.id == item.categoryId);
        return <span>
         <Dropdown  value={cateorySelected} onChange={(e) => {
            if(!e.value)return;
            let newInputs = {...inputs};
            (newInputs.detail??[])[options.rowIndex].categoryId = e.value.id;
            setInputs(newInputs);
        }} options={categories} 
        optionLabel="name" 
        placeholder="Seleccione la categoría" 
            filter 
             className={(!item?.categoryId ) ? "w-full md:w-10rem p-invalid ":"w-full md:w-10rem"} 
             disabled={loading || cannotEdit()}
              />
            {(!item?.categoryId) ?<small id="phone-help" className="block p-error">{t("categoryRequired")}</small>: <></> }
            </span>

    }

    return (
        <div className="card">
          
                <div className="grid">
                        <div className="col-12"> 
                            <div className="flex justify-content-start">
                                <Button icon="pi pi-arrow-left" loading={loading} disabled={loading} onClick={onPreview} className="p-button-rounded p-button" aria-label="Anterior" />
                                <Button icon="pi pi-arrow-right" loading={loading} disabled={loading} onClick={onNext} className="p-button-rounded p-button" aria-label="Siguiente" />
                                {/* <Button icon="pi pi-print" disabled={!cannotEdit()} loading={loading} onClick={onPrintComprobant} className="p-button-rounded p-button-danger" /> */}
                                <Button icon="pi pi-file" loading={loading} label={t("new")} onClick={onClean} className="p-button-rounded " />
                                <Button icon="pi pi-save" loading={loading} disabled={cannotEdit() || (inputs?.detail??[]).length ===0 }  label={t("save")} onClick={onSave} className="p-button-rounded p-button-danger" />
                            </div>

                            <div className="flex justify-content-end"> 
                            {
                                    cannotEdit()? <Button icon="pi pi-times" disabled={loading ||!cannotEdit()|| inputs.statusId ==2|| !canCancelBill()} loading={loading }  onClick={onCancelBill} className="p-button-rounded  p-button-danger" label="Anular"  aria-label="Anterior" />
                                    :<div/>
                                }


                                     
                            </div>
                        </div>
                        <div className="col-12 mt-2">
                            <h4>{t("bill")} {inputs.billId}</h4>
                            <div className="grid">
                                <div className="col-12">
                                    <div className=" grid">
                               
                                        <div className="field col-12 p-fluid">
                                            <label htmlFor="description" className="block">{t("description")}</label>
                                            <InputTextarea  id="description" name="description" placeholder={t("descriptionRequired")} value={inputs?.description??""}
                                            disabled={loading || cannotEdit()}
                                            onChange={handleChange} 
                                            className={(!inputs?.description ) ? "w-full p-invalid block":"w-full block"} 
                                            />
                                            {(!inputs?.description) ?<small id="phone-help" className="block p-error">{t("descriptionRequired")}</small>: <></> }
                                        </div>


                                       {cannotEdit() ?
                                       <>
                                        <div className="field col-4 p-fluid">
                                                <label htmlFor="createDate" className="block">{t("date")}</label>
                                                <InputText id="createDate"disabled={true} value={getDateFormat(new Date(inputs?.createdDate!), user.lang)} readOnly={true} className="block"/>
                                            </div>
                                            <div className="field col-4 p-fluid">
                                                <label htmlFor="total" className="block">{t("total")}</label>
                                                <InputText id="createByNmae" disabled={true} value={`L. ${format(inputs.total??0,2)}`} readOnly={true} className="block"/>
                                            </div>
                                            <div className="field col-4 p-fluid">
                                                <label htmlFor="status" className="block">{t("status")}</label>
                                                <InputText id="status" disabled={true} value={(inputs?.statusId == 1? t("active"): t("canceled")).toString()} readOnly={true} className="block"/> 
                                            </div>
                                        </>:
                                        <></>
                                        }


                                        
                                    </div>                                   
                                </div>
                          


                                 <div className="col-12">
                                            {header}
                                            <DataTable emptyMessage={t("noResultFound")} loading={loading}
                                                    scrollable scrollHeight='300px' showGridlines  height={200}  disabled={loading}
                                                    value={inputs?.detail??[]} responsiveLayout="scroll"  
                                                    size="small" stripedRows  >
                                                    <Column field="description" header={t("description")} body={descriptionBodyTemplate}></Column>
                                                    <Column field="typeId" header={t("category")} body={typeBodyTemplate}></Column>
                                                    {/* <Column field="date" header="Fecha"  body={dateBodyTemplate} ></Column> */}
                                                    <Column field="total" header={t("total")} body={totalBodyTemplate}></Column>
                                                    <Column header={t("action")}  body={actionBodyTemplate} /> 
                                                </DataTable>
                                     
                                        </div>

                                    {/* <div className="col-12">
        
                                        <div className="s-total-body">
                                            <p className="s-title">Total</p>
                                            <p className="s-total">L {format(inputs?.total??0,2)}</p>
                                        </div>
                            
                                    </div> */}
                            </div>
                        </div>
                </div>
        
        </div>
    );
}

export default withTranslation()(BillPage);

