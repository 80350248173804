import { Dispatch } from "@reduxjs/toolkit";
import { alertActions } from "../../../auth/actions/alert.actions";
import { Bill } from "../models/bill";
import { billService } from "../bill.service";
import { billsConstants } from "../constanst/bills.constants";
import { BillCategory } from "../models/bill-category";
import { DataDialogProps } from "../../../models/data.dialog.props";

export const billsActions = {
    save,
    clearBill,
    getCategories,
    getBills,
    getById,
    preview,
    next,
    cancelBill,
    openModalBillCategory,
    addCategory,
    editCategory
};

function clearBill() {
    return {type: billsConstants.CLEAN_BILL};
}

function openModalBillCategory(openBillCategory:DataDialogProps<BillCategory>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: billsConstants.OPEN_MODAL_BILL_CATEGORY, openBillCategory });
    };
}

function save(bill:Bill) {
    return (dispatch: Dispatch) => {
        dispatch(request(bill));
        billService.save(bill)
            .then((result)=> { 
                    dispatch(success(result.data));
                    dispatch(alertActions.success(`Gasto agregado exitosamente ${result.data.billId}`));
                    // dispatch( printComprobant(result.data.suscriptionId!)  as any);
                },
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(bill,message));
                dispatch(alertActions.error(message));
              
            });
    };
    function request(bill:Bill) { return { type: billsConstants.SAVE_REQUEST_BILL, bill } }
    function success(bill:Bill) { return { type: billsConstants.SAVE_SUCCESS_BILL, bill } }
    function failure(bill:Bill, error:any) { return { type: billsConstants.SAVE_FAILURE_BILL, bill, error } }
}
function addCategory(billCategory:BillCategory) {
    return (dispatch: Dispatch) => {
        dispatch(request(billCategory));
        billService.addCategory(billCategory)
            .then((result)=> { 
                    dispatch(success(result.data));
                    dispatch(alertActions.success(`Categoría agregada exitosamente`));
                    // dispatch( printComprobant(result.data.suscriptionId!)  as any);
                },
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(billCategory,message));
                dispatch(alertActions.error(message));
              
            });
    };
    function request(billCategory:BillCategory) { return { type: billsConstants.SAVE_REQUEST_BILL_CATEGORY, billCategory } }
    function success(categories:BillCategory[]) { return { type: billsConstants.SAVE_SUCCESS_BILL_CATEGORY, categories } }
    function failure(billCategory:BillCategory, error:any) { return { type: billsConstants.SAVE_FAILURE_BILL_CATEGORY, billCategory, error } }
}
function editCategory(billCategory:BillCategory) {
    return (dispatch: Dispatch) => {
        dispatch(request(billCategory));
        billService.editCategory(billCategory)
            .then((result)=> { 
                    dispatch(success(result.data));
                    dispatch(alertActions.success(`Categoría editada exitosamente `));
                    // dispatch( printComprobant(result.data.suscriptionId!)  as any);
                },
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(billCategory,message));
                dispatch(alertActions.error(message));
              
            });
    };
    function request(billCategory:BillCategory) { return { type: billsConstants.EDIT_REQUEST_BILL_CATEGORY, billCategory } }
    function success(categories:BillCategory[]) { return { type: billsConstants.EDIT_SUCCESS_BILL_CATEGORY, categories } }
    function failure(billCategory:BillCategory, error:any) { return { type: billsConstants.EDIT_FAILURE_BILL_CATEGORY, billCategory, error } }
}

function getBills(fromDate:string, toDate:string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        billService.getBills(fromDate, toDate)
            .then((result)=> { 
                    dispatch(success(result.data));
                },
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
              
            });
    };
    function request() { return { type: billsConstants.GET_BILLS_REQUEST } }
    function success(bills:Bill[]) { return { type: billsConstants.GET_BILLS_SUCCESS, bills } }
    function failure(error:any) { return { type: billsConstants.GET_BILLS_FAILURE, error } }
}

function getById(billId:string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        billService.getById(billId)
            .then((result)=> { 
                    dispatch(success(result.data));
                    console.log(result.data)
                },
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
              
            });
    };
    function request() { return { type: billsConstants.GET_BILL_BY_ID_REQUEST } }
    function success(bill:Bill) { return { type: billsConstants.GET_BILL_BY_ID_SUCCESS, bill } }
    function failure(error:any) { return { type: billsConstants.GET_BILL_BY_ID_FAILURE, error } }
}

function getCategories() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        billService.getCategories()
            .then((result)=> { 
                    dispatch(success(result.data));
                },
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
              
            });
    };
    function request() { return { type: billsConstants.GET_BILL_CATEGORIES_REQUEST } }
    function success(categories: BillCategory[]) { return { type: billsConstants.GET_BILL_CATEGORIES_SUCCESS, categories } }
    function failure(error:any) { return { type: billsConstants.GET_BILL_CATEGORIES_FAILURE, error } }
}


function next(billId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        billService.next(billId)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: billsConstants.NEXT_REQUEST_BILL } }
    function success(bill:Bill) { return { type: billsConstants.NEXT_SUCCESS_BILL, bill } }
    function failure(error:any) { return { type: billsConstants.NEXT_FAILURE_BILL,  error } }
}

function preview(billId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        billService.preview(billId)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: billsConstants.PREVIEW_REQUEST_BILL } }
    function success(bill:Bill) { return { type: billsConstants.PREVIEW_SUCCESS_BILL, bill } }
    function failure( error:any) { return { type: billsConstants.PREVIEW_FAILURE_BILL, error } }
}

function cancelBill(billId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        billService.cancelBill(billId)
            .then((result)=> { 
                    dispatch(success(result.data));
                    dispatch(alertActions.success(`Gasto anulado exitosamente ${result.data.billId}`));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: billsConstants.CANCEL_BILL_REQUEST } }
    function success(bill:Bill) { return { type: billsConstants.CANCEL_BILL_SUCCESS, bill } }
    function failure( error:any) { return { type: billsConstants.CANCEL_BILL_FAILURE, error } }
}