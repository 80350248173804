export class BillCategory { 
    id?: number;
    name?: string;
    active?: boolean;

    constructor(datos?: BillCategory) {
        if (datos != null) {
        this.id = datos.id;
        this.name = datos.name;
        this.active = datos.active;
        return;
        }
    }
}