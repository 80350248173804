import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { DataDialogProps } from '../../../models/data.dialog.props';
import { DialogProps } from '../../../models/dialog.props';
import { InputText } from 'primereact/inputtext';
import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { getDateFormat, getDateFormatNew } from '../../../helpers/format';
import { TypeDialog } from '../../../helpers/type_dialog';
import Logo from'../../../assets/images/logo.png';
import { ImageSrc } from '../../../components/image-src';
import { SuscriptionHistorical } from '../models/suscription-historical';
import { suscriptionActions } from '../actions/suscription.actions';
import { Calendar } from 'primereact/calendar';
import { User } from '../../settings/users/models/user';
import { withTranslation } from 'react-i18next';


  function DialogUpdateHistorical(props: DialogProps<SuscriptionHistorical>) {
    const { onClose, t} = props;
    const dispatch = useDispatch();    
    const openHistorical  = useSelector((state: any) => state.suscription.openHistorical as DataDialogProps<SuscriptionHistorical>);
    const [inputs, setInputs] = React.useState<SuscriptionHistorical>(openHistorical?.select);
     const user:User  = useSelector((state: any) => state.authentication.user as User);

    useEffect(() => {
         setInputs(openHistorical?.select)
    }, [openHistorical]);


    const handleClose = () => {
      dispatch(suscriptionActions.openModalUpdateHistorical({
        open : false,
        select :new SuscriptionHistorical(),
        type : TypeDialog.add,
        loading: false
      }));
    };
 
    
    function handleSubmit(e:any) {
        e.preventDefault();
        dispatch(suscriptionActions.updateHistorical(inputs));
        
    }

    const showUpdateBtn =  () => {
        
        var permission = user.permissions.find(x=> x.path == "btn_update_suscription");
        return permission;
    }
    function handleChange(e:any) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }


    const footer = (
        <div>
            { showUpdateBtn()? <Button loading={openHistorical.loading} disabled={openHistorical.loading} label="Guardar" onClick={handleSubmit} icon="pi pi-save"/>
            : <></>
            }
           
        </div>
    );


   
  
    return (
        <Dialog  header={`${t("editSuscription")}: `+ (inputs.customer || "")}  footer={footer} visible={openHistorical?.open??false} onHide={handleClose} breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '45vw'}}>
            <div className='p-fluid grid'>   

                <div className="col-12">
                    <div className="grid">
                        <div className="col-9">
                            <div className="grid">
                                <div className="field col-4">
                                    <label htmlFor="customerId" className="block">No</label>
                                    <InputText id="customerId" disabled={true} value={inputs.customerId || ""} readOnly={true} className="block"/>
                                    {/* <small id="username1-help" className="block">Ingrese el No</small> */}
                                </div>
                                <div className="field col-8">
                                    <label htmlFor="customer" className="block">Nombre</label>
                                    <InputText id="customer" disabled={true}  name="customer"  value={inputs.customer || ""}  className={"block"}/>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="startingDate"  className="block">Inicio</label>
                                    <InputText id="startingDate"disabled={true}  name="startingDate"  value={getDateFormat(inputs.startingDate, user.lang)}  className="block"/>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="endingDate" className="block">Fin</label>
                                    {/* <InputText id="endingDate" name="endingDate" value={} onChange={handleChange} className={(!inputs?.endingDate) ? "block p-invalid":"block"}/> */}
                                    <Calendar id="endingDate" name="endingDate" value={getDateFormatNew(inputs.endingDate)} onChange={handleChange}></Calendar>
 
                                    {(!inputs?.endingDate) ?<small id="endingDate-help" className="block p-error">Ingrese la fecha final</small>: <></> }
                                </div>
                                
         
                            </div>
                        </div>
                        <div className="col-3">
                            {
                                (inputs.photoUri) ?
                                <ImageSrc  src={inputs.photoUri} />
                
                                :<img height="100%" width={"100%"} style={{maxHeight:"210px", objectFit: "cover"}} src={ Logo || ""} alt="" />
                            }
       
                        </div>
                    </div>
                </div>      
            </div>
        </Dialog>
    );
  }
  export default withTranslation()(DialogUpdateHistorical);
