import { Link} from 'react-router-dom';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import Logo from'../assets/images/logo.png';
import { Menu } from 'primereact/menu';
import { authActions } from '../auth/actions/auth.actions';
import { useDispatch, useSelector } from 'react-redux';
import { MegaMenu } from 'primereact/megamenu';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import SearchBar from './search_bar/SearchBar';
import { User } from '../pages/settings/users/models/user';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';
import { languages } from '../helpers/consts';
import i18next from '../i18n';
import environment from '../environment';
import { PendientPayBanner } from './pendient-pay-banner/PendientPayBanner';

interface propsTopBar extends WithTranslation{
    
    layoutColorMode: string,
    onToggleMenuClick: (value: any) => void,
    mobileTopbarMenuActive: boolean,
    onMobileTopbarMenuClick: (value: any) => void,
    onMobileSubTopbarMenuClick: (value: any) => void,
}


function AppTopbar(props: propsTopBar) {
const {t} = props;
const dispatch = useDispatch();
const user: User= useSelector((state:any) => state.authentication.user);
const [selectedCountry, setSelectedCountry] = useState(null);
useEffect(() => {
   document.title = user.company??"The Gym"
 
}, []);



const countryOptionTemplate = (option:any) => {
    return (
        <div className="flex align-items-center">
            <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.lang.toLowerCase()}`} style={{ width: '18px' }} />
            <div>{option.name}</div>
        </div>
    );
};

const selectedCountryTemplate = (option:any, props:any) => {
    if (option) {
        return (
            <div className="flex align-items-center">
                <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.lang.toLowerCase()}`} style={{ width: '18px' }} />
                <div>{option.name}</div>
            </div>
        );
    }

    return <span>{props.placeholder}</span>;
};

let items = [
    {
        label: 'Ver Perfil', 
        icon: 'pi pi-fw pi-user',
        command: () => {
            console.log("Perfil");
        }
    },
    {
        label: 'Cerrar Sesión',
        icon: 'pi pi-fw pi-sign-out',
        command: () => {
            dispatch(authActions.logout()); 
        }
    }
];
const menu = useRef<Menu>(null);
    return (

        <div className="layout-topbar">
            <Link to="/home" className="layout-topbar-logo">
                {/* <img src={props.layoutColorMode === 'light' ? Logo : Logo} alt="Logo Diunsa"/> */}
                <img src={user.logoUri} alt="Logo"/>
                <span> {user.company}</span>
            </Link>



            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars"/>
            </button>

          
            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>
            
             {/* <SearchBar/>
            <div className="left-menu">
                <div className="menu-item">
                    <Menu model={items} popup ref={menu} id="popup_menu" />
                    <div className="profile" onClick={(event) => menu?.current?.toggle(event)}>
                        <span className="profile-text">Hola, {user.userName}</span>
                        <i className="pi pi-caret-down mt-2"></i>
                    </div>  
                </div>

            </div> */}

             <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                {
                !environment.isProduction ?
                    <li>
                <Dropdown value={selectedCountry} onChange={(e) =>{
                    
                    setSelectedCountry(e.value);
                    i18next
                    .changeLanguage(e.value??"en")
                    .then((t) => { });
                }
                
                } options={languages} optionLabel="name" optionValue='lang' placeholder={t("language")} 
                    valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate} className="w-full md:w-14rem" 
                    // dropdownIcon={(opts) => {
                    //     return opts.iconProps['data-pr-overlay-visible'] ? <ChevronRightIcon {...opts.iconProps} /> : <ChevronDownIcon {...opts.iconProps} />;
                    // }} 
                />
                    
                </li>
                :<></>
}
                <li>
                    <button className="p-link layout-topbar-button"  onClick={props.onMobileSubTopbarMenuClick}>
                        {/* <i className="pi pi-reply"/> */}
                        {props.t('hello')}, {user.userName?.toLocaleLowerCase()}
                    </button>                    
                </li>
                <li>
                    <button className="p-link layout-topbar-button" onClick={()=>{dispatch(authActions.logout()); }}>
                        <i className="pi pi-unlock"/>
                        <span>Salir</span>
                    </button>
                </li> 
            </ul>
        <PendientPayBanner/>
        </div>
    );
}
export default withTranslation()(AppTopbar);
