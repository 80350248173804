import { Dispatch } from "@reduxjs/toolkit";
import { alertActions } from "../../../auth/actions/alert.actions";
import { listSuscriptionConstants } from "../constants/list-suscription.constants";
import { Suscription } from "../../suscriptions/models/suscription";
import { suscriptionService } from "../../suscriptions/suscription.service";
export const listSuscriptionActions = {
    getByDate,
};

function getByDate(fromDate:string, toDate:string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        suscriptionService.getByDate(fromDate, toDate)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: listSuscriptionConstants.GET_SUSCRIPTION_BY_DATE_REQUEST } }
    function success(suscriptions:Suscription[]) { return { type: listSuscriptionConstants.GET_SUSCRIPTION_BY_DATE_SUCCESS, suscriptions } }
    function failure( error:any) { return { type: listSuscriptionConstants.GET_SUSCRIPTION_BY_DATE_FAILURE, error } }
}