import axios from 'axios';
import environment from '../../environment';
import { Team } from './models/team';

export const teamService = {
  save,
  edit,
  getTeams,
  getById,
  preview,
  next,
  // cancelBill,
};
 function save(team:Team) {
    return axios.post<Team>(`${environment.apiUri}/Team`, team)
  };
 function edit(team:Team) {
    return axios.put<Team>(`${environment.apiUri}/Team`, team)
  };
  function getTeams() :Promise<any> {
    return axios.get<Team[]>(`${environment.apiUri}/Team`)
  };
  
  function getById(teamId: string) :Promise<any> {
   return axios.get<Team>(`${environment.apiUri}/Team/GetById?teamId=${teamId}`)
  };
  function preview(teamId:string){
    return axios.get<Team>(`${environment.apiUri}/Team/preview?teamId=${teamId}`)
  };
  function next(teamId:string){
    return axios.get<Team>(`${environment.apiUri}/Team/next?teamId=${teamId}`)
  };
//  function cancelBill(billId:string){
//    return axios.get<Bill>(`${environment.apiUri}/Team/cancelBill?billId=${billId}`)
//   };