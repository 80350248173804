import { Outlet, useLocation} from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { CSSTransition } from 'react-transition-group';
import AppTopbar from "../components/AppTopbar";
import { AppMenu } from "../components/AppMenu";
import { Tooltip } from 'primereact/tooltip';
import PrimeReact from 'primereact/api';
import classNames from 'classnames';
import { useSelector } from "react-redux";
import '../assets/layout/layout.scss';

function AdminLayout(){
    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef<any>();
    const location = useLocation();
    const user = useSelector((state: any) => state.authentication.user);

    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

  
    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

     useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    // const onInputStyleChange = (inputStyle:any) => {
    //     setInputStyle(inputStyle);
    // }

    // const onRipple = (e:any) => {
    //     PrimeReact.ripple = e.value;
    //     setRipple(e.value)
    // }

    // const onLayoutModeChange = (mode:any) => {
    //     setLayoutMode(mode)
    // }

    // const onColorModeChange = (mode:any) => {
    //     setLayoutColorMode(mode)
    // }

    const onWrapperClick = (event:any) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onToggleMenuClick = (event:any) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        }
        else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMobileTopbarMenuClick = (event:any) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event:any) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    }

    const onMenuItemClick = (event:any) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }
    const isDesktop = () => {
        return window.innerWidth >= 992;
    }

    // const menu = [
    //     {
    //         label: 'Inicio',
    //         items: [{
    //             label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/'
    //         }]
    //     },
    //     {
    //         label: 'Clientes', icon: 'pi pi-fw pi-sitemap',
    //         items: [
    //             { label: 'Información General', icon: 'pi pi-fw pi-id-card', to: '/clientes' },
    //             { label: 'Documentos', icon: 'pi pi-fw pi-check-square', to: '/clientes1' ,
    //                 items:[
    //                     { label: 'Facturas', icon: 'pi pi-fw pi-bookmark', to: '/clientes2'},
    //                     { label: 'Créditos', icon: 'pi pi-fw pi-bookmark', to: '/clientes23'},
    //                     { label: 'Débitos', icon: 'pi pi-fw pi-bookmark', to: '/clientes3'}
    //                 ]
    //             },
    //             { label: 'Cheques posfechados', icon: 'pi pi-fw pi-check-square', to: '/clientes4' ,
    //                 items:[
    //                     { label: 'Pendientes de depositar', icon: 'pi pi-fw pi-bookmark',to: '/clientes5'},
    //                     { label: 'Depositados', icon: 'pi pi-fw pi-bookmark', to: '/clientes6'},
    //                     { label: 'Rechazados', icon: 'pi pi-fw pi-bookmark', to: '/clientes7'}
    //                 ]
    //             },
    //             { label: "Listas de precios", icon: "pi pi-fw pi-bookmark", to: "/clientes8" },
    //             { label: 'Relación de vendedor', icon: 'pi pi-fw pi-table', to: '/clientes9' },
    //         ]
    //     },
    //     {
    //         label: 'Solicitudes',
    //         items: [
    //             { label: 'Devolución de mercadería', icon: 'pi pi-fw pi-eye', to: '/blocks', badge: "5" },
    //             // { label: 'Free', icon: 'pi pi-fw pi-eye', to: '/blocks', badge: "NEW2" },
    //         ]
    //     },
    //     {
    //         label: 'Catálogo de Productos',
    //         items: [
    //             { label: 'Inventario de productos', icon: 'pi pi-fw pi-book', to: '/icons' },
    //             { label: 'UME', icon: 'pi pi-fw pi-box', to: '/icons' },
    //             { label: 'Categorías', icon: 'pi pi-fw pi-hashtag', to: '/icons' },
    //             { label: 'Fotografía', icon: 'pi pi-fw pi-camera', to: '/icons' },
    //             { label: 'Precios', icon: 'pi pi-fw pi-dollar', to: '/icons' }
    //         ]
    //     },
    //     {
    //         label: 'Cotizaciones', icon: 'pi pi-fw pi-clone',
    //         items: [
    //             { label: 'Confirmación', icon: 'pi pi-fw pi-user-edit', to: '/crud' },
    //             // { label: 'Timeline', icon: 'pi pi-fw pi-calendar', to: '/timeline' },
    //         ]
    //     },
    //     {
    //         label: 'Pedidos de venta', icon: 'pi pi-fw pi-clone',
    //         items: [
    //             { label: 'Histórico', icon: 'pi pi-fw pi-user-edit', to: '/crud' },
    //             { label: 'Estados actuales', icon: 'pi pi-fw pi-calendar', to: '/timeline' },
    //         ]
    //     },
    // ];

    const addClass = (element:any, className:any) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element:any, className:any) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });
    return (        
        <div className={wrapperClass} onClick={onWrapperClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />
            <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} /> 

            <div className="layout-sidebar" onClick={onSidebarClick}>
                <AppMenu model={user.menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
            </div> 

            <div className="layout-main-container">
                <div className="layout-main">
                    <Outlet />
                      {/* <Routes>
                            <Route path="/" element={<Button label="Publico" />} />
                            <Route path="/home" element={<Button label="Home" />} />
                            <Route path="/clientes" element={<Button label="clientes" />} />
                            <Route path="/prueba" element={<PrivateRoute path="/prueba" component={<Button label="Privado" />} />} />
                            <Route path="/listado-usuarios" element={<PrivateRoute path="/listado-usuarios" component={ListUserPage} />} />
                            <Route path="/*" element={<Navigate state={{ from: "*" }}to="/home" />} />
                        </Routes> */}

                </div>
            {/* <AppFooter layoutColorMode={layoutColorMode} /> */}
            </div>

           {/* <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} /> */}
            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>
        </div>
    )
}
export default AdminLayout;