import { teamsConstants } from "../constanst/teams.constants";
import { Team } from "../models/team";
import { TeamsState } from "../models/teams.state";


const initialState: TeamsState = { 
    loading: false, 
    error: "",
    team: new Team(),
    teams: [],
};

export function teams(state:TeamsState = initialState, action:any) {
    switch (action.type) {


        case teamsConstants.CLEAN_TEAM:
            return {
                ...state,
                team: new Team(),
                
            };

        case teamsConstants.SAVE_REQUEST_TEAM:
            return {
                ...state,
                 loading: true,
                 team: action.team,
            };
         case teamsConstants.SAVE_SUCCESS_TEAM:
            return {
                ...state,
                loading: false,
                team: action.team
            };
        case teamsConstants.SAVE_FAILURE_TEAM:
            return {
                ...state,
                loading: false,
                team: action.team
            };
        
        case teamsConstants.EDIT_TEAM_REQUEST:
            return {
                ...state,
                 loading: true,
                 team: action.team,
            };
         case teamsConstants.EDIT_TEAM_SUCCESS:
            return {
                ...state,
                loading: false,
                team: action.team
            };
        case teamsConstants.EDIT_TEAM_FAILURE:
            return {
                ...state,
                loading: false,
                team: action.team
            };
   
        case teamsConstants.GET_TEAMS_REQUEST:
            return {
                ...state,
                loading: true,
                teams: []
            };
        case teamsConstants.GET_TEAMS_SUCCESS:
            return {
                ...state,
                loading: false,
                teams: action.teams
            };
        case teamsConstants.GET_TEAMS_FAILURE:
            return {
                ...state,
                loading: false,
                teams: []
            };


        case teamsConstants.NEXT_REQUEST_TEAM:
            return {
                ...state,
                loading: true,
            };
        case teamsConstants.NEXT_SUCCESS_TEAM:
            return {
                ...state,
                loading: false,
                team: action.team
            };
        case teamsConstants.NEXT_FAILURE_TEAM:
            return {
                ...state,
                loading: false,
            };


        case teamsConstants.PREVIEW_REQUEST_TEAM:
            return {
                ...state,
                loading: true,
            };
        case teamsConstants.PREVIEW_SUCCESS_TEAM:
            return {
                ...state,
                loading: false,
                team: action.team
            };
        case teamsConstants.PREVIEW_FAILURE_TEAM:
            return {
                ...state,
                loading: false,
            };


        case teamsConstants.GET_TEAM_BY_ID_REQUEST:
            return {
                ...state,
                loading: true,
                team: new Team()
            };
        case teamsConstants.GET_TEAM_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                team: action.team
            };
        case teamsConstants.GET_TEAM_BY_ID_FAILURE:
            return {
                ...state,
                loading: false,
            };

        default:
            return state
    }
}