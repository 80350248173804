import { Dispatch } from "@reduxjs/toolkit";
import { alertActions } from "../../../auth/actions/alert.actions";
import { DataDialogProps } from "../../../models/data.dialog.props";
import { printPdfActions } from "../../print-pdf/actions/item.actions";
import { BatchSuscription } from "../models/batch-suscription";
import { batchSuscriptionService } from "../batch-suscription.service";
import { batchSuscriptionConstants } from "../constants/batch-suscription.constants";

export const batchSuscriptionActions = {
    openModalSearchItem,
    save,
    addLine,
    removeLine,
    editLinePrice,
    preview,
    next,
    selectCustomer,
    printComprobant,
    openModalPaymentMethodBatch,
    cleanBatchSuscription
};

function openModalSearchItem(openSelectItemBatch:DataDialogProps<(BatchSuscription[])>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: batchSuscriptionConstants.OPEN_BATCH_MODAL_SEARCH_ITEM, openSelectItemBatch });
    };
}


function openModalPaymentMethodBatch(openPaymentMethodBatch:DataDialogProps<(number)>) {
    return {type: batchSuscriptionConstants.OPEN_MODAL_PAYMENT_METHOD_BATCH, openPaymentMethodBatch };
}

function cleanBatchSuscription() {
    return (dispatch: Dispatch) => {
        dispatch(  { type: batchSuscriptionConstants.CLEAN_BATCH_SUSCRIPTION });
    };
}


function removeLine(batchSuscription:BatchSuscription ,customerId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(batchSuscription));
        batchSuscriptionService.removeLine(batchSuscription, customerId)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(batchSuscription,message));
                dispatch(alertActions.error(message));
            });
    };
    function request(batchSuscription:BatchSuscription) { return { type: batchSuscriptionConstants.REMOVE_BATCH_LINE_REQUEST, batchSuscription } }
    function success(batchSuscription:BatchSuscription) { return { type: batchSuscriptionConstants.REMOVE_BATCH_LINE_SUCCESS, batchSuscription } }
    function failure(batchSuscription:BatchSuscription, error:any) { return { type: batchSuscriptionConstants.REMOVE_BATCH_LINE_FAILURE, batchSuscription, error } }
}
function save(batchSuscription:BatchSuscription) {
    return (dispatch: Dispatch) => {
        dispatch(request(batchSuscription));
        batchSuscriptionService.save(batchSuscription)
            .then((result)=> { 
                    dispatch(success(result.data));
                    dispatch(alertActions.success(`Suscripción agregado exitosamente ${result.data.batchSuscriptionId}`));
                    dispatch( printComprobant(result.data.batchSuscriptionId!)  as any);
                },
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(batchSuscription,message));
                dispatch(alertActions.error(message));
              
            });
    };
    function request(batchSuscription:BatchSuscription) { return { type: batchSuscriptionConstants.SAVE_BATCH_REQUEST, batchSuscription } }
    function success(batchSuscription:BatchSuscription) { return { type: batchSuscriptionConstants.SAVE_BATCH_SUCCESS, batchSuscription } }
    function failure(batchSuscription:BatchSuscription, error:any) { return { type: batchSuscriptionConstants.SAVE_BATCH_FAILURE, batchSuscription, error } }
}


function addLine(batchSuscription:BatchSuscription ,itemId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(batchSuscription));
        batchSuscriptionService.addLine(batchSuscription, itemId)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(batchSuscription,message));
                dispatch(alertActions.error(message));
            });
    };
    function request(batchSuscription:BatchSuscription) { return { type: batchSuscriptionConstants.ADD_BATCH_LINE_REQUEST, batchSuscription } }
    function success(batchSuscription:BatchSuscription) { return { type: batchSuscriptionConstants.ADD_BATCH_LINE_SUCCESS, batchSuscription } }
    function failure(batchSuscription:BatchSuscription, error:any) { return { type: batchSuscriptionConstants.ADD_BATCH_LINE_FAILURE, batchSuscription, error } }
}

function editLinePrice(batchSuscription:BatchSuscription , customerId : string,  itemId: string, price: number) {
    return (dispatch: Dispatch) => {
        dispatch(request(batchSuscription));
        batchSuscriptionService.editLinePrice(batchSuscription,customerId, itemId, price)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(batchSuscription,message));
                dispatch(alertActions.error(message));
            });
    };
    function request(batchSuscription:BatchSuscription) { return { type: batchSuscriptionConstants.EDIT_BATCH_LINE_QUANTITY_REQUEST, batchSuscription } }
    function success(batchSuscription:BatchSuscription) { return { type: batchSuscriptionConstants.EDIT_BATCH_LINE_QUANTITY_SUCCESS, batchSuscription } }
    function failure(batchSuscription:BatchSuscription, error:any) { return { type: batchSuscriptionConstants.EDIT_BATCH_LINE_QUANTITY_FAILURE, batchSuscription, error } }
}




function next(batchSuscriptionId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        batchSuscriptionService.next(batchSuscriptionId)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: batchSuscriptionConstants.NEXT_BATCH_REQUEST } }
    function success(batchSuscription:BatchSuscription) { return { type: batchSuscriptionConstants.NEXT_BATCH_SUCCESS, batchSuscription } }
    function failure(error:any) { return { type: batchSuscriptionConstants.NEXT_BATCH_FAILURE,  error } }
}

function preview(batchSuscriptionId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        batchSuscriptionService.preview(batchSuscriptionId)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: batchSuscriptionConstants.PREVIEW_BATCH_REQUEST } }
    function success(batchSuscription:BatchSuscription) { return { type: batchSuscriptionConstants.PREVIEW_BATCH_SUCCESS, batchSuscription } }
    function failure( error:any) { return { type: batchSuscriptionConstants.PREVIEW_BATCH_FAILURE, error } }
}


function selectCustomer(customerId: string, batchSuscription: BatchSuscription) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        batchSuscriptionService.selectCustomer(customerId, batchSuscription)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: batchSuscriptionConstants.SELECT_CUSTOMER_TEAM_REQUEST } }
    function success(batchSuscription:BatchSuscription) { return { type: batchSuscriptionConstants.SELECT_CUSTOMER_TEAM_SUCCESS, batchSuscription } }
    function failure(error:any) { return { type: batchSuscriptionConstants.SELECT_CUSTOMER_TEAM_FAILURE, error } }
}



function printComprobant(batchSuscriptionId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        batchSuscriptionService.printComprobant(batchSuscriptionId)
            .then((result)=> { 
                    dispatch(success());
                    dispatch(printPdfActions.openPrintPdf({
                        open: true,
                        loading: false,
                        select: result.data.pdf,
                        type : 1
                    }));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: batchSuscriptionConstants.PRINT_COMPROBANT_BATCH_REQUEST } }
    function success() { return { type: batchSuscriptionConstants.PRINT_COMPROBANT_BATCH_SUCCESS } }
    function failure( error:any) { return { type: batchSuscriptionConstants.PRINT_COMPROBANT_BATCH_FAILURE, error } }
}

