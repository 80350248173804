import { PendientPayBannerState } from "./models/pendient-pay-banner.state";
import { pendientPayBannerConstants } from "./pendient-pay-banner.constants";

const initialState: PendientPayBannerState = { 
    loading: false, 
    message: "",
    isOpen: false,
};

export function pendientPayBanner(state:PendientPayBannerState = initialState, action:any) {
    switch (action.type) {

        case pendientPayBannerConstants.CLOSE_PENDIENT_PAY_BANNER:
            return {
                ...state,
                isOpen: false

            };
        case pendientPayBannerConstants.GET_PENDIENT_PAY_BANNER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case pendientPayBannerConstants.GET_PENDIENT_PAY_BANNER_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.pendientPayBanner.message,
                isOpen: action.pendientPayBanner.isExpired,
            };
        case pendientPayBannerConstants.GET_PENDIENT_PAY_BANNER_FAILURE:
            return {
                ...state,
                loading: false,
            };

        default:
            return state
    }
}