export class Langs {
    lang?: string;
    name?: string;
    
    constructor(datos?: Langs) {
        if (datos != null) {
        this.lang = datos.lang;
        this.name = datos.name;
        return;
        }
    }

}
