import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useEffect } from 'react';
import { Button } from 'primereact/button';
import { SkeletonComponent } from '../../../components/SkeletonComponet';
import { TemplatePaginator } from '../../../components/TemplatePaginator';
import { DeviceTask } from './models/device-task';
import { deviceTaskActions } from './actions/device-task.actions';
import { User } from '../users/models/user';
import { Checkbox } from 'primereact/checkbox';
import { WithTranslation, withTranslation } from 'react-i18next';

function ListDeviceTaskPage({t}: WithTranslation) {
    const tasks: DeviceTask[] = useSelector((state:any) => state.devicesTasks.tasks as DeviceTask[]);
    const loading: boolean= useSelector((state:any) => state.devicesTasks.loading);
    const user  = useSelector((state: any) => state.authentication.user as User);
    const dispatch = useDispatch();

    useEffect(() => { 
        onRefresh();
    },[]);
   const onOpenDoor = () => {
         dispatch(deviceTaskActions.openDoor());
    }
   const onGetUsersInClock = () => {
         dispatch(deviceTaskActions.getUsersInClockManual());
    }
   const onGetAttenLog = () => {
         dispatch(deviceTaskActions.getAttenLog());
    }
   const syncAllFingers = () => {
         dispatch(deviceTaskActions.syncAllFingers());
    }
   const removeAllFingers = () => {
         dispatch(deviceTaskActions.removeAllFingers());
    }
   const onRefresh = () => {
         dispatch(deviceTaskActions.get());
    }
    const renderHeader = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                <h5 className="m-0">{t("tasksList")}</h5>
                <span className="p-input-icon-left">
                    <Button className='ml-3' label={t("addFingerPrints")}  icon="pi pi-calendar-plus" onClick={syncAllFingers} iconPos="left"  />
                    <Button className='ml-3' label={t("removeFingerPrints")}  icon="pi pi-trash" onClick={removeAllFingers} iconPos="left"  />
                    <Button className='ml-3' label={t("openDoor")} icon="pi pi-lock-open" onClick={onOpenDoor} iconPos="left"  />
                    <Button className='ml-3' label={t("lookForUsers")}  icon="pi pi-clock" onClick={onGetUsersInClock} iconPos="left"  />
                    <Button className='ml-3' label={t("lookRecords")}  icon="pi pi-clock" onClick={onGetAttenLog} iconPos="left"  />
                    <Button className='ml-3' label={t("refresh")}  icon="pi pi-refresh" onClick={onRefresh} iconPos="left"  />
                </span>
            </div>
        )
    }

    const header = renderHeader();

    const actionBodyTemplate = (rowData:DeviceTask) => {
        const showDialog =()=>{
           
        }
      
        return  <span className="p-buttonset">
                    <Button label={t("reschedule")} icon="pi pi-calendar-minus" iconPos="left" onClick={showDialog} />
                </span>
    }
    const completedBodyTemplate = (rowData:DeviceTask) => {
     
        return  <div>
            {rowData.completed ? "Si": "No"}
        </div>
    }
    const dateBodyTemplate = (rowData:DeviceTask) => {
        let date = new Date(rowData.validUntil!);
        return <span>{date?.toLocaleDateString(user.lang == "es"? "es-HN": "en-US",{hour: '2-digit', minute:'2-digit'})}</span>
    }
 
    return ( 
        <div className="grid mt-1">
            <div className="col-12 card">
                {loading ? <SkeletonComponent/> :
                <DataTable  emptyMessage={t("noResultFound")} value={tasks} paginatorTemplate={TemplatePaginator as any} 
                        responsiveLayout="scroll"  rows={10}  size="small" stripedRows   header={header} >
                        <Column field="taskId" header={t("id")}></Column>
                        <Column field="message" header={t("description")}></Column>
                        <Column field="validUntil" header={t("validUntil")} body={dateBodyTemplate}></Column>
                        <Column field="completed" header={t("completed")} body={completedBodyTemplate}></Column>
                        <Column header={t("action")} body={actionBodyTemplate} />
                    </DataTable>
                }
            </div>
        </div>
    );
}

export default withTranslation()(ListDeviceTaskPage);