import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { format, getDateFormat } from '../../helpers/format';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { User } from '../settings/users/models/user';
import { useNavigate, useParams } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Team } from './models/team';
import { teamsActions } from './actions/teams.actions';
import { TeamDetail } from './models/team-detail';
import DialogSelectCustomer from '../customer/components/dialog.select-customer';
import { DataDialogProps } from '../../models/data.dialog.props';
import { Customer } from '../customer/models/customer';
import { customerActions } from '../customer/actions/customer.actions';
import { TypeDialog } from '../../helpers/type_dialog';
import { alertActions } from '../../auth/actions/alert.actions';
import { Checkbox } from 'primereact/checkbox';
import { ImageSrc } from '../../components/image-src';
import Logo from'../../assets/images/logo.png';

function TeamPage({t}: WithTranslation) {

    const loading: boolean= useSelector((state:any) => state.teams.loading);
    const team: Team = useSelector((state:any) => state.teams.team);
    const [inputs, setInputs] = useState<Team>(team);
    const navigate = useNavigate();
    const { id } = useParams();
    const user  = useSelector((state: any) => state.authentication.user as User);
    const openSelectCustomer  = useSelector((state: any) => state.customer.openSelectCustomer as DataDialogProps<Customer[]>);


    const dispatch = useDispatch();


 
    useEffect(() => {
        setInputs(team)
    }, [team]);

    useEffect(()=>{

        if(!canAddTeams()) {
            navigate("/")
            return;
        }
        if(id){
            dispatch(teamsActions.getById(id));
        }
        else{
            dispatch(teamsActions.clearTeam());
        }
    },[])

    const canAddTeams = ()=>{
        var permission = user.permissions.find(x=> x.path == "/teams");
        return permission;
    }

    const handAdd = () => {
        dispatch(customerActions.openModalSearchCustomer({
            open : true,
            select :[],
            type : TypeDialog.add,
            loading: true,
           
        }));
    }

    const onSave = () => {
        inputs.createdBy = user.userId;
        inputs.createdByName = user.name;
        inputs.createdDate = new Date().toISOString();
        inputs.active = true;
        if(!inputs.name){
            return;
        }
        if((inputs.detail??[]).length == 0){
            return;
        }
        dispatch(teamsActions.save(inputs));
    }
    const onEdit = () => {
        if(!inputs.name){
            return;
        }
        if((inputs.detail??[]).length == 0){
            return;
        }
        dispatch(teamsActions.edit(inputs));
    }
    
    const onClean = () => {
        dispatch(teamsActions.clearTeam());
        navigate("/team")
    }
 
    const onPreview = () => {
        dispatch(teamsActions.preview(inputs.teamId??""));
    }


    const onNext = () => {
        dispatch(teamsActions.next(inputs.teamId??""));
    }

    const onRemove = (item: TeamDetail)=>{
        let newInputs = {...inputs}
        let finIndex = newInputs.detail?.findIndex(c=> c.lineId == item.lineId)
        if(finIndex != -1){
            newInputs.detail?.splice(finIndex!,1)
            newInputs.detail!.forEach(((x,i)=> { x.lineId = i+1}))
            setInputs(newInputs);
        }
    }

        const renderHeader = () => {
                return (
                    <div className="flex justify-content-between align-items-center">
                        <h5>{t("members")}</h5>
                        <span className="p-input-icon-left mb-2">
                            <Button  style={{width:"200px"}} disabled={loading } label={t("addCustomer")} onClick={handAdd} icon="pi pi-plus" iconPos="left"  />
                        </span>
                    </div>
                )
            }
    const cannotEdit = () :boolean =>{
        var is = (inputs.teamId??"").toString().length > 1;
        return is;
    }

    const header = renderHeader();
 
    const actionBodyTemplate = (item:TeamDetail, option:any) => {    
         return <span className="p-buttonset">
            <Button className="p-button-danger" icon="pi pi-trash" disabled={loading} iconPos="left" onClick={(event:any)=>{onRemove(item) }} />
        </span>
    }
 
    function handleChange(e:any) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }
    function handleChangeCheck(e:any)  {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: e.target.checked  }));
      };

    const onSelectCustomer = (customer: Customer)=> {
        let newInputs = {...inputs}
        let isIN = newInputs.detail?.findIndex(x=> x.customerId == customer.customerId);
        if(isIN== -1){
                newInputs.detail = [new TeamDetail({
                    customer : customer.name,
                    customerId :customer.customerId,
                    photoUri: customer.photoUri
                }),...inputs.detail??[]]
                
                newInputs.detail.forEach(((x,i)=>  {
                    x.lineId = i+1;        
                }))
                setInputs(newInputs)
        }
        else{
            dispatch(alertActions.error(t("customerHasAlreadyBeenAdded")));
        }
    }


    const photoBodyTemplate = (teamLine: TeamDetail)=>{
        return  (teamLine.photoUri??"") ?
            <ImageSrc height="25px" width="25px" src={teamLine.photoUri??""} />

            :<img height="25px" width="25px"src={ Logo || ""} alt="" />

    }


    return (
        <div className="card">
          
                <div className="grid">
                        <div className="col-12"> 
                            <div className="flex justify-content-start">
                                <Button icon="pi pi-arrow-left" loading={loading} disabled={loading} onClick={onPreview} className="p-button-rounded p-button" aria-label="Anterior" />
                                <Button icon="pi pi-arrow-right" loading={loading} disabled={loading} onClick={onNext} className="p-button-rounded p-button" aria-label="Siguiente" />
                                {/* <Button icon="pi pi-print" disabled={!cannotEdit()} loading={loading} onClick={onPrintComprobant} className="p-button-rounded p-button-danger" /> */}
                                <Button icon="pi pi-file" loading={loading} label={t("new")} onClick={onClean} className="p-button-rounded " />
                                {
                                    cannotEdit() ? <Button icon="pi pi-pencil" loading={loading} disabled={!cannotEdit() || (inputs?.detail??[]).length ===0 }  label={t("edit")} onClick={onEdit} className="p-button-rounded p-button-warn" />
                                    : <Button icon="pi pi-save" loading={loading} disabled={cannotEdit() || (inputs?.detail??[]).length ===0 }  label={t("save")} onClick={onSave} className="p-button-rounded p-button-danger" />
                                }
                                
                                
                            </div>
                        </div>
                        <div className="col-12 mt-2">
                            <h4>{t("team")} {inputs.teamId}</h4>
                            <div className="grid">
                                <div className="col-12">
                                    <div className=" grid">
                               
                                        <div className="field col-12 p-fluid">
                                            <label htmlFor="name" className="block">{t("name")}</label>
                                            <InputTextarea  id="name" name="name" placeholder={t("nameRequired")} value={inputs?.name??""}
                                            disabled={loading}
                                            onChange={handleChange} 
                                            className={(!inputs?.name ) ? "w-full p-invalid block":"w-full block"} 
                                            />
                                            {(!inputs?.name) ?<small id="name-help" className="block p-error">{t("nameRequired")}</small>: <></> }
                                        </div>


                                       {cannotEdit() ?
                                       <>
                                        <div className="field col-4 p-fluid">
                                                <label htmlFor="createDate" className="block">{t("date")}</label>
                                                <InputText id="createDate"disabled={true} value={getDateFormat(new Date(inputs?.createdDate!), user.lang)} readOnly={true} className="block"/>
                                            </div>
                                           
                                            <div className="field col-4 p-fluid mt-5">
                                            
                                                <Checkbox inputId="cbActive" onChange={handleChangeCheck} name="active" checked={inputs?.active??false}></Checkbox>
                                                <label htmlFor="cbActive" className="p-checkbox-label">{t("active")}</label>
                                            </div>
                                        </>:
                                        <></>
                                        }

                                    </div>                                   
                                </div>
                          


                                 <div className="col-12">
                                            {header}
                                            <DataTable emptyMessage={t("noResultFound")} loading={loading}
                                                    scrollable scrollHeight='300px' showGridlines  height={200}  disabled={loading}
                                                    value={inputs?.detail??[]} responsiveLayout="scroll"  
                                                    size="small" stripedRows  >
                                                    <Column field="photoUri" header={t("photo")}  body={photoBodyTemplate} ></Column>
                                                    <Column field="customerId" header={t("customerId")} ></Column>
                                                    <Column field="customer" header={t("customer")}></Column>
                                                    <Column header={t("action")}  body={actionBodyTemplate} /> 
                                                </DataTable>
                                     
                                        </div>
                            </div>
                        </div>
                </div>
                <DialogSelectCustomer onSelect={onSelectCustomer}/>
        </div>
    );
}

export default withTranslation()(TeamPage);

