import axios from 'axios';
import environment from '../../../environment';


export const deviceTaskService = {
    get,
    openDoor,
    getUsersInClockManual,
    syncAllFingers,
    removeAllFingers,
    getAttenLog
};

 function get() {
    return axios.get(`${environment.apiUri}/device/deviceTask`)
  };
  
 function openDoor() {
    return axios.post(`${environment.apiUri}/device/openDoor`)
  };
 function getUsersInClockManual() {
    return axios.post(`${environment.apiUri}/device/SearchUserInClockManual`)
  };
 function syncAllFingers() {
    return axios.post(`${environment.apiUri}/device/SyncAllFingers`)
  };
 function removeAllFingers() {
    return axios.post(`${environment.apiUri}/device/RemoveAllFingers`)
  };
 function getAttenLog() {
    return axios.post(`${environment.apiUri}/device/GetAttenLog`)
  };