import { TeamDetail } from "./team-detail";

export class Team { 
    teamId?: string;
    name?: string;
    createdDate?: string;
    createdBy?:number;
    createdByName?:string;
    active?: boolean;
    detail?: TeamDetail[];

    constructor(datos?: Team) {
        if (datos != null) {
        this.teamId = datos.teamId;
        this.name = datos.name;
        this.createdDate = datos.createdDate;
        this.createdBy = datos.createdBy;
        this.createdByName = datos.createdByName;
        this.active = datos.active;
        this.detail = datos.detail;
        return;
        }
        this.detail = [];

    }
}