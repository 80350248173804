import axios from 'axios';
import environment from '../../environment';
import { PendientPayBannerModel } from './models/pendient-pay-banner.model';

export const pendientPayBannerService = {
    getPendientPayBanner
};

 function getPendientPayBanner() {
    return axios.get<PendientPayBannerModel>(`${environment.apiUri}/shared/GetGymStatus`)
  };
  