import axios from 'axios';
import environment from '../../environment';

export const reportService = {
    searchDiarySales,
    searchMonthlySales,
    searchSalesByItem,
    searchCustomersLoss,
    printMonthClose,
    searchDiarySalesByService,
    searchBillsResumen
};

  function searchDiarySales(fromDate: string, toDate: string) {
    return axios.get(`${environment.apiUri}/report/diarySales?fromDate=${fromDate}&&toDate=${toDate}`)
  };
  function searchDiarySalesByService(fromDate: string, toDate: string) {
    return axios.get(`${environment.apiUri}/report/DiarySalesByService?fromDate=${fromDate}&&toDate=${toDate}`)
  };
  function searchBillsResumen(fromDate: string, toDate: string) {
    return axios.get(`${environment.apiUri}/report/BillsResumen?fromDate=${fromDate}&&toDate=${toDate}`)
  };
  function searchMonthlySales(month:number, year: number) {
    return axios.get(`${environment.apiUri}/report/monthlySales?month=${month}&&year=${year}`)
  };
  function searchSalesByItem(month: number, year: number) {
    return axios.get(`${environment.apiUri}/report/SalesByItem?month=${month}&&year=${year}`)
  };
  function searchCustomersLoss(months: number) {
    return axios.get(`${environment.apiUri}/report/customersLoss?months=${months}`)
  };
  function printMonthClose(month: number, year: number) {
    return axios.get(`${environment.apiUri}/report/MonthClose?month=${month}&&year=${year}`)
  };
