import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {  useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TemplatePaginator } from '../../components/TemplatePaginator';
import { Button } from 'primereact/button';
import { User } from '../settings/users/models/user';
import { Badge } from 'primereact/badge';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Team } from './models/team';
import { teamsActions } from './actions/teams.actions';


function ListTeamPage({t}: WithTranslation) {
    const teams: Team[]= useSelector((state:any) => state.teams.teams);
    const loading: boolean= useSelector((state:any) => state.teams.loading);
    const dispatch = useDispatch();    
    const navigate = useNavigate();
    const user:User  = useSelector((state: any) => state.authentication.user as User);

    useEffect(()=>{
        handSearch()
    },[])
    const handSearch = ()=>{
        dispatch(teamsActions.getTeams()); 
    }

    const addTeam = ()=>{
        navigate(`/team`)
    }

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                <div>
                    <h5 className="m-0">{t("teamList")}</h5>  
                    <div>
               

                    </div>
                </div>

                <span className="p-input-icon-left mt-5">
                    <Button label={t("addNew")}  disabled={loading}  onClick={addTeam} icon="pi pi-pencil" iconPos="left"  />
                </span>
            </div>
        )
    }
    const header = renderHeader();

    const createBodyTemplate = (row:Team) => {
        if(row.createdDate == null) return <span>{t("notApplicable")}</span>
        let date = new Date(row.createdDate!);
        return <span>{date?.toLocaleDateString("es-US")}</span>
    }

    const actionBodyTemplate = (team:Team) => {
        const onUpdateTeam =()=>{
            navigate(`/team/${team.teamId}`)
        }
        return <span className="p-buttonset">
            <Button label={t("edit")} className="p-button p-button-outlined" icon="pi pi-pencil" iconPos="left" onClick={onUpdateTeam} />
        </span>
    }
     
      const statusBodyTemplate = (team:Team) => {
        return <Badge value={team.active ?  t("active") : t("inactive")} severity={team.active? undefined: "danger"} ></Badge>
    }

    return (

            <div className="grid  mt-1">
                    <div className=" col-12 card">
                    {header}
                    <DataTable  emptyMessage={t("noResultFound")} paginatorTemplate={TemplatePaginator as any} 
                        loading={loading} paginator rows={10}  disabled={loading}
                        value={teams??[]} responsiveLayout="scroll"
                        size="small" stripedRows  >
                        <Column filter filterType="contains" field="teamId" header={t("id")}></Column>
                        <Column filter filterType="contains" field="name" header={t("name")} ></Column>
                        <Column sortable  field="createDate" header={t("date")} body={createBodyTemplate}></Column>
                        <Column header={t("status")} body={statusBodyTemplate} />
                        <Column header={t("action")} body={actionBodyTemplate} />
                    </DataTable>
                </div>

            </div>
   
       
    );
}

export default withTranslation()(ListTeamPage);