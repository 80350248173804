import "primereact/resources/themes/fluent-light/theme.css";
//import "primereact/resources/themes/tailwind-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import '../assets/layout/layout.scss';
import './App.css';
import { Navigate, Route,  Routes, useLocation } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import AdminLayout from "../layout/AdminLayout";
import LoginLayout from "../layout/LoginLayout";
import { useEffect } from "react";
import { Button } from "primereact/button";
import ListUserPage from "../pages/settings/users/ListUserPage";
import ListRolePage from "../pages/settings/roles/ListRolePage";
import { ListPermissionPage } from "../pages/settings/permissions/ListPermissionPage";
import { LoginPage } from "../pages/login/LoginPage";
import { RestartPasswordPage } from "../pages/login/RestartPasswordPage";
import axios from "axios";
import { authActions } from "../auth/actions/auth.actions";
import { useDispatch, useSelector } from "react-redux";
import OfferListPage from "../pages/offers/OfferListPage";
import { searchBarActions } from "../components/search_bar/actions/search_bar.actions";
import { ItemDetailPage } from "../pages/items/ItemDetailPage";
import ListItemPage from "../pages/items/ListItemPage";

import CustomerListPage from "../pages/customer/CustomerListPage";
import SuscriptionPage from "../pages/suscriptions/SuscriptionPage";
import ActiveSuscriptionPage from "../pages/suscriptions/ActiveSuscriptionPage";
import ListDeviceTaskPage from "../pages/settings/device-task/ListDeviceTaskPage";
import DiarySalesPage from "../pages/reports/DiarySalesPage";
import SalesByItemPage from "../pages/reports/SalesByItemPage";
import MonthSalesPage from "../pages/reports/MonthSalesPage";
import { reportActions } from "../pages/reports/actions/report.actions";
import CustomerInClockListPage from "../pages/customer/CustomerInClockListPage";
import CustomerLossPage from "../pages/reports/CustomerLossPage";
import BillPage from "../pages/bills/BillPage";
import ListBillPage from "../pages/bills/ListBillPage";
import EmployeerListPage from "../pages/customer/EmployeerListPage";
import HomePage from "../pages/home/HomePage";
import ListBillCategoriesPage from "../pages/bills/ListBillCategoriesPage";
import DiaryClosePage from "../pages/reports/DiaryClosePage";
import ListSuscriptionsPage from "../pages/list-suscriptions/ListSuscriptionsPage";
import ListAttenLogPage from "../pages/customer/ListAttenLogPage";
import i18next from "i18next";
import ListTeamPage from "../pages/teams/ListTeamPage";
import TeamPage from "../pages/teams/TeamPage";
import BatchSuscriptionPage from "../pages/batch-suscriptions/BatchSuscriptionPage";
import { batchSuscriptionActions } from "../pages/batch-suscriptions/actions/batch-suscription.actions";
import { suscriptionActions } from "../pages/suscriptions/actions/suscription.actions";
import MonthClosePage from "../pages/reports/MonthClosePage";
import SalesByService from "../pages/reports/SalesByService";
import BillsResumen from "../pages/reports/BillsResumen";
import LateCustomerPage from "../pages/suscriptions/LateCustomerPage";
import ListAppUserPage from "../pages/the-app/users/ListAppUserPage";


function App() {
    const location = useLocation();
    const dispatch = useDispatch();
    const user = useSelector((state: any) => state.authentication.user);
    document.documentElement.style.fontSize = 14 + 'px';
    useEffect(() => {
      //TODO   console.log(location);
        //dispatch(alertActions.clear());
        //dispatch(inventoryActions.locationChange());
      dispatch(searchBarActions.changeShowPreview(false));
      dispatch(reportActions.cleanReports());
      dispatch(batchSuscriptionActions.cleanBatchSuscription())
      dispatch(suscriptionActions.cleanSuscription())
    },[location]);

    useEffect(()=>{
      if(!user){
        i18next
        .changeLanguage('es')
        .then((t) => { });
      }
      else{
        i18next
        .changeLanguage(user?.lang??"es")
        .then((t) => { });
      }
    },[user])

    axios.interceptors.response.use(
    response => response,
    error => {
      const {status} = error?.response??0;
      if (status ===401) {
        dispatch(authActions.logout());
      }
      return Promise.reject(error);
    }
    );
    axios.interceptors.response.use(undefined, (err) => {
      const { config, message } = err;
      if (!config || !config.retry) {
        return Promise.reject(err);
      }
      // retry while Network timeout or Network Error
      if (!(message.includes("timeout") || message.includes("Network Error"))) {
        return Promise.reject(err);
      }
      config.retry -= 1;
      const delayRetryRequest = new Promise<void>((resolve) => {
        setTimeout(() => {
          console.log("retry the request", config.url);
          resolve();
        }, config.retryDelay || 1000);
      });
      return delayRetryRequest.then(() => axios(config));
    });
    
    axios.defaults.headers.common['Authorization'] = `Bearer ${(user?.token??"")}` 
   

  return (
        <Routes>
            <Route path="/" element={<PrivateRoute path="/" component={<AdminLayout />} />} > 
                <Route path="/home" element={<HomePage/>} />
                <Route path="/" element={<HomePage/>} />
                <Route path="/users" element={<ListUserPage/>} />
                <Route path="/app-users" element={<ListAppUserPage/>} />
                
                <Route path="/roles" element={<ListRolePage/>} />
                <Route path="/device-task" element={<ListDeviceTaskPage/>} />
                <Route path="/customers" element={<CustomerListPage/>} />
                <Route path="/teams" element={<ListTeamPage/>} />
                <Route path="/team/:id" element={<TeamPage/>} />
                <Route path="/team" element={<TeamPage/>} />
                <Route path="/customers-in-clock" element={<CustomerInClockListPage/>} />
                <Route path="/listado-objetos" element={<ListPermissionPage/>} />
                <Route path="/listado-ofertas" element={<OfferListPage/>} />
                <Route path="/producto/:id" element={<ItemDetailPage />} />
                <Route path="/lista-productos" element={<ListItemPage />} />
                <Route path="/items" element={<ListItemPage />} />

                
                <Route path="/diary-sales" element={<DiarySalesPage />} />
                <Route path="/monthly-sales" element={<MonthSalesPage />} />
                <Route path="/sales-by-item" element={<SalesByItemPage />} />
                <Route path="/sales-by-service" element={<SalesByService />} />
                <Route path="/bills-resumen" element={<BillsResumen />} />

                <Route path="/clientes" element={<CustomerListPage />} />
                <Route path="/employees" element={<EmployeerListPage />} />
                <Route path="/suscription" element={<SuscriptionPage />} />
                <Route path="/batch-suscription" element={<BatchSuscriptionPage />} />
                <Route path="/active-suscription" element={<ActiveSuscriptionPage />} />
                <Route path="/late-customers" element={<LateCustomerPage />} />
                <Route path="/list-suscriptions" element={<ListSuscriptionsPage />} />
                <Route path="/atten-log" element={<ListAttenLogPage />} />
                <Route path="/customers-loss" element={<CustomerLossPage message="asda" />} />
                <Route path="/bill/:id" element={<BillPage />} />
                <Route path="/bill" element={<BillPage />} />
                <Route path="/bills" element={<ListBillPage />} />
                <Route path="/bill-categories" element={<ListBillCategoriesPage />} />
                <Route path="/diary_close" element={<DiaryClosePage />} />
                <Route path="/month-close" element={<MonthClosePage />} />
                <Route path="/*" element={<Navigate state={{ from: "*" }}to="/home" />} />
            </Route>
            <Route path="/login" element={<LoginLayout />}> 
                <Route path="/login/ingresar" element={<LoginPage />} />
                <Route path="/login/reiniciar/:id" element={<RestartPasswordPage />} />
                <Route path="/login" element={<Navigate state={{ from: "*" }}to="/login/ingresar" />} />  
            </Route>
            <Route path="/*" element={<Navigate state={{ from: "*" }}to="/" />} /> 
        </Routes>        
  );
}

export default App;
