import { Dispatch } from "@reduxjs/toolkit";
import { alertActions } from "../../../../auth/actions/alert.actions";

import { DataDialogProps } from "../../../../models/data.dialog.props";
import { AppUser } from "../models/app-user";
import { appUserConstants } from "../constants/app-user.constants";
import { appUserService } from "../app-user.service";

export const appUserActions = {
    openModalUser,
    openModalRestart,
    getUsers,
    sendLinkEmail,
    add,
    edit
};

function openModalUser(openAppUser:DataDialogProps<AppUser>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: appUserConstants.OPEN_MODAL_APP_USER, openAppUser });
    };
}
function openModalRestart(openAppRestart:DataDialogProps<AppUser>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: appUserConstants.OPEN_MODAL_APP_RESTART, openAppRestart });
    };
}

function getUsers() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        appUserService.getUsers()
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: appUserConstants.GET_APP_USERS_REQUEST } }
    function success(appUsers:AppUser[]) { return { type: appUserConstants.GET_APP_USERS_SUCCESS, appUsers } }
    function failure(error:any) { return { type: appUserConstants.GET_APP_USERS_FAILURE, error } }
}

function sendLinkEmail(userId: number) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        appUserService.sendLinkEmail(userId)
            .then((result)=> { 
                    dispatch(success(result.data));
                    dispatch(alertActions.success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: appUserConstants.SEND_APP_LINK_EMAIL_REQUEST } }
    function success(message: string) { return { type: appUserConstants.SEND_APP_LINK_EMAIL_SUCCESS, message } }
    function failure(error:any) { return { type: appUserConstants.SEND_APP_LINK_EMAIL_FAILURE, error } }
}


function add(user: AppUser) {
    return (dispatch: Dispatch) => {
        dispatch(request(user));
        appUserService.add(user)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message, user));
                dispatch(alertActions.error(message));
            });
    };

    function request(user: AppUser) { return { type: appUserConstants.ADD_APP_USER_REQUEST, user } }
    function success(appUsers:AppUser[]) { return { type: appUserConstants.ADD_APP_USER_SUCCESS, appUsers} }
    function failure(error:any, user: AppUser) { return { type: appUserConstants.ADD_APP_USER_FAILURE, error, user } }
}

function edit(user: AppUser) {
    return (dispatch: Dispatch) => {
        dispatch(request(user));
        appUserService.edit(user)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message, user));
                dispatch(alertActions.error(message));
            });
    };

    function request(user: AppUser) { return { type: appUserConstants.EDIT_APP_USER_REQUEST, user } }
    function success(appUsers:AppUser[]) { return { type: appUserConstants.EDIT_APP_USER_SUCCESS, appUsers } }
    function failure(error:any, user: AppUser) { return { type: appUserConstants.EDIT_APP_USER_FAILURE, error, user } }
}
